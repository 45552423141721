// import React from 'react';
// import DOMPurify from 'dompurify';
// import katex from 'katex';
// import 'katex/dist/katex.min.css';

// interface PreviewProps {
//     htmlContent: string;
// }

// const PreviewComponent: React.FC<PreviewProps> = ({ htmlContent }) => {
//     const decodeHTMLEntities = (text: string): string => {
//         const textArea = document.createElement('textarea');
//         textArea.innerHTML = text;
//         return textArea.value;
//     };

//     const renderMath = (text: string): string => {
//         return text.replace(/\$\$(.*?)\$\$/g, (_, expr) => {
//             return katex.renderToString(expr, {
//                 throwOnError: false
//             });
//         });
//     };

//     const decodedContent = decodeHTMLEntities(htmlContent);
//     const sanitizedContent = DOMPurify.sanitize(decodedContent);
//     const mathRenderedContent = renderMath(sanitizedContent);

//     return (
//         <div dangerouslySetInnerHTML={{ __html: mathRenderedContent }} />
//     );
// };

// export default PreviewComponent;

import type React from "react";
import { useEffect, useRef, useState } from "react";
import DOMPurify from "dompurify";
import { debounce } from "lodash";

interface PreviewProps {
  htmlContent: string;
}

const PreviewComponent: React.FC<PreviewProps> = ({ htmlContent }) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isRendering, setIsRendering] = useState(false);

  // Decode HTML entities (e.g., &lt; to <)
  const decodeHTMLEntities = (text: string): string => {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = text;
    return textArea.value;
  };

  // Debounced MathJax rendering function
  const debouncedRender = useRef(
    debounce(async () => {
      if (!contentRef.current || typeof window === "undefined") return;

      try {
        setIsRendering(true);
        const MathJax = (window as any).MathJax;

        if (!MathJax) {
          console.warn("MathJax not loaded");
          return;
        }

        // Clear existing math elements to prevent flicker
        if (MathJax.typesetClear) {
          MathJax.typesetClear([contentRef.current]);
        }

        // Use Promise-based API if available
        if (typeof MathJax.typesetPromise === "function") {
          await MathJax.typesetPromise([contentRef.current]);
        } else if (typeof MathJax.typeset === "function") {
          MathJax.typeset([contentRef.current]);
        }
      } catch (err) {
        console.error("MathJax rendering failed:", err);
      } finally {
        setIsRendering(false);
      }
    }, 300)
  ).current;

  useEffect(() => {
    debouncedRender();

    return () => {
      debouncedRender.cancel();
    };
  }, [htmlContent, debouncedRender]);

  // Configure DOMPurify to allow MathJax-specific elements and attributes
  const sanitizeConfig = {
    ADD_TAGS: [
      "math",
      "maction",
      "maligngroup",
      "malignmark",
      "menclose",
      "merror",
      "mfenced",
      "mfrac",
      "mi",
      "mlabeledtr",
      "mlongdiv",
      "mmultiscripts",
      "mn",
      "mo",
      "mover",
      "mpadded",
      "mphantom",
      "mroot",
      "mrow",
      "ms",
      "mscarries",
      "mscarry",
      "msgroup",
      "msline",
      "mspace",
      "msqrt",
      "msrow",
      "mstack",
      "mstyle",
      "msub",
      "msup",
      "msubsup",
      "mtable",
      "mtd",
      "mtext",
      "mtr",
      "munder",
      "munderover",
      "semantics",
      "annotation",
      "annotation-xml",
    ],
    ADD_ATTR: [
      "display",
      "id",
      "class",
      "style",
      "href",
      "title",
      "target",
      "xmlns",
      "mathvariant",
    ],
  };

  const decodedContent = decodeHTMLEntities(htmlContent || "");
  const sanitizedContent = DOMPurify.sanitize(decodedContent, sanitizeConfig);

  return (
    <div
      ref={contentRef}
      className={`math-content ${isRendering ? "rendering" : ""}`}
      dangerouslySetInnerHTML={{ __html: sanitizedContent }}
      style={{
        opacity: isRendering ? 0.5 : 1,
        transition: "opacity 0.2s ease-in-out",
      }}
    />
  );
};

export default PreviewComponent;