"use client"

import { Edit } from "@mui/icons-material"
import { Button, Divider, IconButton, List, ListItem, ListItemText, Stack, TextField, Typography } from "@mui/material"
import { type FC, useRef, useState } from "react"
import { Navigate } from "react-router-dom"
import { useAppDispatch, useAppSelecter } from "../../hooks/storeHooks"
import { proofReadingPermission } from "./ProofReadingPermission"
import {
  changeActiveQuestionExplanationTranslation,
  changeActiveQuestionOption1Translation,
  changeActiveQuestionOption2Translation,
  changeActiveQuestionOption3Translation,
  changeActiveQuestionOption4Translation,
  changeActiveQuestionTranslation,
} from "../../store/slices/proofReading/questionDataArraySlice"
import { updateQuestionData } from "../../store/slices/proofReading/thunks/updateQuestionData/updateQuestionData"
import { serverDetails } from "../../store/slices/constants/staticContents"

// Define interfaces for better type safety
interface OptionUpdate {
  option_id: number
  edited_option_hindi: string
}

interface QuestionUpdate {
  question_id: number
  edited_question_hindi?: string
  options?: OptionUpdate[]
  edited_explanation_hindi?: string
  is_proofread_hi?: boolean
}

export const QuestionRawContainer: FC = () => {
  const questionData = useAppSelecter((state) => state.questionsDataArraySlice.activeQuestionData)
  if (questionData === null) return null
  return <QuestionRawContainerJsx key={questionData.questionId} />
}

const QuestionRawContainerJsx: FC = () => {
  const questionRawContainerRef = useRef(null)
  const dispatch = useAppDispatch()
  const activeQuestionData = useAppSelecter((state) => state.questionsDataArraySlice.activeQuestionData)

  const token = localStorage.getItem("npc-admin-token")
    ? JSON.parse(localStorage.getItem("npc-admin-token") || "")
    : null

  const proofReadingPermissions = localStorage.getItem("npc-admin-permissions")
    ? JSON.parse(localStorage.getItem("npc-admin-permissions") || "")
    : null

  // State for editing different parts of the question
  const [editingStates, setEditingStates] = useState({
    question: false,
    explanation: false,
    option1: false,
    option2: false,
    option3: false,
    option4: false,
  })

  // State for edited content
  const [editedContent, setEditedContent] = useState({
    question: "",
    explanation: "",
    option1: "",
    option2: "",
    option3: "",
    option4: "",
  })

  const [modalInfo, setModalInfo] = useState<{
    rawText: string
    visible: boolean
  }>({
    rawText: "",
    visible: false,
  })

  if (proofReadingPermissions === null) return <Navigate to={"/login-page"} replace />
  if (!activeQuestionData) return null

  // Toggle editing state for a specific field
  const toggleEditing = (field: keyof typeof editingStates, value: boolean) => {
    setEditingStates((prev) => ({
      ...prev,
      [field]: value,
    }))

    // Initialize edited content when starting to edit
    if (value) {
      switch (field) {
        case "question":
          setEditedContent((prev) => ({ ...prev, question: activeQuestionData.questionHindi || "" }))
          break
        case "explanation":
          setEditedContent((prev) => ({ ...prev, explanation: activeQuestionData.explanationHindi || "" }))
          break
        case "option1":
          setEditedContent((prev) => ({ ...prev, option1: activeQuestionData.option1Hindi || "" }))
          break
        case "option2":
          setEditedContent((prev) => ({ ...prev, option2: activeQuestionData.option2Hindi || "" }))
          break
        case "option3":
          setEditedContent((prev) => ({ ...prev, option3: activeQuestionData.option3Hindi || "" }))
          break
        case "option4":
          setEditedContent((prev) => ({ ...prev, option4: activeQuestionData.option4Hindi || "" }))
          break
      }
    }
  }

  // Handle change for any field with proper date tracking
  const handleFieldChange = (
    field: string,
    value: string,
    dispatchAction: (payload: any) => void,
    coordinateField: string,
    coordinateDateField: string,
  ) => {
    // Update the edited content state
    setEditedContent((prev) => ({
      ...prev,
      [field]: value,
    }))

    // Create the appropriate payload for dispatch
    const payload: any = { date: new Date().toISOString() }

    switch (field) {
      case "question":
        payload.newActiveQuestionTranslation = value
        break
      case "explanation":
        payload.newActiveQuestionExplanationTranslation = value
        break
      case "option1":
        payload.newActiveQuestionOption1Translation = value
        break
      case "option2":
        payload.newActiveQuestionOption2Translation = value
        break
      case "option3":
        payload.newActiveQuestionOption3Translation = value
        break
      case "option4":
        payload.newActiveQuestionOption4Translation = value
        break
    }

    // Dispatch the action to update the state
    // dispatch(dispatchAction(payload))
  }

  // Build the payload for API submission
  const buildQuestionUpdatePayload = () => {
    return (): QuestionUpdate[] => {
      if (!activeQuestionData) return []

      const payload: QuestionUpdate = {
        question_id: activeQuestionData.questionId,
      }

      // Only add fields that have been edited
      if (editingStates.question) {
        payload.edited_question_hindi = editedContent.question
      }

      // Add options that have been edited
      const options: OptionUpdate[] = []

      if (editingStates.option1) {
        options.push({
          option_id: 1,
          edited_option_hindi: editedContent.option1,
        })
      }

      if (editingStates.option2) {
        options.push({
          option_id: 2,
          edited_option_hindi: editedContent.option2,
        })
      }

      if (editingStates.option3) {
        options.push({
          option_id: 3,
          edited_option_hindi: editedContent.option3,
        })
      }

      if (editingStates.option4) {
        options.push({
          option_id: 4,
          edited_option_hindi: editedContent.option4,
        })
      }

      // Only add options if there are any
      if (options.length > 0) {
        payload.options = options
      }

      // Add explanation if edited
      if (editingStates.explanation) {
        payload.edited_explanation_hindi = editedContent.explanation
      }

      console.log("Sending payload:", payload)
      return [payload] // Return as array since the API expects an array
    }
  }

  const getQuestionUpdatePayload = buildQuestionUpdatePayload()

  // Save function that uses the buildQuestionUpdatePayload
  const handleSaveQuestionUpdates = async (): Promise<void> => {
    if (token === null) return

    const questionUpdatePayload = getQuestionUpdatePayload()

    // Only proceed if there's something to save
    if (questionUpdatePayload.length > 0 && Object.keys(questionUpdatePayload[0]).length > 1) {
      try {
        const response = await fetch(`${serverDetails}/content/update/questions-proofread/`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(questionUpdatePayload),
        })

        if (response.ok) {
          // Reset all editing states
          setEditingStates({
            question: false,
            explanation: false,
            option1: false,
            option2: false,
            option3: false,
            option4: false,
          })

          // Clear edited content
          setEditedContent({
            question: "",
            explanation: "",
            option1: "",
            option2: "",
            option3: "",
            option4: "",
          })
        }
      } catch (error) {
        console.error("Failed to save updates:", error)
      }
    }
  }

  const handleMarkAsDone = async () => {
    if (token === null) return
    let questionUpdatePayload = getQuestionUpdatePayload()
    questionUpdatePayload[0].is_proofread_hi = true
    console.log("Mark as done payload:", questionUpdatePayload)
    try{
      const response = await fetch(`${serverDetails}/content/update/questions-proofread/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(questionUpdatePayload)})
    }catch(error){
      console.error("Failed to mark as done:", error)
    }
  }

  // Helper function to render editable field
  const renderEditableField = (
    isEditing: boolean,
    fieldKey: keyof typeof editingStates,
    originalValue: string,
    dispatchAction: any,
    coordinateField: string,
    coordinateDateField: string,
    editedDate?: string,
    label?: string,
  ) => {
    // Get the current value from our editedContent state
    const currentValue = editedContent[fieldKey] || originalValue || ""

    return (
      <Stack spacing={"8px"} marginTop={"16px"}>
        <Stack direction={"row"} justifyContent={"space-between"} spacing={"16px"} alignItems={"center"}>
          <Stack direction={"row"} alignItems={"center"}>
            <Typography variant="body1">{label || fieldKey}</Typography>
            {editedDate && (
              <Button
                onClick={(e) => {
                  e.stopPropagation()
                  setModalInfo({
                    visible: true,
                    rawText: originalValue,
                  })
                }}
              >{`edited on ${editedDate}`}</Button>
            )}
          </Stack>
          {proofReadingPermissions.includes(proofReadingPermission.change_traslation) && !isEditing && (
            <IconButton
              aria-label="edit"
              onClick={(e) => {
                e.stopPropagation()
                toggleEditing(fieldKey, true)
              }}
            >
              <Edit />
            </IconButton>
          )}
        </Stack>

        {isEditing ? (
          <TextField
            multiline
            maxRows={5}
            value={currentValue}
            onChange={(e) => {
              e.stopPropagation()
              handleFieldChange(fieldKey, e.target.value, dispatchAction, coordinateField, coordinateDateField)
            }}
          />
        ) : (
          <Typography variant="body2">{currentValue}</Typography>
        )}
      </Stack>
    )
  }

  // Helper function to render editable option
  const renderEditableOption = (
    optionNumber: number,
    isEditing: boolean,
    fieldKey: keyof typeof editingStates,
    originalValue: string,
    dispatchAction: any,
    coordinateField: string,
    coordinateDateField: string,
    editedDate?: string,
  ) => {
    const optionLabels = ["A", "B", "C", "D"]
    const label = optionLabels[optionNumber - 1]

    // Get the current value from our editedContent state
    const currentValue = editedContent[fieldKey] || originalValue || ""

    return (
      <ListItem disablePadding>
        <Stack direction={"row"} justifyContent={"space-between"} spacing={"16px"} alignItems={"center"} flexGrow={1}>
          <ListItemText
            primary={
              isEditing ? (
                <TextField
                  multiline
                  maxRows={5}
                  value={currentValue}
                  onChange={(e) => {
                    e.stopPropagation()
                    handleFieldChange(fieldKey, e.target.value, dispatchAction, coordinateField, coordinateDateField)
                  }}
                />
              ) : (
                <Typography variant="body2">{`${label}: ${currentValue}`}</Typography>
              )
            }
          />
          {editedDate && (
            <Stack direction={"row"} alignItems={"center"}>
              <Button
                onClick={(e) => {
                  e.stopPropagation()
                  setModalInfo({
                    visible: true,
                    rawText: originalValue,
                  })
                }}
              >{`edited on ${editedDate}`}</Button>
            </Stack>
          )}
          {proofReadingPermissions.includes(proofReadingPermission.change_traslation) && !isEditing && (
            <IconButton
              aria-label="edit"
              onClick={(e) => {
                e.stopPropagation()
                toggleEditing(fieldKey, true)
              }}
            >
              <Edit />
            </IconButton>
          )}
        </Stack>
      </ListItem>
    )
  }

  return (
    <Stack
      maxHeight={"90vh"}
      marginTop={"16px"}
      padding={"16px"}
      spacing={"16px"}
      sx={{ overflowY: "scroll" }}
      ref={questionRawContainerRef}
    >
      {/* Modal for showing original text */}
      {modalInfo.visible === true && (
        <Stack
          position={"absolute"}
          top={0}
          left={0}
          right={0}
          bottom={0}
          bgcolor={"rgba(0, 0, 0, 0.3)"}
          alignItems={"center"}
          justifyContent={"center"}
          zIndex={"100"}
        >
          <Stack p={"24px"} bgcolor={"white"} width={"500px"} borderRadius={"16px"} overflow={"hidden"}>
            <Typography mb={"16px"}>{modalInfo.rawText}</Typography>
            <Button
              variant="contained"
              onClick={(e) => {
                e.stopPropagation()
                setModalInfo({ rawText: "", visible: false })
              }}
            >
              close
            </Button>
          </Stack>
        </Stack>
      )}

      <Typography variant="h5" textAlign={"center"}>
        Raw Data
      </Typography>

      <Divider />

      {/* Question English */}
      <Stack spacing={"8px"} marginTop={"16px"}>
        <Typography variant="body1">question (english)</Typography>
        <Typography variant="body2">{activeQuestionData.question}</Typography>
      </Stack>

      <Divider />

      {/* Question Hindi - Editable */}
      {renderEditableField(
        editingStates.question,
        "question",
        activeQuestionData.questionHindi,
        changeActiveQuestionTranslation,
        activeQuestionData.editedQuestionHindiCoordinate,
        activeQuestionData.editedQuestionHindiCoordinateDate,
        activeQuestionData.editedQuestionHindiDate,
        "question (hindi)",
      )}

      <Divider />

      {/* Options English */}
      <Stack>
        <Typography variant="body1">options (english)</Typography>
        <List>
          <ListItem disablePadding>
            <ListItemText primary={<Typography variant="body2">A: {activeQuestionData.option1}</Typography>} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText primary={<Typography variant="body2">B: {activeQuestionData.option2}</Typography>} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText primary={<Typography variant="body2">C: {activeQuestionData.option3}</Typography>} />
          </ListItem>
          <ListItem disablePadding>
            <ListItemText primary={<Typography variant="body2">D: {activeQuestionData.option4}</Typography>} />
          </ListItem>
        </List>
      </Stack>

      <Divider />

      {/* Options Hindi - Editable */}
      <Stack>
        <Typography variant="body1">options (hindi)</Typography>
        <List>
          {/* Option A */}
          {renderEditableOption(
            1,
            editingStates.option1,
            "option1",
            activeQuestionData.option1Hindi,
            changeActiveQuestionOption1Translation,
            activeQuestionData.editedOption1HindiCoordinate,
            activeQuestionData.editedOption1HindiCoordinateDate,
            activeQuestionData.editedOption1HindiDate,
          )}

          {/* Option B */}
          {renderEditableOption(
            2,
            editingStates.option2,
            "option2",
            activeQuestionData.option2Hindi,
            changeActiveQuestionOption2Translation,
            activeQuestionData.editedOption2HindiCoordinate,
            activeQuestionData.editedOption2HindiCoordinateDate,
            activeQuestionData.editedOption2HindiDate,
          )}

          {/* Option C */}
          {renderEditableOption(
            3,
            editingStates.option3,
            "option3",
            activeQuestionData.option3Hindi,
            changeActiveQuestionOption3Translation,
            activeQuestionData.editedOption3HindiCoordinate,
            activeQuestionData.editedOption3HindiCoordinateDate,
            activeQuestionData.editedOption3HindiDate,
          )}

          {/* Option D */}
          {renderEditableOption(
            4,
            editingStates.option4,
            "option4",
            activeQuestionData.option4Hindi,
            changeActiveQuestionOption4Translation,
            activeQuestionData.editedOption4HindiCoordinate,
            activeQuestionData.editedOption4HindiCoordinateDate,
            activeQuestionData.editedOption4HindiDate,
          )}
        </List>
      </Stack>

      <Divider />

      {/* Explanation English */}
      <Stack spacing={"8px"} marginTop={"16px"}>
        <Typography variant="body1">explanation (english)</Typography>
        <Typography variant="body2">{activeQuestionData.explanation}</Typography>
      </Stack>

      <Divider />

      {/* Explanation Hindi - Editable */}
      {renderEditableField(
        editingStates.explanation,
        "explanation",
        activeQuestionData.explanationHindi,
        changeActiveQuestionExplanationTranslation,
        activeQuestionData.editedExplanationHindiCoordinate,
        activeQuestionData.editedExplanationHindiCoordinateDate,
        activeQuestionData.editedExplanationHindiDate,
        "explanation (hindi)",
      )}

      {/* Action Buttons */}
      <Stack direction={"row"} justifyContent={"flex-end"} spacing={"8px"}>
        <Button
          variant="contained"
          disabled={!Object.values(editingStates).some((state) => state)}
          onClick={(e) => {
            e.stopPropagation()
            handleSaveQuestionUpdates()
          }}
        >
          Save
        </Button>
        <Button
          variant="contained"
          onClick={(e) => {
            e.stopPropagation()
            handleMarkAsDone()
          }}
        >
          Mark as done
        </Button>
      </Stack>
    </Stack>
  )
}

