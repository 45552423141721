import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { loadQuestionsDataArray } from "./thunks/loadQuestionsDataArray/loadQuestionDataArray";
import { QuestionData } from "./thunks/loadQuestionsDataArray/interfaces/frontend";
import { updateQuestionData } from "./thunks/updateQuestionData/updateQuestionData";
// import { updateQuestionData } from "./thunks/updateQuestionData/updateQuestionData";

export interface QuestionsDataArraySlice {
  status: "IDLE" | "LOADING" | "LOADED";
  questionsDataArray: QuestionData[] | null;
  activeQuestionData: null | QuestionData;
  currentPageNumber: number;
}

export const initialUserData: QuestionsDataArraySlice = {
  status: "IDLE",
  questionsDataArray: null,
  activeQuestionData: null,
  currentPageNumber: 1,
};

const questionsDataArraySlice = createSlice({
  name: "questionsDataArraySlice",
  initialState: initialUserData,
  reducers: {
    goToNextPage(state) {
      if (state.currentPageNumber < 10) state.currentPageNumber++;
    },
    goToPreviousPage(state) {
      if (state.currentPageNumber > 1) state.currentPageNumber--;
    },
    // setting active question
    setActiveQuestion(
      state,
      action: PayloadAction<{
        activeQuestionRowNumber: number;
      }>
    ) {
      const { activeQuestionRowNumber } = action.payload;
      if (state.questionsDataArray) {
        const targetQuestionData = state.questionsDataArray.find(
          (questionsData) => questionsData.questionId === activeQuestionRowNumber
        );

        state.activeQuestionData = targetQuestionData
          ? { ...targetQuestionData }
          : null;
      }
    },
    // setting active question translation
    changeActiveQuestionTranslation(
      state,
      action: PayloadAction<{
        newActiveQuestionTranslation: string;
        date: string;
      }>
    ) {
      const { newActiveQuestionTranslation, date } = action.payload;

      if (state.activeQuestionData) {
        state.activeQuestionData.editedQuestionHindi =
          newActiveQuestionTranslation;
        state.activeQuestionData.editedQuestionHindiDate = date;
      }
    },
    // setting active question explanation translation
    changeActiveQuestionExplanationTranslation(
      state,
      action: PayloadAction<{
        newActiveQuestionExplanationTranslation: string;
        date: string;
      }>
    ) {
      const { newActiveQuestionExplanationTranslation, date } = action.payload;

      if (state.activeQuestionData) {
        state.activeQuestionData.editedExplanationHindi =
          newActiveQuestionExplanationTranslation;
        state.activeQuestionData.editedExplanationHindiDate = date;
      }
    },
    // setting active question option 1 translation
    changeActiveQuestionOption1Translation(
      state,
      action: PayloadAction<{
        newActiveQuestionOption1Translation: string;
        date: string;
      }>
    ) {
      const { newActiveQuestionOption1Translation, date } = action.payload;

      if (state.activeQuestionData) {
        state.activeQuestionData.editedOption1Hindi =
          newActiveQuestionOption1Translation;
        state.activeQuestionData.editedOption1HindiDate = date;
      }
    },
    // setting active question option 2 translation
    changeActiveQuestionOption2Translation(
      state,
      action: PayloadAction<{
        newActiveQuestionOption2Translation: string;
        date: string;
      }>
    ) {
      const { newActiveQuestionOption2Translation, date } = action.payload;

      if (state.activeQuestionData) {
        state.activeQuestionData.editedOption2Hindi =
          newActiveQuestionOption2Translation;
        state.activeQuestionData.editedOption2HindiDate = date;
      }
    },
    // setting active question option 3 translation
    changeActiveQuestionOption3Translation(
      state,
      action: PayloadAction<{
        newActiveQuestionOption3Translation: string;
        date: string;
      }>
    ) {
      const { newActiveQuestionOption3Translation, date } = action.payload;

      if (state.activeQuestionData) {
        state.activeQuestionData.editedOption3Hindi =
          newActiveQuestionOption3Translation;
        state.activeQuestionData.editedOption3HindiDate = date;
      }
    },
    // setting active question option 4 translation
    changeActiveQuestionOption4Translation(
      state,
      action: PayloadAction<{
        newActiveQuestionOption4Translation: string;
        date: string;
      }>
    ) {
      const { newActiveQuestionOption4Translation, date } = action.payload;

      if (state.activeQuestionData) {
        state.activeQuestionData.editedOption4Hindi =
          newActiveQuestionOption4Translation;
        state.activeQuestionData.editedOption4HindiDate = date;
      }
    },
  },
  extraReducers(builder) {
    // restore user #################################
    builder.addCase(loadQuestionsDataArray.pending, (state) => {
      state.status = "LOADING";
      state.questionsDataArray = null;
    });
    builder.addCase(loadQuestionsDataArray.fulfilled, (state, action) => {
      const {
        payload: { questionsDataArray },
      } = action;

      state.status = "LOADED";
      state.questionsDataArray = questionsDataArray;
    });
    builder.addCase(loadQuestionsDataArray.rejected, (state) => {
      state.status = "LOADED";
      state.questionsDataArray = null;
    });
    // update question
    builder.addCase(updateQuestionData.pending, () => {});
    builder.addCase(updateQuestionData.fulfilled, (state) => {
      if (
        state.activeQuestionData === null ||
        state.questionsDataArray === null
      )
        return state;

      const targetQuestionRowNumber = state.activeQuestionData.questionId;

      state.questionsDataArray = state.questionsDataArray.map(
        (questionData) => {
          if (questionData.questionId === targetQuestionRowNumber)
            return { ...questionData, questionStatus: "done" };
          return questionData;
        }
      );
    });
    builder.addCase(updateQuestionData.rejected, () => {});
  },
});

export default questionsDataArraySlice.reducer;
export const {
  goToNextPage,
  goToPreviousPage,
  changeActiveQuestionTranslation,
  changeActiveQuestionExplanationTranslation,
  changeActiveQuestionOption1Translation,
  changeActiveQuestionOption2Translation,
  changeActiveQuestionOption3Translation,
  changeActiveQuestionOption4Translation,
  setActiveQuestion,
} = questionsDataArraySlice.actions;
