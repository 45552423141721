const getAlphabetForIndex = (index: number) => {
  switch (index) {
    case 0:
      return "A";
    case 1:
      return "B";
    case 2:
      return "C";
    case 3:
      return "D";
    default:
      return "X";
  }
};

export default getAlphabetForIndex;
