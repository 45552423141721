import { createAsyncThunk } from "@reduxjs/toolkit";
import { serverDetails } from "../../../constants/staticContents";
// import { convertToCamelCase } from "../../../../../utility/convertToCamelCase";

// login thunk is called only when on enter mobile number view
export const updateQuestionData = createAsyncThunk(
  "questionsDataArraySlice/updateQuestionData",
  async ({ questionUpdates, token }: updateQuestionDataArgs) => {
    try {
      // resend : number, 0 -> ...
      console.log(questionUpdates);
      const response = await fetch(
        `${serverDetails}/api/content/excel-question/update/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          method: "POST",
          body: JSON.stringify(questionUpdates),
        }
      );
      if (!response.ok)
        throw new Error(`Something went wrong : ${response.status}`);
    } catch (error: any) {
      // console.log(error);
      throw new Error(error.message);
    }
  }
);

export interface updateQuestionDataArgs {
  token: string;
  questionUpdates: QuestionUpdate[];
}

export interface QuestionUpdate {
  coordinate: string;
  value: string;
}
