import { Stack, Typography } from "@mui/material";
import { FC, useEffect, useRef } from "react";
import { useAppSelecter } from "../../hooks/storeHooks";
import SingleQuestion from "./SingleQuestion";
import AnswerExplanation from "./AnswerExplanation";
import mathFunction from "../../pages/exams/mathFunction";

export const QuestionPreview: FC = () => {
  const { activeQuestionData } = useAppSelecter(
    (state) => state.questionsDataArraySlice
  );
  const questionPreviewRef = useRef(null);

  useEffect(() => {
    let timeOut: ReturnType<typeof setTimeout>;

    if (activeQuestionData && questionPreviewRef.current) {
      timeOut = setTimeout(() => mathFunction(), 2000);
    }

    return () => clearTimeout(timeOut);
  }, [activeQuestionData]);

  if (activeQuestionData === null) return null;

  /* question preview */
  return (
    <Stack
      ref={questionPreviewRef}
      maxHeight={"80%"}
      spacing={"30px"}
      width={"400px"}
      flexShrink={0}
      py={"50px"}
      px={"16px"}
      sx={{ overflowY: "scroll", overflowX: "hidden" }}
      // border={"1px solid red"}
    >
      <Stack border={"2px solid grey"} p={"8px"} borderRadius={"8px"}>
        <Typography>question preview (English)</Typography>
        <SingleQuestion language={"english"} />
        <AnswerExplanation
          answerExplanationText={activeQuestionData.explanation}
        />
      </Stack>
      <Stack border={"2px solid grey"} p={"8px"} borderRadius={"8px"}>
        <Typography>question preview (Hindi)</Typography>
        <SingleQuestion language={"hindi"} />
        <AnswerExplanation
          answerExplanationText={
            activeQuestionData.editedExplanationHindi
              ? activeQuestionData.editedExplanationHindi
              : activeQuestionData.explanationHindi
          }
        />
      </Stack>
    </Stack>
  );
};


