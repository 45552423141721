import {
  Box,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import type { QuestionType } from "./types";
import Chip from "../Chip";
interface QuestionPreviewProps {
  question: QuestionType | undefined;
  questionNumber: number;
  isSelected: boolean;
  onClick: () => void;
}

export function QuestionPreview({
  question,
  questionNumber,
  isSelected,
  onClick,
}: QuestionPreviewProps) {
  if (!question) {
    return null; // or return a placeholder component
  }

  return (
    <Box
      className="preview-question-box"
      sx={{
        mt: 4,
        p: 3,
        borderRadius: 1,
        border: isSelected ? "2px solid #1976d2" : "1px solid #ccc",
        backgroundColor: isSelected ? "rgba(25, 118, 210, 0.1)" : "white",
        transition: "all 0.3s ease",
        cursor: "pointer",
        "&:hover": {
          boxShadow: isSelected ? "none" : "0 2px 4px rgba(0,0,0,0.1)",
        },
      }}
      onClick={onClick}
    >
      <Typography
        variant="body1"
        sx={{ display: "flex", gap: 1, wordWrap: "wrap" }}
      >
        Q{questionNumber + 1}.{" "}
        <span
          style={{
            display: "inline-block", // Ensures the span behaves like a block for proper wrapping
            wordWrap: "break-word", // Wrap long words if needed
            overflowWrap: "break-word", // A more modern alternative for better browser support
            width: "100%", // Allows the content to adjust fluidly
          }}
          dangerouslySetInnerHTML={{
            __html:
              question.questionHtml ||
              question.text ||
              "Question text will appear here",
          }}
        />
      </Typography>
      {question.tag
        ? question.tag
            .split(",")
            .map((tag, index) => (
              <Chip key={index} label={tag.trim()} color="red" />
            ))
        : null}

      {question.image && (
        <Box sx={{ my: 2 }}>
          <img
            src={question.image || "/placeholder.svg"}
            alt="Question"
            style={{ maxWidth: "100%", height: "auto" }}
          />
        </Box>
      )}

      <RadioGroup>
        {question.options.map((option, optIndex) => (
          <FormControlLabel
            key={optIndex}
            value={optIndex}
            control={<Radio />}
            label={
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    option || `Option ${String.fromCharCode(65 + optIndex)}`,
                }}
              />
            }
            sx={{
              backgroundColor:
                optIndex === question.correctAnswer
                  ? "rgba(76, 175, 80, 0.2)"
                  : "transparent",
              border:
                optIndex === question.correctAnswer
                  ? "2px solid #4caf50"
                  : "none",
              borderRadius: "4px",
              padding: "4px",
              my: 0.5,
            }}
            checked={optIndex === question.correctAnswer}
          />
        ))}
      </RadioGroup>

      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle2">Solution:</Typography>
        <Typography>
          <span
            dangerouslySetInnerHTML={{
              __html:
                question.solutionHtml ||
                question.explanation ||
                "Solution will appear here",
            }}
          />
        </Typography>
      </Box>
    </Box>
  );
}
