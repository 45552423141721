import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useAppDispatch, useAppSelecter } from "../../hooks/storeHooks";
import { loadQuestionsDataArray } from "../../store/slices/proofReading/thunks/loadQuestionsDataArray/loadQuestionDataArray";
import mathFunction from "../../pages/exams/mathFunction";
import { useNavigate, useLocation} from "react-router-dom";
//import { logoutUser } from "../store/slices/userDataSlice/thunks/logoutUser/logoutUser";
import globalColors from "../../globalColors";
import { goToNextPage, goToPreviousPage, setActiveQuestion } from "../../store/slices/proofReading/questionDataArraySlice";

export const QuestionsContainer: FC = () => {
  const dispatch = useAppDispatch();

  const questionsRef = useRef(null);
  const { status, questionsDataArray, currentPageNumber } = useAppSelecter(
    (state) => state.questionsDataArraySlice
  );

  
  const { activeQuestionData } = useAppSelecter(
    (state) => state.questionsDataArraySlice
  );
  
  const [selectedInd, setSelectedInd] = useState<number | null>(null);

  const token = localStorage.getItem("npc-admin-token") ? 
  JSON.parse(localStorage.getItem("npc-admin-token") || "") : null;
  const [filterby, setFilterBy] = useState("all");

  // useEffect(() => {
  // if (questionsDataArray === null && status === "IDLE")
  // handleLoadQuestiondDataArray({ currentPageNumber: 1 });
  // }, []);
  const navigate = useNavigate();
  const location = useLocation();
  
  useEffect(() => {
    if (location.pathname.includes('/preview/') && location.pathname.includes('/editor')) {
      navigate('/proofReading', { replace: true });
    }
  }, []);
  useEffect(() => {
    handleLoadQuestiondDataArray({ currentPageNumber });
  }, [currentPageNumber]);

  useEffect(() => {
    let timeOut: ReturnType<typeof setTimeout>;

    if (questionsRef.current) {
      timeOut = setTimeout(() => mathFunction(), 1000);
    }

    return () => clearTimeout(timeOut);
  }, [questionsDataArray, filterby]);

  const handleLoadQuestiondDataArray = async ({
    currentPageNumber,
  }: {
    currentPageNumber: number;
  }) => {
    try {
      if (token === null) return;
      const dispatchLoadQuestionsDataArrayResponse: any = await dispatch(
        loadQuestionsDataArray({ token, currentPageNumber })
      );

      if (
        dispatchLoadQuestionsDataArrayResponse.meta.requestStatus === "rejected"
      )
        throw new Error(dispatchLoadQuestionsDataArrayResponse.error.message);
    } catch (error: any) {
      alert(error.message);
      //dispatch(logoutUser());
    }
  };

  const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterBy((event.target as HTMLInputElement).value);
  };

  if (questionsDataArray === null) return null;

  const filteredQuestionsDataArray =
    filterby === "all"
      ? questionsDataArray
      : questionsDataArray.filter(
          (questionsData) => questionsData.questionStatus === filterby
        );
  

  return (
    <Stack>
      {/* filters */}
      <Stack marginTop={"16px"} p={"16px"}>
        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">Filters</FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            value={filterby}
            onChange={handleFilterChange}
          >
            <FormControlLabel value="all" control={<Radio />} label="all" />
            <FormControlLabel
              value="pending"
              control={<Radio />}
              label="pending"
            />
            <FormControlLabel value="done" control={<Radio />} label="done" />
          </RadioGroup>
        </FormControl>
      </Stack>

      {/* page navigators */}
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        px={"16px"}
        mb={"16px"}
      >
        <Button
          variant="contained"
          disabled={currentPageNumber === 1 || status === "LOADING"}
          onClick={(e) => {
            e.stopPropagation();
            dispatch(goToPreviousPage());
          }}
        >
          previous
        </Button>
        <Button
          variant="contained"
          disabled={currentPageNumber === 10 || status === "LOADING"}
          onClick={(e) => {
            e.stopPropagation();
            dispatch(goToNextPage());
          }}
        >
          next
        </Button>
      </Stack>
      <Divider />
      <Typography textAlign={"center"} my={"16px"}>
        {currentPageNumber}/10 pages
      </Typography>
      <Divider />
      {/* questions list */}
      <List ref={questionsRef}>
        {filteredQuestionsDataArray.map((questionData) => (
          <>
            <ListItem
              disablePadding
              key={questionData.questionId}
              sx={{
                bgcolor:
                  activeQuestionData !== null &&
                  activeQuestionData?.questionId=== questionData.questionId
                    ? globalColors.lavender[200]
                    : "",
              }}
            >
              <ListItemButton
                onClick={(e) => {
                  e.stopPropagation();
                  
                  dispatch(
                    setActiveQuestion({
                      activeQuestionRowNumber: questionData.questionId,
                    })
                  );
                  navigate(`preview/${questionData.questionId}/editor`);
                }}
              >
                <ListItemText
                  primary={
                    <Stack direction={"row"} spacing={"16px"}>
                      <span>{questionData.questionId}</span>
                      <Typography variant="body2">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: questionData.question,
                          }}
                        ></div>
                      </Typography>
                    </Stack>
                  }
                />
              </ListItemButton>
            </ListItem>
          </>
        ))}
      </List>
    </Stack>
  );
};
