import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Paper,
  Button,
  Typography,
  Stack,
  List,
  ListItem,
  ListItemText,
  Breadcrumbs,
  Link,
  Divider,
  LinearProgress,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Tooltip,
  Checkbox,
  CircularProgress,
} from "@mui/material";
import {
  NavigateNextOutlined,
  Add as AddIcon,
  Image as ImageIcon,
} from "@mui/icons-material";
import { ClusterType } from "./bulkquestion/types";
import { QuestionEditor } from "./bulkquestion/QuestionEditor";
import { QuestionPreview } from "./bulkquestion/QuestionPreview";
import mammoth from "mammoth";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import FullScreenLoader from "./bulkquestion/loader";
import { allApis } from "../../request/config";
import apiRequest from "../../request/apiRequest/apiRequest";
import { useAppSelecter } from "../../hooks/storeHooks";
import { ObjectValues } from "../login";
import mathFunction from "./mathFunction";
import CustomSnackbar from "../../Components/CustomSnackbar/index";
import { type snackbarProps } from "../../Components/CustomSnackbar/index";

import { type structureProps } from "../../store/slices/constants/staticContents";
import BulkQuestionsFormStepper from "./bulkquestion/bulkQuestionStepper";

interface ParsedQuestion {
  id: string;
  text: string;
  options: {
    text: string;
    html: string;
  }[];
  correctAnswer: number;
  explanation: string;
  solutionHtml: string;
  cluster: string;
  tag?: string;
}

interface Question {
  id: string;
  text: string;
  questionHtml: string;
  options: string[];
  correctAnswer: number;
  explanation: string;
  solutionHtml: string;
  image?: string;
  source?: string;
  tag?: string;
}

interface Cluster {
  id: string;
  name: string;
  questions: Question[];
}

interface QuestionPayload {
  question_: string;
  option1: string;
  option2: string;
  option3: string;
  option4: string;
  correct_answer: string;
  solution: string;
  tag: string[];
}

interface ClusterPayload {
  [cluster_name: string]: QuestionPayload[];
}

export interface BulkQuestionsFormProps {
  dialogRef: React.MutableRefObject<{ onSubmitForm?: () => void }>;
  onSubmit: (questions: Question[]) => void;
  dockedCard: structureProps | null;
  onClose: () => void;
  subtopic?: ObjectValues;
}

export default function BulkQuestionsForm({
  dialogRef,
  onSubmit,
  dockedCard,
  onClose,
  subtopic,
}: BulkQuestionsFormProps) {
  const [clusters, setClusters] = useState<Cluster[]>([]);
  const [selectedClusterIndex, setSelectedClusterIndex] = useState(0);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(0);
  const [editorElements, setEditorElements] = useState<NodeListOf<Element>>();
  const [hasFileUploaded, setHasFileUploaded] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const [dialogState, setDialogState] = useState<{
    open: boolean;
    type: "info" | "confirm";
    title: string;
    message: string;
  }>({
    open: false,
    type: "confirm",
    title: "",
    message: "",
  });
  const [totalQuestions, setTotalQuestions] = useState(0);

  const userData = useAppSelecter(
    (state: ObjectValues) => state.userSlice.loggedUser
  );

  const ref = useRef<HTMLDivElement>();

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [currentFileName, setCurrentFileName] = useState<String>("");
  const questionListRefs = useRef<(HTMLDivElement | null)[]>([]);
  const editorRefs = useRef<(HTMLDivElement | null)[]>([]);
  const previewRefs = useRef<(HTMLDivElement | null)[]>([]);
  const inputRefs = useRef<{
    [key: string]: HTMLInputElement | HTMLTextAreaElement | null;
  }>({});
  const updateCompletedRef = useRef(false);
  const [clusterType, setClusterType] = useState<string | null>(null);
  const [hindiTranslation, setHindiTranslation] = useState<boolean>(false);

  const createEmptyQuestion = (): Question => ({
    id: Math.random().toString(36).substr(2, 9),
    text: "",
    questionHtml: "",
    options: ["", "", "", ""],
    correctAnswer: -1,
    explanation: "",
    solutionHtml: "",
  });

  const createEmptyCluster = (): ClusterType => ({
    id: Math.random().toString(36).substr(2, 9),
    name: `Cluster ${clusters.length + 1}`,
    questions: [createEmptyQuestion()],
  });

  const isValidQuestion = (question: Question) => {
    return (
      question.text.trim() !== "" &&
      question.options.length > 1 &&
      question.options.every((opt) => opt.trim() !== "") &&
      question.correctAnswer !== undefined &&
      question.correctAnswer >= 0 &&
      question.correctAnswer < question.options.length
    );
  };

  const handleClusterTypeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setClusterType(event.target.value);
  };

  const handleHindiTranslation = () => {
    setHindiTranslation((prev) => !prev);
  };

  const isSingleQuestionPerCluster = () => {
    return clusters.every(
      (cluster) => cluster.questions.filter(isValidQuestion).length === 1
    );
  };

  const handleOpen = () => {
    if (isSingleQuestionPerCluster()) {
      setDialogState({
        open: true,
        type: "info",
        title: "Already Separated",
        message:
          "Questions are already organized with one question per cluster.",
      });
    } else {
      setDialogState({
        open: true,
        type: "confirm",
        title: "Confirmation",
        message: `This will replace existing clusters and create a total of ${countTotalQuestions()} clusters (one cluster per question). Do you want to continue?`,
      });
    }
  };

  const handleClose = () => {
    setDialogState((prev) => ({ ...prev, open: false }));
  };

  const countTotalQuestions = () => {
    return clusters.reduce((total, cluster) => {
      return total + cluster.questions.filter(isValidQuestion).length;
    }, 0);
  };

  const handleConfirmSingleQuestion = () => {
    const allQuestions = clusters.flatMap((cluster) =>
      cluster.questions.filter(isValidQuestion)
    );

    const newClusters = allQuestions.map((question, index) => ({
      id: Math.random().toString(36).substr(2, 9),
      name: `Cluster ${index + 1}`,
      questions: [question],
    }));

    setClusters(newClusters);
    setSelectedClusterIndex(0);
    setSelectedQuestionIndex(0);
    handleClose();
  };

  const getAbsoluteQuestionIndex = (
    clusterIndex: number,
    questionIndex: number
  ) => {
    return (
      clusters.slice(0, clusterIndex).reduce((sum, cluster) => {
        const validQuestions = cluster.questions.filter(
          (question) => question.text.trim() !== ""
        );
        return sum + validQuestions.length;
      }, 0) + questionIndex
    );
  };
  const scrollAllSections = (absoluteQuestionIndex: number) => {
    setTimeout(() => {
      const scrollOptions = {
        behavior: "smooth" as ScrollBehavior,
        block: "start" as ScrollLogicalPosition,
      };

      const editorRef = editorRefs.current[absoluteQuestionIndex];
      const previewElements = Array.from(
        document.querySelectorAll(".preview-question-box")
      );
      const previewRef =
        previewElements[selectedClusterIndex - selectedQuestionIndex] ||
        previewElements[absoluteQuestionIndex - 1];

      if (editorRef) {
        editorRef.scrollIntoView(scrollOptions);
      }

      if (previewRef) {
        previewRef.scrollIntoView(scrollOptions);
      }

      setTimeout(() => {
        const questionTextField =
          inputRefs.current[
            `question-text-${selectedClusterIndex}-${selectedQuestionIndex}`
          ];
        if (questionTextField) {
          questionTextField.focus();
        }
      }, 100);
    }, 0);
  };

  useEffect(() => {
    setClusters([createEmptyCluster()]);
  }, []);

  useEffect(() => {
    const absoluteIndex = getAbsoluteQuestionIndex(
      selectedClusterIndex,
      selectedQuestionIndex
    );
    scrollAllSections(absoluteIndex);
  }, [selectedQuestionIndex, selectedClusterIndex]);

  useEffect(() => {
    if (clusters[selectedClusterIndex]?.questions[selectedQuestionIndex]) {
      const questionTextElement = document.getElementById(
        `question-text-${selectedClusterIndex}-${selectedQuestionIndex}`
      );
      if (questionTextElement) {
        questionTextElement.focus();
      }
    }
  }, [selectedClusterIndex, selectedQuestionIndex, clusters]);

  const replaceBase64WithUploadedUrl = (
    htmlContent: string,
    imgMap: Record<string, string>
  ): string => {
    let processedHtml = htmlContent;

    // Process each base64 image and its uploaded URL
    for (const [base64Image, uploadedUrl] of Object.entries(imgMap)) {
      try {
        // Instead of using regex with the base64 string directly, which can contain regex special chars,
        // we'll use a safer approach by creating a DOM parser and replacing nodes
        const parser = new DOMParser();
        const doc = parser.parseFromString(processedHtml, "text/html");
        const imgElements = doc.getElementsByTagName("img");

        // Check each image element to see if it matches our base64 string
        for (const img of Array.from(imgElements)) {
          const src = img.getAttribute("src");
          if (src === base64Image) {
            img.setAttribute("src", uploadedUrl);
            img.setAttribute("width", "100%");
          }
        }

        // Convert back to string
        processedHtml = doc.body.innerHTML;
      } catch (error) {
        console.error("Error replacing base64 with uploaded URL:", error);
      }
    }

    return processedHtml;
  };

  const base64ToBlob = (base64Data: string, fileName: string): File => {
    const [header, content] = base64Data.split(",");
    const mimeMatch = header.match(/^data:(.*?);/);
    const mimeType = mimeMatch ? mimeMatch[1] : "image/png";

    const binaryStr = window.atob(content);
    const len = binaryStr.length;
    const arr = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
      arr[i] = binaryStr.charCodeAt(i);
    }

    const blob = new Blob([arr], { type: mimeType });
    return new File([blob], fileName, { type: mimeType });
  };

  const processHtmlWithImageRestrictions = async (html: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const imgElements = doc.getElementsByTagName("img");
    const uploadedImgMap: Record<string, string> = {};

    for (const img of Array.from(imgElements)) {
      const base64Image = img.getAttribute("src");
      if (base64Image && base64Image.startsWith("data:image")) {
        try {
          const mimeMatch = base64Image.match(
            /^data:image\/(png|jpeg|jpg|gif);base64,/
          );
          const fileExtension = mimeMatch ? mimeMatch[1] : "png";
          const now = new Date();
          const formattedDate = `${now.getFullYear()}${String(
            now.getMonth() + 1
          ).padStart(2, "0")}${String(now.getDate()).padStart(2, "0")}${String(
            now.getHours()
          ).padStart(2, "0")}${String(now.getMinutes()).padStart(
            2,
            "0"
          )}${String(now.getSeconds()).padStart(2, "0")}`;
          const fileName = `${formattedDate}.${fileExtension}`;

          const imageFile = base64ToBlob(base64Image, fileName);
          const res = await apiRequest({
            form: true,
            api: allApis.file_upload,
            payload: {
              parent_id: selectedClusterIndex,
              file: imageFile,
              filename: fileName,
              created_by: userData?.id,
              modified_by: userData?.id,
              parent_model: selectedQuestionIndex,
            },
          });

          if (res.file) {
            uploadedImgMap[base64Image] = res.file;
          }
        } catch (error) {
          console.error("Upload error:", error);
        }
      }
    }

    return uploadedImgMap;
  };

  const parseQuestionContent = (
    questionContent: string,
    uploadedImgMap: Record<string, string>
  ): Partial<Question> => {
    const question: Partial<Question> = {
      id: Math.random().toString(36).substr(2, 9),
      options: [],
      solutionHtml: "",
      explanation: "",
      questionHtml: "",
    };

    // Helper function to balance HTML tags
    const balanceHtmlTags = (content: string): string => {
      if (!content) return "";

      // Remove closing tags at start without opening tags
      let cleaned = content.replace(/^(\s*<\/[^>]+>)+/, "");

      // Track opening and closing tags
      const openTags: string[] = [];
      const tagPattern = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
      let match;

      // Find all tags and track them
      while ((match = tagPattern.exec(cleaned)) !== null) {
        const fullTag = match[0];
        const tagName = match[1].toLowerCase();

        if (!fullTag.includes("/>") && !fullTag.includes("</")) {
          // Opening tag
          openTags.push(tagName);
        } else if (fullTag.startsWith("</")) {
          // Closing tag
          if (openTags.includes(tagName)) {
            // Remove the last matching opening tag
            const index = openTags.lastIndexOf(tagName);
            openTags.splice(index, 1);
          }
        }
      }

      // Add missing opening tags at start
      let prefix = "";
      const seenClosingTags = new Set();
      const closingTagPattern = /<\/([a-z][a-z0-9]*)\b[^>]*>/gi;

      while ((match = closingTagPattern.exec(cleaned)) !== null) {
        const tagName = match[1].toLowerCase();
        if (!seenClosingTags.has(tagName) && !cleaned.includes(`<${tagName}`)) {
          prefix += `<${tagName}>`;
          seenClosingTags.add(tagName);
        }
      }

      // Add missing closing tags at end
      const suffix = openTags
        .reverse()
        .map((tag) => `</${tag}>`)
        .join("");

      return prefix + cleaned + suffix;
    };

    try {
      const normalizedContent = questionContent;

      // Extract question text while preserving formatting
      const questionTextMatch = normalizedContent.match(
        /^\s*(.*?)(?=Tag:|Options:)/s
      );

      if (questionTextMatch) {
        const extractedText = questionTextMatch[1].trim();
        question.text = extractedText.replace(/<[^>]+>/g, "").trim();
        question.questionHtml = balanceHtmlTags(extractedText);
      }

      // Extract optional tag
      const tagMatch = normalizedContent.match(
        /Tag:\s*([^]*?)(?=Options:|[\d\(][\.|\)])/i
      );
      if (tagMatch) {
        question.tag = tagMatch[1].replace(/<[^>]+>/g, "").trim();
      }
      // Extract options
      let optionsText =
        normalizedContent.match(
          /Options:.*?(?=Correct(?:\s*Answer:|[\s\n]*<strong>\s*Answer<\/strong>:|[\s\n]*Answer:)|$)/is
        )?.[0] || "";

      // Handle list items in options
      const listItems = optionsText.match(/<li>(.*?)<\/li>/g);
      if (listItems) {
        const optionLetters = ["a", "b", "c", "d"];
        listItems.forEach((item, idx) => {
          const cleanItem = item.replace(/<\/?li>/g, "").trim();
          optionsText = optionsText.replace(
            item,
            `(${optionLetters[idx]}) ${cleanItem}`
          );
        });
      }

      // Extract and format options
      const optionsMatch = optionsText.match(
        /\(([a-d])\)(.*?)(?=\([a-d]\)|$)/g
      );

      if (optionsMatch) {
        question.options = optionsMatch.map((opt, index) => {
          let optionText = opt.replace(/^\([a-d]\)/, "").trim();

          optionText = balanceHtmlTags(optionText);

          optionText = replaceBase64WithUploadedUrl(optionText, uploadedImgMap);

          return optionText;
        });
      }

      const correctAnswerMatch = normalizedContent.match(
        /(?:Correct\s*Answer|Answer)\s*:?\s*(?:<[^>]*>\s*)*\(([a-d])\)/i
      );

      if (correctAnswerMatch) {
        question.correctAnswer =
          correctAnswerMatch[1].toLowerCase().charCodeAt(0) - 97;
      }
      const solutionMatch = normalizedContent.match(
        /(?:Solution:|<strong>Solution:<\/strong>)(.*)$/s
      );
      if (solutionMatch) {
        const extractedSolution = solutionMatch[1].trim();
        question.solutionHtml = balanceHtmlTags(extractedSolution);
      }

      if (question.questionHtml) {
        question.questionHtml = replaceBase64WithUploadedUrl(
          question.questionHtml,
          uploadedImgMap
        );
      }
      if (question.solutionHtml) {
        question.solutionHtml = replaceBase64WithUploadedUrl(
          question.solutionHtml,
          uploadedImgMap
        );
      }
    } catch (error) {
      console.error("Error parsing question content:", error);
    }

    return question;
  };

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>,
    clusterIndex?: number
  ) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setCurrentFileName(file.name);
    setIsUploading(true);
    setUploadProgress(0);
    setHasFileUploaded(true);

    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const progressInterval = setInterval(() => {
          setUploadProgress((prev) => {
            if (prev >= 90) {
              clearInterval(progressInterval);
              return 90;
            }
            return prev + 10;
          });
        }, 200);

        const result = await mammoth.convertToHtml({
          arrayBuffer: e.target?.result as ArrayBuffer,
        });
        let htmlContent = result.value.replace(/\*\*/g, "").trim();

        const uploadedImgMap = await processHtmlWithImageRestrictions(
          htmlContent
        );

        const clusterNumbers = htmlContent.match(/Cluster (\d+\.\d+)/g) || [];

        let newClusters: Cluster[] = [];

        if (clusterNumbers.length > 0) {
          // Handle content with explicit clusters
          const sections = htmlContent.split(/Cluster \d+\.\d+/);

          clusterNumbers.forEach((clusterHeader, index) => {
            const clusterMatch = clusterHeader.match(/Cluster (\d+\.\d+)/);
            if (!clusterMatch) return;

            const clusterNumber = clusterMatch[1];
            const clusterContent = sections[index + 1];
            if (!clusterContent) return;

            const cluster = processClusterContent(
              clusterContent,
              clusterNumber,
              uploadedImgMap
            );
            if (cluster.questions.length > 0) {
              newClusters.push(cluster);
            }
          });
        } else {
          // Handle content without clusters - create a default cluster
          const defaultCluster = processClusterContent(
            htmlContent,
            "1",
            uploadedImgMap
          );
          if (defaultCluster.questions.length > 0) {
            newClusters = [defaultCluster];
          }
        }

        setClusters(newClusters);
        setSelectedClusterIndex(clusterIndex || 0);
        setSelectedQuestionIndex(0);

        clearInterval(progressInterval);
        setUploadProgress(100);
        setTimeout(() => {
          setIsUploading(false);
          setUploadProgress(0);
        }, 1000);
      } catch (error) {
        setIsUploading(false);
        setUploadProgress(0);
      }
    };
    reader.readAsArrayBuffer(file);
  };

  const handleSmartPaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault();
    const text = event.clipboardData.getData("text");

    const cleanText = text.replace(/\*\*/g, "").replace(/\*/g, "");

    const newQuestion: Partial<Question> = {
      id: Math.random().toString(36).substr(2, 9),
      options: [],
      solutionHtml: "",
      explanation: "",
      questionHtml: "",
    };

    const questionMatch = cleanText.match(
      /Question\s*\d+\.\s*(.*?)(?=Options:)/s
    );
    if (questionMatch) {
      newQuestion.text = questionMatch[1].trim();
      newQuestion.questionHtml = `<p>${newQuestion.text}</p>`;
    }

    const optionsText =
      cleanText.match(/Options:.*?(?=Correct Answer:|$)/s)?.[0] || "";
    const optionsMatch = optionsText.match(/\([a-d]\)[^()]+/gi);

    if (optionsMatch) {
      newQuestion.options = optionsMatch.map((opt) =>
        opt.replace(/^\([a-d]\)/, "").trim()
      );
    }

    const correctAnswerMatch = cleanText.match(
      /Correct Answer:\s*\(([a-d])\)/i
    );
    if (correctAnswerMatch) {
      newQuestion.correctAnswer =
        correctAnswerMatch[1].toLowerCase().charCodeAt(0) - 97;
    }

    const solutionMatch = cleanText.match(
      /Solution:(.*?)(?=\(this is my question|$)/s
    );
    if (solutionMatch) {
      const solutionText = solutionMatch[1].trim();
      newQuestion.explanation = solutionText;

      const solutionLines = solutionText
        .split("\n")
        .map((line) => line.trim())
        .filter(Boolean);

      let solutionHtml = "<div>";
      let inList = false;

      solutionLines.forEach((line) => {
        if (line.startsWith("*")) {
          if (!inList) {
            solutionHtml += "<ul>";
            inList = true;
          }
          const bulletPoint = line.substring(1).trim();
          solutionHtml += `<li>${bulletPoint}</li>`;
        } else {
          if (inList) {
            solutionHtml += "</ul>";
            inList = false;
          }
          solutionHtml += `<p>${line}</p>`;
        }
      });

      if (inList) {
        solutionHtml += "</ul>";
      }
      solutionHtml += "</div>";

      newQuestion.solutionHtml = solutionHtml;
    }

    if (
      newQuestion.text &&
      Array.isArray(newQuestion.options) &&
      newQuestion.options.length > 0 &&
      newQuestion.correctAnswer !== undefined
    ) {
      clusters[selectedClusterIndex].questions[
        clusters[selectedClusterIndex].questions.length - 1
      ] = newQuestion as Question;
      addNewQuestion(selectedClusterIndex);
    }
  };

  const processClusterContent = (
    content: string,
    clusterNumber: string,
    uploadedImgMap: Record<string, string>
  ): Cluster => {
    const cluster: Cluster = {
      id: Math.random().toString(36).substr(2, 9),
      name: `Cluster ${clusterNumber}`,
      questions: [],
    };

    const questionSegments = content.split(/Question \d+\./);

    const questions = questionSegments.filter((q) => q.trim());

    questions.forEach((questionContent) => {
      const question = parseQuestionContent(questionContent, uploadedImgMap);

      if (
        question.text &&
        question.options?.length === 4 &&
        question.correctAnswer !== undefined
      ) {
        let temp = {
          question_: question.text,
          option1: question.options[0],
          option2: question.options[1],
          option3: question.options[2],
          option4: question.options[3],
          correctAnswer: `option${question.correctAnswer + 1}`,
          solution: question.solutionHtml,
          tag: question.tag
            ? question.tag.split(",").map((tag) => tag.trim())
            : [],
        };
        cluster.questions.push(question as Question);
      }
    });

    return cluster;
  };

  const addNewCluster = () => {
    setClusters((prev) => [...prev, createEmptyCluster()]);
    setSelectedClusterIndex(clusters.length);
    setSelectedQuestionIndex(0);
  };

  const addNewQuestion = (clusterIndex: number) => {
    const lastQuestion =
      clusters[clusterIndex].questions[
        clusters[clusterIndex].questions.length - 1
      ];

    if (
      !lastQuestion ||
      !lastQuestion.text.trim() ||
      !lastQuestion.options.some((opt) => opt)
    ) {
      alert(
        "The last question in the cluster must be valid before adding a new question."
      );
      return;
    }

    const newClusters = [...clusters];
    const newQuestion = createEmptyQuestion();
    newClusters[clusterIndex].questions.push(newQuestion);

    const newQuestionIndex = newClusters[clusterIndex].questions.length - 1;

    setClusters(newClusters);
    setSelectedClusterIndex(clusterIndex);

    const absoluteQuestionIndex = getAbsoluteQuestionNumber(
      clusterIndex,
      newQuestionIndex
    );
    setSelectedQuestionIndex(absoluteQuestionIndex);

    const observer = new MutationObserver((mutationsList, observer) => {
      const newQuestionElement = document.getElementById(
        `question-editor-${absoluteQuestionIndex}`
      );
      if (newQuestionElement) {
        observer.disconnect();
        newQuestionElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    setTimeout(() => {
      const previewElements = Array.from(
        document.querySelectorAll(".preview-question-box")
      );

      const selectedQuestionElement =
        previewElements[selectedQuestionIndex - 1];
      if (selectedQuestionElement) {
        selectedQuestionElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 200);
  };

  const getValidQuestionsCount = (questions: Question[]) => {
    return questions.filter(
      (q) => q.text.trim() || q.options.some((opt) => opt.trim())
    ).length;
  };

  const examsData = useAppSelecter((state: ObjectValues) => state.exams);

  const clustersRef = useRef(clusters);

  useEffect(() => {
    clustersRef.current = clusters;
  }, [clusters]);

  const [snackbarState, setSnackbarState] = useState<snackbarProps>({
    open: false,
    message: "",
    severity: "info",
  });

  const handleSubmit = async () => {
    setIsLoading(true); // Start loading

    const currentClusters = clustersRef.current;
    const payload: Record<string, any> = {};
    const question_data: Record<string, any> = {};
    const syllabus_data: Record<string, any> = {
      goal_id: examsData.exams_structure.child.selected_row.id,
      section_id: examsData.exams_structure.child.child.selected_row.id,
      unit_id: examsData.exams_structure.child.child.child.selected_row.id,
      topic_id:
        examsData.exams_structure.child.child.child.child.selected_row.id,
      subtopic_id: subtopic?.id,
      cluster_type: clusterType,
      hindi_translation: hindiTranslation,
    };

    currentClusters.forEach((cluster) => {
      const clusterName = `${cluster.name}`;

      question_data[clusterName] = cluster.questions.map((question) => ({
        question: question.questionHtml,
        option1: question.options[0] || "",
        option2: question.options[1] || "",
        option3: question.options[2] || "",
        option4: question.options[3] || "",
        correct_answer: `option${(question.correctAnswer ?? 0) + 1}`,
        solution: question.solutionHtml || "",
        tag: question.tag
          ? question.tag.split(",").map((tag) => tag.trim())
          : [],
      }));
    });

    payload["question_data"] = question_data;
    payload["syllabus_data"] = syllabus_data;

    try {
      const res = await apiRequest({
        method: "POST",
        api: allApis.add_bulkquestions,
        payload: payload,
      });

      if (res.success) {
        setSnackbarState({
          open: true,
          message: "Questions added successfully!",
          severity: "success",
        });
        handleClose();
      } else {
        setSnackbarState({
          open: true,
          message: res.message || "Failed to add questions. Please try again.",
          severity: "error",
        });
      }
    } catch (error) {
      setSnackbarState({
        open: true,
        message: "An error occurred while adding questions. Please try again.",
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarState((prev) => ({
      ...prev,
      open: false,
    }));
    onClose();
  };

  dialogRef.current = {
    onSubmitForm: handleSubmit,
  };

  const updateQuestion = (
    clusterIndex: number,
    questionIndex: number,
    updatedQuestion: Question
  ) => {
    const newClusters = [...clusters];
    newClusters[clusterIndex].questions[questionIndex] = updatedQuestion;

    setClusters(newClusters);
  };

  const getAbsoluteQuestionNumber = (
    clusterIndex: number,
    questionIndex: number
  ) => {
    const previousValidQuestions = clusters
      .slice(0, clusterIndex)
      .reduce((sum, cluster) => {
        return (
          sum +
          cluster.questions.filter(
            (q) => q.text.trim() || q.options.some((opt) => opt.trim())
          ).length
        );
      }, 0);

    const currentClusterQuestions = questionIndex + 1;

    return previousValidQuestions + currentClusterQuestions;
  };

  useEffect(() => {
    const elements = document.querySelectorAll('[id^="question-editor-"]');
    setEditorElements(elements);
  }, [selectedClusterIndex, selectedQuestionIndex, clusters]);

  const handleQuestionSelect = (
    clusterIndex: number,
    questionIndex: number
  ) => {
    setSelectedClusterIndex(clusterIndex);
    setSelectedQuestionIndex(questionIndex);

    // Scroll to the correct editor element in the current cluster
    setTimeout(() => {
      const targetEditor = document.getElementById(
        `question-editor-${questionIndex + 1}`
      );

      if (targetEditor) {
        targetEditor.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 500);

    // Scroll preview panel
    setTimeout(() => {
      const previewElements = Array.from(
        document.querySelectorAll(".preview-question-box")
      );

      const selectedQuestionElement = previewElements[questionIndex];
      if (selectedQuestionElement) {
        selectedQuestionElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
        });
      }
    }, 200);
  };

  useEffect(() => {
    if (ref.current) {
      const images = ref.current.querySelectorAll("img");
    }
  }, []);

  useEffect(() => {
    setInterval(mathFunction, 1000);
  }, []);

  return (
    <>
      {isLoading && <FullScreenLoader open={isLoading} />}
      <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        {/* Header */}
        <Box sx={{ p: 2, borderBottom: "1px solid #e0e0e0" }}>
          <Breadcrumbs separator={<NavigateNextOutlined fontSize="small" />}>
            <Link
              component="button"
              variant="body1"
              onClick={onClose}
              sx={{
                textDecoration: "none",
                "&:hover": { textDecoration: "underline" },
              }}
            >
              Questions
            </Link>
            <Typography color="text.primary">Add New Question</Typography>
          </Breadcrumbs>
        </Box>

        <Box sx={{ flex: 1, display: "flex", overflow: "hidden" }}>
          {/* Left Panel - Questions List */}
          <Paper
            sx={{
              width: 400,
              overflow: "auto",
              borderRight: 1,
              borderColor: "divider",
            }}
          >
            <List>
              {/* Add the stepper wizard at the top */}
              <BulkQuestionsFormStepper
                hindiTranslation={hindiTranslation}
                setHindiTranslation={setHindiTranslation}
                clusterType={clusterType}
                handleClusterTypeChange={handleClusterTypeChange}
                fileInputRef={fileInputRef}
                currentFileName={currentFileName}
                isUploading={isUploading}
                uploadProgress={uploadProgress}
                hasFileUploaded={hasFileUploaded}
                handleSubmit={handleSubmit}
                isLoading={isLoading}
                countTotalQuestions={countTotalQuestions}
                isSingleQuestionPerCluster={isSingleQuestionPerCluster}
                handleSeparateQuestions={handleConfirmSingleQuestion}
              />

              {/* Hidden file input */}
              <input
                type="file"
                accept=".doc,.docx,.txt,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                id="upload-word-file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={(e) => handleFileUpload(e, selectedClusterIndex)}
              />
              {clusters.map((cluster, clusterIndex) => (
                <React.Fragment key={cluster.id}>
                  <ListItem
                    sx={{
                      backgroundColor:
                        selectedClusterIndex === clusterIndex
                          ? "rgba(25, 118, 210, 0.08)"
                          : "transparent",
                    }}
                  >
                    <ListItemText
                      primary={
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                        >
                          <Typography sx={{ fontWeight: "bold" }}>
                            {cluster.name}
                          </Typography>
                          <Typography
                            variant="caption"
                            sx={{
                              backgroundColor: "primary.main",
                              color: "white",
                              borderRadius: "12px",
                              px: 1,
                              py: 0.5,
                            }}
                          >
                            {cluster.questions.filter(isValidQuestion).length}{" "}
                            {cluster.questions.filter(isValidQuestion)
                              .length === 1
                              ? "Question"
                              : "Questions"}
                          </Typography>
                        </Stack>
                      }
                    />
                  </ListItem>
                  {cluster.questions
                    .filter(isValidQuestion)
                    .map((question, questionIndex) => (
                      <ListItem
                        key={question.id}
                        button
                        selected={
                          selectedClusterIndex === clusterIndex &&
                          selectedQuestionIndex ===
                            getAbsoluteQuestionNumber(
                              clusterIndex,
                              questionIndex
                            ) -
                              1
                        }
                        onClick={() =>
                          handleQuestionSelect(
                            clusterIndex,
                            getAbsoluteQuestionNumber(
                              clusterIndex,
                              questionIndex
                            ) - 1
                          )
                        }
                        sx={{
                          pl: 4,
                          "&.Mui-selected": {
                            backgroundColor: "lightgrey",
                          },
                          "&:hover": {
                            backgroundColor: "grey",
                          },
                        }}
                      >
                        <ListItemText
                          primary={
                            <Box sx={{ display: "flex", gap: 1 }}>
                              <span>{`Q${getAbsoluteQuestionNumber(
                                clusterIndex,
                                questionIndex
                              )}.`}</span>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: `${
                                    question.questionHtml.slice(0, 30) ||
                                    `Question ${questionIndex + 1}`
                                  }${question.text.length > 30 ? "..." : ""}`,
                                }}
                              />
                            </Box>
                          }
                        />
                      </ListItem>
                    ))}
                  <Divider />
                </React.Fragment>
              ))}
            </List>
          </Paper>

          {/* Center Panel - Editor */}
          <Box
            sx={{
              flex: 1,
              overflow: "auto",
              bgcolor: "background.default",
              p: 3,
            }}
            onPaste={handleSmartPaste}
          >
            {clusters[selectedClusterIndex]?.questions.map(
              (question, qIndex) => (
                <QuestionEditor
                  key={question.id}
                  question={question}
                  questionNumber={getAbsoluteQuestionNumber(
                    selectedClusterIndex,
                    qIndex
                  )}
                  onQuestionChange={(updatedQuestion) =>
                    updateQuestion(
                      selectedClusterIndex,
                      qIndex,
                      updatedQuestion
                    )
                  }
                  fileInputRef={fileInputRef}
                  isSelected={
                    selectedQuestionIndex + 1 ===
                    getAbsoluteQuestionNumber(selectedClusterIndex, qIndex)
                  }
                />
              )
            )}
          </Box>

          {/* Right Panel - Stepper and Preview */}
          <Paper
            sx={{
              width: 480,
              overflow: "auto",
              borderLeft: 1,
              borderColor: "divider",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* Preview section */}
            {hasFileUploaded && (
              <Box sx={{ p: 3 }}>
                <Typography variant="h6" gutterBottom>
                  Question Preview
                </Typography>
                <Box ref={ref} sx={{ overflow: "auto" }}>
                  {clusters.map((cluster, cidx) =>
                    cluster?.questions
                      .filter(
                        (question) =>
                          question.text.trim() &&
                          question.options.some((opt) => opt)
                      )
                      .map((question, index) => (
                        <QuestionPreview
                          key={question.id}
                          question={question}
                          questionNumber={getAbsoluteQuestionIndex(cidx, index)}
                          isSelected={
                            getAbsoluteQuestionNumber(cidx, index) - 1 ===
                            selectedQuestionIndex
                          }
                          onClick={() =>
                            setSelectedQuestionIndex(
                              getAbsoluteQuestionNumber(cidx, index) - 1
                            )
                          }
                        />
                      ))
                  )}
                </Box>
              </Box>
            )}
          </Paper>
        </Box>

        {/* Footer */}
        <Box
          sx={{
            p: 2,
            borderTop: 1,
            borderColor: "divider",
            bgcolor: "background.paper",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              p: 2,
            }}
          >
            <Box>
              <Button variant="outlined" onClick={onClose}>
                Cancel
              </Button>
            </Box>
            <Box sx={{ display: "flex", gap: 2 }}>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={() => addNewQuestion(selectedClusterIndex)}
              >
                Add Question to {clusters[selectedClusterIndex]?.name}
              </Button>
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                onClick={addNewCluster}
              >
                Add New Cluster
              </Button>
            </Box>
          </Box>
        </Box>
        <CustomSnackbar
          open={snackbarState.open}
          message={snackbarState.message}
          severity={snackbarState.severity}
          onClose={handleSnackbarClose}
        />
      </Box>
    </>
  );
}
