"use client";

import { useAppDispatch, useAppSelecter } from "../../hooks/storeHooks";
import {
  Box,
  Divider,
  Stack,
  Dialog,
  DialogActions,
  Button,
  DialogContent,
} from "@mui/material";
import CustomTable from "../../Components/CustomTable";
import CustomTab, { type TabProp } from "../../Components/CustomTab";
import CustomBreadcrumbs from "../../Components/CustomBreadcrumbs";
import globalColors from "../../globalColors";
import { headerContent, subHeading } from "../../Components/CustomTexts";
import { Addicon, stepperSvg } from "../../assets/svgs";
import { ExpandButton } from "../../Components/ExpandViewCard.tsx";
import type { ObjectValues } from "../login";
import { useEffect, useState, useRef } from "react";
import FormComponent from "../../Components/FormComponent";
import AllExpanded from "../AllExpanded";
import {
  ModuleDetails,
  allModules,
  allPermissions,
  allStatus,
  allStatusInClusterQuestion,
  checkPermisions,
  contents,
  getNextModule,
  historyArray,
  type statusType,
  type structureProps,
} from "../../store/slices/constants/staticContents";
import { setExamStructure, setFetchAgain } from "../../store/slices/exams";
import CustomSearch from "../../Components/CustomSearch";
import CustomDialog from "../../Components/CustomDialog";
import CustomTextField from "../../Components/CustomTextfield";
import { fetchTableData } from "../../store/slices/exams/thunks/fetchTableData";
import { allApis } from "../../request/config";
import apiRequest from "../../request/apiRequest/apiRequest";
import { showSnack } from "../../store/slices/constants";
import ConfirmationDialog from "../../Components/ConfirmationDialog";
import PathOrderManagment from "../../Components/PathOrderManagment";
import CopyComponent from "../../Components/CopyComponent";
import { rowCenter, rowHorizontalCenter } from "../../GlobalStyles";
import {
  fetchAllClusters,
  fetchAllExamsTabData,
  fetchAllQuestionsTabData,
  fetchContentData,
  fetchSinlgQustion,
  fetchTagsData,
} from "../../store/slices/exams/thunks/fetchContentData";
import { fetchHistory } from "../../store/slices/exams/thunks/fetchHistory";

import QuestionForm from "./QuestionForm";
import QuestionFormComponentExams from "./QuestionFormComponentExams";
import BulkQuestionsForm from "./BulkQuestionsForm";
export const formatStructureData = (
  old: structureProps,
  newFields: ObjectValues,
  title: (typeof allModules)[number]
) => {
  const updateStructure = (structure: structureProps): structureProps => {
    if (structure.title === title) {
      return {
        ...structure,
        ...newFields,
      };
    }
    if (structure.child) {
      return {
        ...structure,
        dockedList:
          newFields.dockedList && structure.title !== title
            ? false
            : structure.dockedList,
        child: updateStructure(structure.child),
      };
    }

    // If no update is needed, return the original structure
    return structure;
  };

  // Update the structure and return the updated version
  return updateStructure(old);
};
function Exams() {
  const dialogRef = useRef<{ onSubmitForm?: () => void }>({});
  const dispatch = useAppDispatch();
  const examsData = useAppSelecter((state: ObjectValues) => state.exams);
  const fetchAgain = examsData.fetchAgain;
  const tags_list = examsData.tags_list ?? [];
  const all_clusters = examsData.all_clusters ?? [];
  const history_list = examsData.history_list;
  const all_exam_status_data = examsData.all_exam_status_data;
  const all_clusters_status_data = examsData.all_clusters_status_data;
  const all_questions_status_data = examsData.all_questions_status_data;
  const all_goal_status_data = examsData.all_goal_status_data;
  const userSlice = useAppSelecter(
    (state: ObjectValues) => state.userSlice.loggedUser
  );
  const is_expanded = examsData.is_expanded;
  const dockedCard: structureProps = examsData.docked_exams_table;
  const [dialogs, setDialog] = useState<ObjectValues>({});
  const [copyDialog, setCopyDialog] = useState<ObjectValues>({});
  const exams_structure = examsData.exams_structure;

  const formatted_exams_structure: structureProps[] =
    examsData.formatted_exams_structure;
  const [isBulkQuestionDialogOpen, setIsBulkQuestionDialogOpen] = useState<{
    open: boolean;
    data?: ObjectValues;
  }>({ open: false });
  const [selectedDockedCard, setSelectedDockedCard] =
    useState<structureProps | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleBulkQuestionsSubmit = (questions: any) => {

    setIsBulkQuestionDialogOpen({ open: false });

    dispatch(setFetchAgain({ value: true }));
  };

  
  const getGoalId = () => {
    return formatted_exams_structure.find(
      (item) => item.title === allModules[1]
    )?.selected_row?.id;
  };
  const getGoalStatus = () => {
    return formatted_exams_structure.find(
      (item) => item.title === allModules[1]
    )?.selected_row?.status;
  };
  const statusUpdateFunction = async (status: string, row: any) => {
    const response: any = await apiRequest({
      api:
        status === "deleted" && allApis[dockedCard.title].delete
          ? allApis[dockedCard.title].delete(row?.selected?.id)
          : allApis[dockedCard.title].status_update
          ? allApis[dockedCard.title].status_update(
              row?.selected?.cluster?.id ?? row?.selected?.id
            )
          : allApis[dockedCard.title].edit(row?.selected?.id),
      payload:
        status === "deleted" && allApis[dockedCard.title].delete
          ? {}
          : status === "deleted"
          ? { is_active: false }
          : dockedCard.title === "questions"
          ? {
              status: status,
              id: row?.selected?.id,
            }
          : {
              status: status,
              id: row?.selected?.id,
              // internal_remark: row[contents.feedback] ?? "",
            },
      method:
        status === "deleted" && allApis[dockedCard.title].delete
          ? "DELETE"
          : "PATCH",
    });

    const clearAll = () => {};
    setDialog({});
    dispatch(
      setExamStructure(
        formatStructureData(
          exams_structure,
          {
            fetchAgain: true,
            loading: true,
          },
          dockedCard.title
        )
      )
    );
    if (!response || (response && response.error)) {
      clearAll();
    } else {
      if (status === "deleted") {
        dispatch(
          showSnack({
            message: contents.successStatusChange(row?.selected?.name, status),
            severity: "success",
            open: true,
          })
        );
        clearAll();
      } else {
        const content = examsData.content_list.find(
          (item: { model: string }) =>
            item.model ===
            (dockedCard.title === allModules[2] ||
            dockedCard.title === allModules[3] ||
            dockedCard.title === allModules[4] ||
            dockedCard.title === allModules[5]
              ? "syllabustreenode"
              : dockedCard.title === allModules[6]
              ? "cluster"
              : dockedCard.title === allModules[7]
              ? "question"
              : dockedCard.title.replace("s", ""))
        );
        if (content && content.id) {
          const statusResponse: any = await apiRequest({
            api: allApis.status_create,
            payload: {
              parent_id: row?.selected?.cluster?.id ?? row?.selected?.id,
              old_status:
                row?.selected?.cluster?.status ?? row?.selected?.status,
              new_status: status,
              feedback: row[contents.feedback] ?? "",
              parent_model: content.id,
            },
            method: "POST",
          });
          if (!statusResponse) {
          } else {
            await dispatch(
              showSnack({
                message: contents.successStatusChange(
                  row?.selected?.name,
                  status
                ),
                severity: "success",
                open: true,
              })
            );
            await clearAll();
          }
        }
      }
    }
  };
  const getEditButton = (rowData?: ObjectValues, type?: "button") => {
    return (
      checkPermisions(
        userSlice?.user_permissions ?? [],
        "change",
        getPermissionName(),
        dockedCard.title == "questions" ? "content" : "syllabus"
      ) &&
      (type
        ? {
            content: "Edit",
            onClick: (row: ObjectValues) => {
              dispatch(
                setExamStructure(
                  formatStructureData(
                    exams_structure,
                    {
                      edit: true,
                      selected_row: rowData ?? row,
                      selected_row_field: "name",
                    },
                    dockedCard.title
                  )
                )
              );
            },
          }
        : {
            label: "Edit",
            onclick: (row: ObjectValues) => {
              dispatch(
                setExamStructure(
                  formatStructureData(
                    exams_structure,
                    {
                      edit: true,
                      selected_row: rowData ?? row,
                      selected_row_field: "name",
                    },
                    dockedCard.title
                  )
                )
              );
            },
          })
    );
  };

  const getAddBulkQuestionsButton = (row: ObjectValues) => {
    if (dockedCard.title === "subtopics") {
      return {
        label: "Add Questions in Bulk",
        onclick: () => {
          setSelectedDockedCard(dockedCard);
          setIsBulkQuestionDialogOpen({ data: row, open: true });
        },
      };
    }
    return null;
  };

  const getDeleteButton = () => {
    return checkPermisions(
      userSlice?.user_permissions ?? [],
      "delete",
      getPermissionName(),
      dockedCard.title == "questions" ? "content" : "syllabus"
    ) &&
      dockedCard.title !== "clusters" &&
      dockedCard.title !== "questions"
      ? {
          label: `Delete ${dockedCard.title}`,
          onclick: (row: ObjectValues) => {
            setDialog((prevDialogs) => {
              return {
                ...prevDialogs,
                open: true,
                actions: (dialog: any) => {
                  return [
                    {
                      sx: { width: "130px", padding: "6px" },
                      content: "Save",
                      disabled: !dialog[contents.feedback],
                      variant: "contained",
                      onClick: () => {
                        statusUpdateFunction("deleted", dialog);
                        dispatch(fetchAllExamsTabData());
                      },
                    },
                  ];
                },
                title: "Delete",
                selected: row,
              };
            });
          },
        }
      : false;
  };
  const fetchSingleClusterQuestions = async (id: string) => {
    const res = apiRequest({
      api: allApis.cluster_questions(`${id}/`),
      method: "GET",
    });
    return res;
  };
  const allStepForm = ModuleDetails[dockedCard.title]?.inputSteps(
    {
      all_clusters: all_clusters ?? [],
      fetchSingleClusterQuestions: fetchSingleClusterQuestions,
      ...dockedCard,
      selected_row: dockedCard.selected_row ?? copyDialog.row ?? null,
    },
    examsData.docked_exams && examsData.docked_exams.selected_row
      ? examsData.docked_exams
      : null
  );

  const formProps = {
    successMessage: (res: ObjectValues) => {
      return ModuleDetails[dockedCard.title].success(
        dockedCard.edit ? dockedCard.selected_row ?? {} : undefined,
        res
      );
    },
    formatPayload: ModuleDetails[dockedCard.title]?.formatPayload,
    extraFields: dockedCard.edit
      ? []
      : dockedCard.title === allModules[2] ||
        dockedCard.title === allModules[3] ||
        dockedCard.title === allModules[4] ||
        dockedCard.title === allModules[5]
      ? [{ label: "goal", value: getGoalId() }]
      : [
          { label: "status", value: "draft" },
          {
            label: "is_active",
            value: true,
          },
        ],
    inputSteps: allStepForm,
    inputFormArray: ModuleDetails[dockedCard.title]?.inputArray(
      {
        tags_list: tags_list ?? [],
        ...dockedCard,

        selected_row: dockedCard.selected_row ?? copyDialog.row ?? null,
        // tagContent:
      },
      examsData.docked_exams && examsData.docked_exams.selected_row
        ? examsData.docked_exams
        : null
    ),
    header: dockedCard.title,
    editData: dockedCard.edit ? dockedCard.selected_row ?? {} : undefined,
    api: {
      edit: dockedCard.edit
        ? allApis[dockedCard.title].edit(
            dockedCard.selected_row?.id ??
              dockedCard.selected_row?.cluster?.id ??
              ""
          )
        : null,
      edit_method: dockedCard.title === "clusters" ? "POST" : "PATCH",
      create: allApis[dockedCard.title].create,
      create2: allApis[dockedCard.title].create2,
    },
    onSubmit: () => {
      if (
        dockedCard.title === allModules[6] &&
        fetchAgain.all_cluster !== true
      ) {
        dispatch(setFetchAgain({ all_cluster: true }));
      }
    },
    onClear: () => {
      dispatch(
        setExamStructure(
          formatStructureData(
            exams_structure,
            {
              create: false,
              edit: false,
              selected_row: null,
              fetchAgain: true,
            },
            dockedCard.title
          )
        )
      );
    },
  };
  const getStatusButtons = (
    list: statusType[],
    row: ObjectValues,
    type?: "popper"
  ) => {
    const filteredList = list;
    return filteredList.map((item: statusType) => {
      const actionLabel =
        typeof item.actionLabel === "function"
          ? item.actionLabel(item.label) // Pass the status to the actionLabel function
          : item.actionLabel;
      return type
        ? {
            label: actionLabel,
            onclick: (rowData: ObjectValues) => {
              setDialog((prevDialogs) => {
                return {
                  ...prevDialogs,
                  open: true,
                  actions: (dialog: any) => {
                    return [
                      {
                        sx: { width: "130px", padding: "6px" },
                        content: "Save",
                        disabled: !dialog[contents.feedback],
                        variant: "contained",
                        onClick: () => {
                          statusUpdateFunction(item.label, dialog);
                          dispatch(fetchAllExamsTabData());
                          // Handle the save action
                        },
                      },
                    ];
                  },
                  title: actionLabel,
                  selected: rowData,
                };
              });
            },
          }
        : {
            content: actionLabel,
            sx: { minWidth: "fit-content" },
            onClick: () => {
              setDialog((prevDialogs) => {
                return {
                  ...prevDialogs,
                  open: true,
                  actions: (dialog: any) => {
                    return [
                      {
                        sx: { width: "130px", padding: "6px" },
                        content: "Save",
                        disabled: !dialog[contents.feedback],
                        variant: "contained",
                        onClick: () => {
                          statusUpdateFunction(item.label, dialog);
                          dispatch(fetchAllExamsTabData());
                          // Handle the save action
                        },
                      },
                    ];
                  },
                  title: actionLabel,
                  selected: row,
                };
              });
            },
          };
    });
  };
  
  // Changed from ["published", "archived", "approved"] to empty array
  const noEditStatus: string[] = [];
  
  const actionarr = (row: any): any[] => {
    // Always include these three buttons regardless of status or module
    const actions: any[] = [];
    
    // Always add edit button
    const editButton = getEditButton();
    if (editButton) {
      actions.push(editButton);
    }
    
    // Always add delete button
    const deleteButton = getDeleteButton();
    if (deleteButton) {
      actions.push(deleteButton);
    }
    
    // Always add bulk question button
    const bulkButton = getAddBulkQuestionsButton(row);
    if (bulkButton) {
      actions.push(bulkButton);
    }
    
    // Add status-specific buttons
    if (
      ModuleDetails[dockedCard.title]?.isTab &&
      !["clusters", "questions"].includes(dockedCard.title)
    ) {
      const rowStatus = row.status;
      switch (dockedCard.selectedTab?.label.toLowerCase()) {
        case "all":
          switch (rowStatus?.toLowerCase()) {
            case "draft":
              actions.push(...getStatusButtons(
                allStatus.filter((status) => status.label === "ready"),
                {},
                "popper"
              ).filter(button => button !== undefined));
              break;
            case "ready":
              actions.push(...getStatusButtons(
                allStatus.filter((status) => status.label === "draft"),
                {},
                "popper"
              ).filter(button => button !== undefined));
              break;
            case "approved":
              actions.push(...getStatusButtons(
                allStatus.filter((status) => status.label === "rejected"),
                {},
                "popper"
              ).filter(button => button !== undefined));
              break;
            case "published":
              actions.push(...getStatusButtons(
                allStatus.filter((status) => status.label === "unpublished"),
                {},
                "popper"
              ).filter(button => button !== undefined));
              break;
            case "archived":
              if (dockedCard.title !== allModules[0]) {
                actions.push(...getStatusButtons(
                  allStatus.filter((status) => status.label === "unpublished"),
                  {},
                  "popper"
                ).filter(button => button !== undefined));
              }
              break;
            case "rejected":
              actions.push(...getStatusButtons(
                allStatus.filter((status) => status.label === "draft"),
                {},
                "popper"
              ).filter(button => button !== undefined));
              break;
            case "unpublished":
              const archivedButton = getStatusButtons(
                allStatus.filter((status) => status.label === "archived"),
                {},
                "popper"
              )[0];
              const publishedButton = getStatusButtons(
                allStatus.filter((status) => status.label === "published"),
                {},
                "popper"
              )[0];
              if (archivedButton) actions.push(archivedButton);
              if (publishedButton) actions.push(publishedButton);
              break;
          }
          break;
        case "draft":
          actions.push(...getStatusButtons(
            allStatus.filter((status) => status.label === "ready"),
            {},
            "popper"
          ).filter(button => button !== undefined));
          break;
        case "ready":
          actions.push(...getStatusButtons(
            allStatus.filter(
              (status) =>
                status.label === "approved" || status.label === "rejected"
            ),
            {},
            "popper"
          ).filter(button => button !== undefined));
          break;
        case "approved":
          actions.push(...getStatusButtons(
            allStatus.filter((status) => status.label === "rejected"),
            {},
            "popper"
          ).filter(button => button !== undefined));
          break;
        case "published":
          actions.push(...getStatusButtons(
            allStatus.filter((status) => status.label === "unpublished"),
            {},
            "popper"
          ).filter(button => button !== undefined));
          break;
        case "archived":
          if (dockedCard.title !== allModules[0]) {
            actions.push(...getStatusButtons(
              allStatus.filter((status) => status.label === "unpublished"),
              {},
              "popper"
            ).filter(button => button !== undefined));
          }
          break;
        case "rejected":
          actions.push(...getStatusButtons(
            allStatus.filter((status) => status.label === "draft"),
            {},
            "popper"
          ).filter(button => button !== undefined));
          break;
        case "unpublished":
          const archivedBtn = getStatusButtons(
            allStatus.filter((status) => status.label === "archived"),
            {},
            "popper"
          )[0];
          const publishedBtn = getStatusButtons(
            allStatus.filter((status) => status.label === "published"),
            {},
            "popper"
          )[0];
          if (archivedBtn) actions.push(archivedBtn);
          if (publishedBtn) actions.push(publishedBtn);
          break;
      }
    } else if (
      ModuleDetails[dockedCard.title]?.isTab &&
      ["clusters", "questions"].includes(dockedCard.title)
    ) {
      switch (dockedCard.selectedTabInClusterQuestion?.label.toLowerCase()) {
        case "draft":
          actions.push(...getStatusButtons(
            allStatusInClusterQuestion.filter((status) => status.label === "ready"),
            {},
            "popper"
          ).filter(button => button !== undefined));
          break;
        case "ready":
          actions.push(...getStatusButtons(
            allStatusInClusterQuestion.filter(
              (status) =>
                status.label === "approved" || status.label === "rejected"
            ),
            {},
            "popper"
          ).filter(button => button !== undefined));
          break;
        case "approved":
          actions.push(...getStatusButtons(
            allStatusInClusterQuestion.filter((status) => status.label === "rejected"),
            {},
            "popper"
          ).filter(button => button !== undefined));
          break;
        case "published":
          actions.push(...getStatusButtons(
            allStatusInClusterQuestion.filter((status) => status.label === "unpublished"),
            {},
            "popper"
          ).filter(button => button !== undefined));
          break;
        case "archived":
          if (dockedCard.title !== allModules[0]) {
            actions.push(...getStatusButtons(
              allStatusInClusterQuestion.filter((status) => status.label === "unpublished"),
              {},
              "popper"
            ).filter(button => button !== undefined));
          }
          break;
        case "rejected":
          actions.push(...getStatusButtons(
            allStatusInClusterQuestion.filter((status) => status.label === "draft"),
            {},
            "popper"
          ).filter(button => button !== undefined));
          break;
        case "unpublished":
          const archivedClusterBtn = getStatusButtons(
            allStatusInClusterQuestion.filter((status) => status.label === "archived"),
            {},
            "popper"
          )[0];
          const publishedClusterBtn = getStatusButtons(
            allStatusInClusterQuestion.filter((status) => status.label === "published"),
            {},
            "popper"
          )[0];
          if (archivedClusterBtn) actions.push(archivedClusterBtn);
          if (publishedClusterBtn) actions.push(publishedClusterBtn);
          break;
      }
    }
  
    // Filter out any undefined or false buttons
    return actions.filter((button): button is any => button !== undefined && button !== false);
  };
  
  const fetchSingleQuestionDetails = (id: string): void => {
    dispatch(
      fetchSinlgQustion({
        api: `cms/content/question/${id}/`,
        method: "GET",
        payload: {},
        id: id,
      })
    );
  };
  
  // Always allow actions by returning false regardless of status
  const noAction = (): boolean => {
    return false;
  };
  
  const buttonarr = (data: ObjectValues): any[] => {
    // Start with the edit button for all rows
    const buttons: any[] = [];
    const editBtn = getEditButton(data, "button");
    if (editBtn) buttons.push(editBtn);
    
    if (
      ModuleDetails[dockedCard.title]?.isTab &&
      !["clusters", "questions"].includes(dockedCard.title)
    ) {
      const rowStatus = data?.status?.toLowerCase();
      
      let statusButtons: any[] = [];
      switch (dockedCard.selectedTab?.label.toLowerCase()) {
        case "all":
          // In case of "ALL", return action buttons based on the row's specific status
          switch (rowStatus?.toLowerCase()) {
            case "draft":
              statusButtons = getStatusButtons(
                allStatus.filter((status) => status.label === "ready"),
                data
              );
              break;
            case "ready":
              statusButtons = getStatusButtons(
                allStatus.filter(
                  (status) =>
                    status.label === "approved" || status.label === "rejected"
                ),
                data
              );
              break;
            case "approved":
              statusButtons = getStatusButtons(
                allStatus.filter(
                  (status) => status.label === "published"
                ),
                data
              );
              break;
            case "published":
              statusButtons = getStatusButtons(
                allStatus.filter((status) => status.label === "archived"),
                data
              );
              break;
            case "archived":
              statusButtons = dockedCard.title === allModules[0]
                ? getStatusButtons(
                    allStatus.filter(
                      (status) => status.label === "unpublished"
                    ),
                    data
                  )
                : [
                    {
                      content: "Create a Copy",
                      onClick: () => {
                        setCopyDialog({ open: true, row: data });
                      },
                    },
                  ];
              break;
            case "rejected":
              statusButtons = getStatusButtons(
                allStatus.filter((status) => status.label === "draft"),
                data
              );
              break;
            case "unpublished":
              // Edit button is already included above
              break;
            default:
              break;
          }
          break;
        case "draft":
          statusButtons = getStatusButtons(
            allStatus.filter((status) => status.label === "ready"),
            data
          );
          break;
        case "ready":
          statusButtons = getStatusButtons(
            allStatus.filter(
              (status) =>
                status.label === "approved" || status.label === "rejected"
            ),
            data
          );
          break;
        case "approved":
          statusButtons = getStatusButtons(
            allStatus.filter(
              (status) => status.label === "published"
            ),
            data
          );
          break;
        case "published":
          statusButtons = getStatusButtons(
            allStatus.filter((status) => status.label === "archived"),
            data
          );
          break;
        case "archived":
          statusButtons = dockedCard.title === allModules[0]
            ? getStatusButtons(
                allStatus.filter((status) => status.label === "unpublished"),
                data
              )
            : [
                {
                  content: "Create a Copy",
                  onClick: () => {
                    setCopyDialog({ open: true, row: data });
                  },
                },
              ];
          break;
        case "rejected":
          statusButtons = getStatusButtons(
            allStatus.filter((status) => status.label === "draft"),
            data
          );
          break;
        case "unpublished":
          // Edit button is already included above
          break;
      }
      
      // Add status buttons if any exist
      if (statusButtons && statusButtons.length > 0) {
        buttons.push(...statusButtons.filter(button => button !== undefined));
      }
    } else if (
      ModuleDetails[dockedCard.title]?.isTab &&
      ["clusters", "questions"].includes(dockedCard.title)
    ) {
      let statusButtons: any[] = [];
      switch (dockedCard.selectedTabInClusterQuestion?.label.toLowerCase()) {
        case "draft":
          statusButtons = getStatusButtons(
            allStatusInClusterQuestion.filter(
              (status) => status.label === "ready"
            ),
            data
          );
          break;
        case "ready":
          statusButtons = getStatusButtons(
            allStatusInClusterQuestion.filter(
              (status) =>
                status.label === "approved" || status.label === "rejected"
            ),
            data
          );
          break;
        case "approved":
          statusButtons = getStatusButtons(
            allStatusInClusterQuestion.filter(
              (status) => status.label === "published"
            ),
            data
          );
          break;
        case "published":
          statusButtons = getStatusButtons(
            allStatusInClusterQuestion.filter(
              (status) => status.label === "archived"
            ),
            data
          );
          break;
        case "archived":
          statusButtons = dockedCard.title === allModules[0]
            ? getStatusButtons(
                allStatusInClusterQuestion.filter(
                  (status) => status.label === "unpublished"
                ),
                data
              )
            : [
                {
                  content: "Create a Copy",
                  onClick: () => {
                    setCopyDialog({ open: true, row: data });
                  },
                },
              ];
          break;
        case "rejected":
          statusButtons = getStatusButtons(
            allStatusInClusterQuestion.filter(
              (status) => status.label === "draft"
            ),
            data
          );
          break;
        case "unpublished":
          // Edit button is already included above
          break;
      }
      
      // Add status buttons if any exist
      if (statusButtons && statusButtons.length > 0) {
        buttons.push(...statusButtons.filter(button => button !== undefined));
      }
    } else {
      // For non-tab modules, always show edit button (already added above)
    }
    
    return buttons.filter((button): button is any => button !== undefined);
  };

  const [tableData, setTableData] = useState<ObjectValues>({
    actual: [],
    copy: [],
    all_status: [],
  });
  useEffect(() => {
    const isAllStatusTab =
      dockedCard.selectedTab?.label.toLowerCase() === "all";
    const moduleTitle = dockedCard.title;
    let filterLabel: string | undefined = "all";
    if (moduleTitle == "goals" || moduleTitle == "exams") {
      filterLabel = dockedCard.selectedTab?.label.toLowerCase();
    } else if (moduleTitle == "clusters" || moduleTitle == "questions") {
      filterLabel =
        dockedCard.selectedTabInClusterQuestion?.label.toLowerCase();
    }

    const filteredData = ModuleDetails[dockedCard.title]
      ?.formatList(dockedCard.list)
      ?.filter((s) =>
        isAllStatusTab && moduleTitle != "clusters"
          ? true // If All Status tab, don't filter by status
          : (s.cluster?.status ?? s.status) === filterLabel
      )
      ?.filter((it) => it.is_active);
    const sectionsData = ModuleDetails[dockedCard.title]
      ?.formatList(dockedCard.list)
      ?.filter((it) => it.is_active);

    setTableData(
      ModuleDetails[dockedCard.title]?.arrange_path
        ? {
            all_status:
              dockedCard.title == "sections" ||
              dockedCard.title == "unit" ||
              dockedCard.title == "topics" ||
              dockedCard.title == "subtopics"
                ? sectionsData
                : filteredData,
            actual:
              dockedCard.title == "sections" ||
              dockedCard.title == "unit" ||
              dockedCard.title == "topics" ||
              dockedCard.title == "subtopics"
                ? sectionsData
                : filteredData,
            copy:
              dockedCard.title == "sections" ||
              dockedCard.title == "unit" ||
              dockedCard.title == "topics" ||
              dockedCard.title == "subtopics"
                ? sectionsData
                : filteredData,
          }
        : {
            all_status:
              dockedCard.title == "sections" ||
              dockedCard.title == "unit" ||
              dockedCard.title == "topics" ||
              dockedCard.title == "subtopics"
                ? sectionsData
                : filteredData,
            actual:
              dockedCard.title == "sections" ||
              dockedCard.title == "unit" ||
              dockedCard.title == "topics" ||
              dockedCard.title == "subtopics"
                ? sectionsData
                : filteredData,
            copy:
              dockedCard.title == "sections" ||
              dockedCard.title == "unit" ||
              dockedCard.title == "topics" ||
              dockedCard.title == "subtopics"
                ? sectionsData
                : filteredData,
          }
    );
    if (dockedCard.title === allModules[7] && fetchAgain.all_tags !== false) {
      dispatch(fetchTagsData());
      dispatch(setFetchAgain({ all_tags: false }));
    }
    if (
      dockedCard.title === allModules[6] &&
      fetchAgain.all_cluster !== false
    ) {
      dispatch(fetchAllClusters());
      dispatch(setFetchAgain({ all_cluster: false }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dockedCard]);

  useEffect(() => {
    if (examsData.content_list?.length === 0) {
      dispatch(fetchContentData());
    }
    dispatch(fetchAllExamsTabData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeLastS = (word: string) => {
    if (word.endsWith("s")) {
      return word.slice(0, -1);
    }
    return word;
  };

  const fetchHistorys = (row: ObjectValues) => {
    const content = examsData.content_list.find(
      (item: { model: string }) => item.model === removeLastS(dockedCard.title) //.replace("s", "")
    );
    if (content && content.id) {
      dispatch(fetchHistory({ row_id: row.id, content_id: content.id }));
    }
  };

  // const fetchHistorys = (row: ObjectValues) => {
  //   const content = examsData.content_list.find(
  //     (item: { model: string }) =>
  //       item.model === dockedCard.title.replace("s", "")
  //   );
  //   if (content && content.id) {
  //     dispatch(fetchHistory({ row_id: row.id, content_id: content.id }));
  //   }
  // };

  useEffect(() => {
    if (dockedCard.fetchAgain === true && !is_expanded) {
      if (dockedCard.title == "questions") {
        dispatch(
          fetchAllQuestionsTabData(
            examsData.docked_exams.selected_row.cluster?.id
          )
        );
      }

      dispatch(
        fetchTableData({
          api: `${allApis[dockedCard.title].list(
            dockedCard.title === allModules[6] ||
              dockedCard.title === allModules[7]
              ? dockedCard.selectedTabInClusterQuestion?.label.toLowerCase()
              : dockedCard.selectedTab?.label.toLowerCase(),
            examsData?.docked_exams && examsData.docked_exams.selected_row
              ? examsData.docked_exams.selected_row.cluster?.id ??
                  examsData.docked_exams.selected_row.id
              : "",
            dockedCard.title === allModules[2] ||
              dockedCard.title === allModules[3] ||
              dockedCard.title === allModules[4] ||
              dockedCard.title === allModules[5]
              ? getGoalId()
              : "",
              { page: examsData?.paginationModel?.page + 1 || 1, size: examsData?.paginationModel?.pageSize || 10 }
          )}`,
          method: "GET",
          payload: {},
          token: dockedCard.title,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dockedCard.fetchAgain]);

  const hasCreateOrEdit: any = (obj: any) => {
    if (!obj) {
      return false;
    } else if (obj.create || obj.edit) {
      return true;
    } else if (obj.child) {
      return hasCreateOrEdit(obj.child);
    } else {
      return false;
    }
  };

  // eslint-disable react/no-array-index-key
  const columns =
    ModuleDetails[dockedCard.title]?.tableHeaders({
      noAction: noAction,
      fetchSingleQuestionDetails: fetchSingleQuestionDetails,
      tags_list: tags_list ?? [],
      tableWidth:
        (document.getElementById("table-width")?.offsetWidth ?? 400) - 5,
      actionWidth: () => {
        if (ModuleDetails[dockedCard.title]?.isTab) {
          switch (dockedCard.selectedTab?.label.toLowerCase()) {
            case "draft":
              return 120;
            case "ready":
              return 130;
            case "approved":
              return 122;
            case "published":
              return 124;
            case "archived":
              return dockedCard.title === allModules[1] ? 180 : 120;
            case "rejected":
              return 140;

            case "unpublished":
              return 120;
          }
        } else {
          return 120;
        }
      },
      buttonarr,
      actionarr,
      onPreview: (row: any) => {
        dispatch(
          setExamStructure(
            formatStructureData(
              exams_structure,
              {
                selected_row: row,
                selected_row_field: "name",
                dockedList: true,
                dockedTable: false,
                child: {
                  fetchAgain: true,
                  title: getNextModule(dockedCard.title),
                  list: [],
                  parentDetails: row,
                  child: null,
                  loading: true,
                  create: false,
                  edit: false,
                  selected_row: null,
                  dockedTable: true,
                  dockedList: false,
                  selectedTab: allStatus[0],
                  selectedTabInClusterQuestion: allStatusInClusterQuestion[0],
                },
              },
              dockedCard.title
            )
          )
        );
      },
      fetchHistorys,
    }) ?? [];
  const getPermissionName = (title?: string) => {
    const indexOfTitle = allModules.indexOf(title ?? dockedCard.title);
    return allPermissions[indexOfTitle];
  };
  const tableContent: any = (it: any) => {
    return (
      checkPermisions(
        userSlice?.user_permissions ?? [],
        "view",
        getPermissionName(),
        dockedCard.title == "questions" ? "content" : "syllabus"
      ) && (
        <Stack
          height={"100%"}
          spacing={"10px"}
          m="0"
          id="table-width"
          width="100%"
          overflow={"hidden"}
        >
          <CustomTable
            renderRowDetails={(row: any) => {
              const list = history_list[row.id] ?? [];

              return (
                <Stack
                  spacing="8px"
                  style={{
                    margin: "10px 0",
                    padding: "8px 10px",
                    border: "1px solid #C8C8C8",
                    borderRadius: "8px",
                    background: globalColors.primary.appBackground,
                    minHeight: "70px",
                  }}
                >
                  <Stack
                    spacing="10px"
                    sx={{
                      borderRadius: "8px",
                      backgroundColor: globalColors.primary.pureWhite,
                      padding: "8px 8px 8px 50px",
                    }}
                    direction="row"
                  >
                    {historyArray.map(({ label, sx }) => {
                      return subHeading({
                        key: `row-${label}-header`,
                        text: label,
                        textAlign: "start",
                        parentSx: sx ? { ...sx } : {},
                        sx: { minWidth: "fit-content" },
                      });
                    })}
                  </Stack>
                  {list.length === 0 ? (
                    <Stack
                      width="100%"
                      sx={{ ...rowHorizontalCenter, padding: "10px 0" }}
                    >
                      {subHeading({
                        text: "No Changes yet!",
                        sx: { color: globalColors.primary.disableText },
                        weight: 600,
                      })}
                    </Stack>
                  ) : (
                    <Stack spacing="10px" direction="row" width="100%">
                      <Box
                        sx={{
                          minWidth: "30px",
                          ...rowHorizontalCenter,
                          overflow: "hidden",
                          height:
                            list.length === 1 || list.length === 2
                              ? "100px"
                              : list.length === 3
                              ? "250px"
                              : "370px",
                        }}
                      >
                        {stepperSvg({
                          height:
                            list.length === 1
                              ? "200px"
                              : list.length === 2
                              ? "400px"
                              : "350px",
                          length: list.length,
                        })}
                      </Box>
                      <Box
                        sx={{
                          gap: "8px",
                          flex: "8",
                          flexDirection: "column",
                          display: "flex",
                          // maxHeight: "370px",
                          // overflowY: "auto",
                        }}
                        className="scrollBluePrimarythin"
                      >
                        {list.map((li: ObjectValues) => (
                          <Stack
                            key={`row-${li.id}`}
                            spacing="10px"
                            sx={{
                              borderRadius: "8px",
                              backgroundColor: globalColors.primary.pureWhite,
                              padding: "10px",
                            }}
                            direction="row"
                          >
                            {historyArray.map(({ label, sx, content }) => {
                              return (
                                <Box sx={{ ...sx }} key={`row-${label}`}>
                                  {content(li)}
                                </Box>
                              );
                            })}
                          </Stack>
                        ))}
                      </Box>
                    </Stack>
                  )}
                </Stack>
              );
            }}
            loading={dockedCard.loading}
            apiCall={ModuleDetails[dockedCard.title].apiCall}
            data={tableData.actual}
            filterValue={[
              {
                name: "primary_language",
                operator: "eq",
                type: "select",
                value: null,
              },
              {
                name: "name",
                operator: "startsWith",
                type: "string",
                value: "",
              },
              {
                name: "path_order",
                operator: "gte",
                type: "number",
                value: null,
              },
              {
                name: "registered_users",
                operator: "gte",
                type: "number",
                value: null,
              },
              {
                name: "goal_type",
                operator: "eq",
                type: "select",
                value: null,
              },
            ]}
            // ModuleDetails[dockedCard.title].filterValue ?? []}
            columnsConfig={columns}
          />
        </Stack>
      )
    );
  };
  const allTab: TabProp[] =
    dockedCard?.title == "clusters" || dockedCard?.title == "questions"
      ? allStatusInClusterQuestion.map((it) => {
          const data: TabProp = {
            content: tableContent(it),
            id: it.value,
            tab: it.label,
          };
          return data;
        })
      : allStatus.map((it) => {
          const data: TabProp = {
            content: tableContent(it),
            id: it.value,
            tab: it.label,
          };
          return data;
        });
  const bulkQuestionsDialog = (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      maxWidth="lg"
      fullWidth
    >
      <DialogContent sx={{ maxHeight: "90vh", overflowY: "auto" }}>
        <BulkQuestionsForm
          dialogRef={dialogRef}
          onSubmit={handleBulkQuestionsSubmit}
          dockedCard={selectedDockedCard}
          onClose={(): void => {
            throw new Error("Function not implemented.");
          }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "8px",
            marginTop: "24px",
          }}
        >
          <Button onClick={() => setIsOpen(false)} variant="outlined">
            Cancel
          </Button>
          <Button
            onClick={() => {
              dialogRef.current?.onSubmitForm?.();
              setIsOpen(false);
            }}
            variant="contained"
          >
            Submit
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
  return (
    <Stack
      height="calc(100% - 15px)"
      width="calc(100% - 16px)"
      direction={"row"}
      m="0 8px 15px"
      spacing={"8px"}
      // sx={{overflowX:"auto"}}
    >
      {copyDialog.open && (
        <CopyComponent
          open={copyDialog.open}
          onClose={() => {
            setCopyDialog({});
          }}
          row={copyDialog.row}
          formProps={formProps}
        />
      )}
      {dialogs.open ? (
        dialogs.title === "Ready" ||
        dialogs.title === "Approve" ||
        dialogs.title === "Publish" ||
        dialogs.title === "Publish" ? (
          <ConfirmationDialog
            title="Confirm"
            content={`Are you sure you want to move exam ${dialogs.selected.name} to ${dialogs.title} state ?`}
            open={dialogs.open}
            onClose={() => {
              setDialog({});
            }}
            onSubmit={() => {
              dialogs.actions(dialogs)[0].onClick();
              setTimeout(() => dispatch(fetchAllExamsTabData()), 1000);
            }}
          />
        ) : (
          <CustomDialog
            title={contents.action(dialogs.title) ?? ""}
            open={dialogs.open}
            content={
              <Stack spacing="10px" width="400px">
                <Box sx={{ color: "#A09F9F" }}>
                  {subHeading({
                    text: contents.actionText(
                      `exam ${dialogs.selected.name}`,
                      dialogs.title
                    ),
                  })}
                </Box>
                <Divider />
                {subHeading({ text: contents.feedback, weight: 600 })}
                <CustomTextField
                  placeholder={contents.feedback}
                  id={contents.feedback}
                  variant="outlined"
                  error={false}
                  value={dialogs[contents.feedback] ?? ""}
                  maxRows={6}
                  minRows={6}
                  disabled={dialogs.disabled ?? false}
                  multiline={true}
                  onChange={(e) => {
                    setDialog((p) => {
                      return { ...p, [contents.feedback]: e.target.value };
                    });
                  }}
                  onKeyPress={async (ev: any) => {
                    if (ev.key === "Enter") {
                      ev.preventDefault();
                      if (
                        dialogs.actions(dialogs)[0] &&
                        dialogs[contents.feedback]
                      ) {
                        dialogs.actions(dialogs)[0].onClick();
                        // setTimeout(() =>
                        //   dispatch(fetchAllExamsTabData())
                        // , 1000);
                      }
                    }
                  }}
                  sx={{
                    width: "100%",
                    "& .MuiInputBase-root": { padding: "0" },
                    "& .MuiInputBase-input": { padding: "10px 12px" },
                  }}
                  inputProps={{
                    "data-testid": `${contents.feedback}-input`,
                  }}
                />
              </Stack>
            }
            onClose={() => {
              setDialog({});
            }}
            actions={dialogs.actions(dialogs) ?? []}
          />
        )
      ) : (
        ""
      )}
      {hasCreateOrEdit(exams_structure) === true ? "" : <AllExpanded />}

      {(!is_expanded || hasCreateOrEdit(exams_structure) === true) && (
        <Stack
          height={"100%"}
          width={
            dockedCard.parentDetails &&
            hasCreateOrEdit(exams_structure) !== true
              ? "calc(100% - 158px)"
              : "100%"
          }
          spacing="15px"
          position={"relative"}
        >
          {dockedCard.create ||
          dockedCard.edit ||
          hasCreateOrEdit(exams_structure) === true
            ? ""
            : exams_structure.child && dockedCard.dockedTable && ExpandButton()}
          <CustomBreadcrumbs />

          <Stack
            flex="8"
            sx={{
              borderRadius: "12px",
              background: globalColors.primary.pureWhite,
              p: "10px 10px 0",
              width: "calc(100% - 20px)",
              overflow: "auto",
            }}
          >
            {dockedCard.create ||
            dockedCard.edit ||
            hasCreateOrEdit(exams_structure) === true ? (
              <Stack flex="8" pb="10px" overflow="hidden">
                {dockedCard.title === allModules[7] ? (
                  <QuestionFormComponentExams
                    {...formProps}
                    getApi={(id: string) => allApis[dockedCard.title].item(id)}
                  />
                ) : (
                  <FormComponent
                    {...formProps}
                    customContent={
                      allStepForm.length > 0
                        ? ({ onSubmit, res, submittedData }: any) => {
                            return (
                              <QuestionForm
                                dockedCard={dockedCard}
                                res={res}
                                editData={
                                  dockedCard.edit
                                    ? dockedCard.selected_row ?? {}
                                    : undefined
                                }
                                formData={submittedData}
                                onSubmit={onSubmit}
                                onClear={formProps.onClear}
                                item={{
                                  list: tableData.actual,
                                  selected_row: tableData.actual[0] ?? null,
                                  title: dockedCard.title,
                                  primary: "name",
                                }}
                              />
                            );
                          }
                        : undefined
                    }
                  />
                )}
              </Stack>
            ) : (
              <Stack
                flex="8"
                sx={{
                  p: "0 10px",
                  width: "calc(100% - 20px)",
                  overflow: "auto",
                }}
              >
                {headerContent({
                  text: `List of ${
                    dockedCard.title.charAt(0).toUpperCase() +
                    dockedCard.title.slice(1)
                  }`,
                  sx: {
                    p: "10px 0",
                  },
                  weight: "500",
                  icons: [],
                })}
                <Stack
                  direction={"row"}
                  sx={{
                    width: "100%",
                    p: "0 0 15px 0",
                    borderBottom: `1px solid ${globalColors.primary.borderColor}`,
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box sx={{ width: "250px" }}>
                    {" "}
                    <CustomSearch
                      fieldName={
                        columns.map((col) => col.field) ?? [
                          "name",
                          "primary_language",
                          "description",
                        ]
                      }
                      copyData={tableData.copy ?? []}
                      filteredData={(array: ObjectValues[]) => {
                        setTableData((prev) => {
                          return { ...prev, actual: array };
                        });
                      }}
                      onClear={() => {
                        setTableData((prev) => {
                          return { ...prev, actual: prev.copy };
                        });
                      }}
                    />
                  </Box>
                  <Box sx={{ ...rowCenter, gap: "10px" }}>
                    {ModuleDetails[dockedCard.title]?.arrange_path && (
                      <PathOrderManagment
                        list={tableData.all_status}
                        title={dockedCard.title}
                        onClear={() => {
                          dispatch(
                            setExamStructure(
                              formatStructureData(
                                exams_structure,
                                {
                                  create: false,
                                  edit: false,
                                  selected_row: null,
                                  fetchAgain: true,
                                },
                                dockedCard.title
                              )
                            )
                          );
                          dispatch(
                            showSnack({
                              open: true,
                              severity: "success",
                              message: `Path order of all the ${dockedCard.title} has been successfully updated.`,
                            })
                          );
                        }}
                      />
                    )}
                    {headerContent({
                      text: "",
                      icons: noAction()
                        ? []
                        : checkPermisions(
                            userSlice?.user_permissions ?? [],
                            "add",
                            getPermissionName(),
                            dockedCard.title == "questions"
                              ? "content"
                              : "syllabus"
                          )
                        ? [
                            {
                              content: `Add ${
                                dockedCard.title === "clusters" ? "" : "New"
                              } ${dockedCard.title}`,
                              icon: Addicon,
                              variant: "contained",
                              onClick: () => {
                                dispatch(
                                  setExamStructure(
                                    formatStructureData(
                                      exams_structure,
                                      { create: true },
                                      dockedCard.title
                                    )
                                  )
                                );
                              },
                            },
                          ]
                        : [],
                    })}
                  </Box>
                </Stack>

                <Box
                  sx={{
                    maxWidth: "100%",
                    flex: "8",
                    overflow: "auto",
                  }}
                >
                  {ModuleDetails[dockedCard.title]?.isTab ? (
                    <CustomTab
                      selected={allTab.find(
                        (it) =>
                          it.tab ===
                          (dockedCard?.title == "clusters" ||
                          dockedCard.title === "questions"
                            ? dockedCard.selectedTabInClusterQuestion?.label
                            : dockedCard.selectedTab?.label)
                      )}
                      onChange={(id: number) => {
                        dispatch(
                          setExamStructure(
                            formatStructureData(
                              exams_structure,
                              {
                                selectedTab: ["exams", "goals"].includes(
                                  dockedCard?.title
                                )
                                  ? allStatus.find((it) => it.value === id)
                                  : allStatus[0],
                                selectedTabInClusterQuestion: [
                                  "clusters",
                                  "questions",
                                ].includes(dockedCard?.title)
                                  ? allStatusInClusterQuestion.find(
                                      (it) => it.value === id
                                    )
                                  : allStatusInClusterQuestion[0],
                                fetchAgain: ModuleDetails[dockedCard.title]
                                  .arrange_path
                                  ? false
                                  : true,
                                list: ModuleDetails[dockedCard.title]
                                  .arrange_path
                                  ? dockedCard.list
                                  : [],
                                child: null,
                                loading: ModuleDetails[dockedCard.title]
                                  .arrange_path
                                  ? false
                                  : true,
                              },
                              dockedCard.title
                            )
                          )
                        );
                      }}
                      tabs={allTab}
                      badgeCount={
                        dockedCard.title == "exams"
                          ? all_exam_status_data
                          : dockedCard.title == "goals"
                          ? all_goal_status_data
                          : dockedCard.title == "clusters"
                          ? all_clusters_status_data
                          : all_questions_status_data
                      }
                    />
                  ) : (
                    tableContent()
                  )}
                </Box>
              </Stack>
            )}
          </Stack>
        </Stack>
      )}
      {dialogs.open && (
        <Dialog
          open={dialogs.open}
          onClose={() => setDialog({})}
          maxWidth="md"
          fullWidth
        >
          {dialogs.content}
          <DialogActions>
            {dialogs.actions().map((action: any, index: number) => (
              <Button
                key={index}
                onClick={action.onClick}
                variant={action.variant}
                sx={action.sx}
              >
                {action.content}
              </Button>
            ))}
          </DialogActions>
        </Dialog>
      )}
      {isBulkQuestionDialogOpen.open && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            bgcolor: "background.paper",
            zIndex: 1300,
          }}
        >
          <BulkQuestionsForm
            subtopic={isBulkQuestionDialogOpen.data}
            dialogRef={dialogRef}
            dockedCard={selectedDockedCard}
            onSubmit={handleBulkQuestionsSubmit}
            onClose={() => setIsBulkQuestionDialogOpen({ open: false })}
          />
        </Box>
      )}
    </Stack>
  );
}

export default Exams;
