import { Button, Stack, Typography } from "@mui/material";
import { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { QuestionsContainer } from "../../Components/ProofReadingComponents/QuestionsContainer";
// import { useAppDispatch, useAppSelecter } from "../../hooks/storeHooks";
//import globalColors from "../../globalColors";
// import { logoutUser } from "../../store/slices/userDataSlice/thunks/logoutUser/logoutUser";

export const ProofReading: FC = () => {
  // const dispatch = useAppDispatch();
  // const { status: userStatus } = useAppSelecter((state) => state.userDataSlice);
  // const { userEmail, userId } = useAppSelecter(
  //   (state) => state.userDataSlice.userData
  // );
  
  // if (userStatus !== "VERIFIED_OTP" && !userEmail && !userId)
  //   return <Navigate to={"/login-page"} replace />;

  return (
    <Stack width={"100%"} height={"90vh"} overflow={"hidden"}>
      {/* app bar */}
      {/* <Stack
        height={"50px"}
        direction={"row"}
        alignItems={"center"}
        justifyContent={"flex-end"}
        p={"8px 24px"}
        borderBottom={"1px solid grey"}
        bgcolor={globalColors.lavender[300]}
        color={"white"}
        spacing={"40px"}
      >
        <Typography variant="body1">{userEmail}</Typography>

        <Button
          variant="contained"
          onClick={(e) => {
            e.stopPropagation();
            dispatch(logoutUser());
          }}
        >
          logout
        </Button>
      </Stack> */}
      {/* content */}
      <Stack
        direction={"row"}
        width={"100%"}
        height={"calc(90%-50px)"}
        overflow={"hidden"}
        // border={"2px solid red"}
      >
        <Stack
          minWidth={"400px"}
          maxWidth={"400px"}
          height={"100%"}
          sx={{ overflowY: "scroll", overflowX: "hidden" }}
        >
          <QuestionsContainer />
        </Stack>
        {/* question preview + raw data */}
        <Outlet />
      </Stack>
    </Stack>
  );
};


