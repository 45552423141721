import { Box, Stack, Typography } from "@mui/material";
import React, { useRef } from "react";
import QuestionOption from "./QuestionOption";
// import Chip from "../chip/Chip";
import globalColors from "../../globalColors";
// import getSectionColor from "../utility/getSectionColor";
// import math from "../math";
import { useAppSelecter } from "../../hooks/storeHooks";
// import math from "../math";

interface SingleQuestionProps {
  language: "hindi" | "english";
}

const SingleQuestion: React.FC<SingleQuestionProps> = ({ language }) => {
  const questionRef = useRef(null);
  const { activeQuestionData } = useAppSelecter(
    (state) => state.questionsDataArraySlice
  );

  // useEffect(() => {
  //   math(questionRef.current);
  // }, [questionData]);

  if (!activeQuestionData) return null;

  return (
    <Box maxHeight={"80%"} bgcolor={"transparent"}>
      {/* question tag  */}
      {/* {question_tags && question_tags.length > 0 && ( */}
      <Stack
        // py={"18px"}
        // px={"12px"}
        bgcolor={globalColors.primary.pureWhite}
        borderRadius={"10px"}
        // border={"1px solid red"}
        direction={"row"}
        alignItems={"center"}
        spacing={"12px"}
        mb={"8px"}
      >
        {/* <Stack alignSelf={"flex-start"}>
            {sectionId &&
              question_tags.map(({ tag__tag_name }) => {
                return (
                  <Stack mb={"8px"}>
                    <Chip
                      label={tag__tag_name}
                      // bgcolor={globalColors.green[100]}
                      bgcolor={getSectionColor(sectionId).secondary}
                      color={getSectionColor(sectionId).primary}
                    />
                  </Stack>
                );
              })}
          </Stack> */}
      </Stack>

      <Typography
        component={"div"}
        fontStyle={"normal"}
        fontWeight={500}
        fontSize={"14px"}
        lineHeight={"21px"}
      >
        <div
          ref={questionRef}
          dangerouslySetInnerHTML={{
            __html:
              language === "english"
                ? activeQuestionData.question
                : activeQuestionData.editedQuestionHindi
                ? activeQuestionData.editedQuestionHindi
                : activeQuestionData.questionHindi,
          }}
        />
      </Typography>
      {/* options */}
      <Stack spacing={"12px"} my={"24px"}>
        {/* option 1 */}
        <QuestionOption
          optionIndex={0}
          optionText={
            language === "english"
              ? activeQuestionData.option1
              : activeQuestionData.editedOption1Hindi
              ? activeQuestionData.editedOption1Hindi
              : activeQuestionData.option1Hindi
          }
        />
        {/* option 2 */}
        <QuestionOption
          optionIndex={1}
          optionText={
            language === "english"
              ? activeQuestionData.option2
              : activeQuestionData.editedOption2Hindi
              ? activeQuestionData.editedOption2Hindi
              : activeQuestionData.option2Hindi
          }
        />
        {/* option 3 */}
        <QuestionOption
          optionIndex={2}
          optionText={
            language === "english"
              ? activeQuestionData.option3
              : activeQuestionData.editedOption3Hindi
              ? activeQuestionData.editedOption3Hindi
              : activeQuestionData.option3Hindi
          }
        />
        {/* option 4 */}
        <QuestionOption
          optionIndex={3}
          optionText={
            language === "english"
              ? activeQuestionData.option4
              : activeQuestionData.editedOption4Hindi
              ? activeQuestionData.editedOption4Hindi
              : activeQuestionData.option4Hindi
          }
        />
      </Stack>
    </Box>
  );
};

export default SingleQuestion;


