import React, { useState, RefObject } from 'react';
import { 
  Box, 
  Stepper, 
  Step, 
  StepLabel, 
  StepContent, 
  Button, 
  Typography, 
  FormControlLabel,
  Checkbox,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Paper,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Divider,
} from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DoneIcon from '@mui/icons-material/Done';

// Define prop types for the component
interface BulkQuestionsFormStepperProps {
  hindiTranslation: boolean;
  setHindiTranslation: (value: boolean) => void;
  clusterType: string | null;
  handleClusterTypeChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  fileInputRef: RefObject<HTMLInputElement>;
  currentFileName: String | null;
  isUploading: boolean;
  uploadProgress: number;
  hasFileUploaded: boolean;
  handleSubmit: () => void;
  isLoading: boolean;
  countTotalQuestions: () => number;
  isSingleQuestionPerCluster: () => boolean;
  handleSeparateQuestions: () => void;
}

// Define step interface
interface Step {
  label: string;
  description: string;
  completed: boolean;
  content: React.ReactNode;
  getSummary: () => string;
}

const BulkQuestionsFormStepper: React.FC<BulkQuestionsFormStepperProps> = ({ 
  hindiTranslation, 
  setHindiTranslation, 
  clusterType, 
  handleClusterTypeChange, 
  fileInputRef, 
  currentFileName, 
  isUploading, 
  uploadProgress, 
  hasFileUploaded,
  handleSubmit,
  isLoading,
  countTotalQuestions,
  isSingleQuestionPerCluster,
  handleSeparateQuestions
}) => {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [completedSteps, setCompletedSteps] = useState<number[]>([]);
  const [dialogState, setDialogState] = useState<{
    open: boolean;
    type: "info" | "confirm";
    title: string;
    message: string;
  }>({
    open: false,
    type: "confirm",
    title: "",
    message: "",
  });

  const handleNext = (): void => {
    // Add current step to completed steps if not already there
    if (!completedSteps.includes(activeStep)) {
      setCompletedSteps([...completedSteps, activeStep]);
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = (): void => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = (): void => {
    setActiveStep(0);
    setCompletedSteps([]);
  };

  const handleFileUploadClick = (): void => {
    fileInputRef.current?.click();
  };

  const handleOpen = () => {
    if (isSingleQuestionPerCluster()) {
      setDialogState({
        open: true,
        type: "info",
        title: "Already Separated",
        message:
          "Questions are already organized with one question per cluster.",
      });
    } else {
      setDialogState({
        open: true,
        type: "confirm",
        title: "Confirmation",
        message: `This will replace existing clusters and create a total of ${countTotalQuestions()} clusters (one cluster per question). Do you want to continue?`,
      });
    }
  };
  
  const handleClose = () => {
    setDialogState((prev) => ({ ...prev, open: false }));
  };

  const handleConfirm = () => {
    handleSeparateQuestions();
    handleClose();
  };

  // Define steps and their validation status
  const steps: Step[] = [
    {
      label: 'Language Option',
      description: 'Choose whether to enable Hindi translation',
      completed: true, // Always can proceed from this step
      content: (
        <FormControlLabel
          control={
            <Checkbox
              checked={hindiTranslation}
              onChange={() => setHindiTranslation(!hindiTranslation)}
            />
          }
          label="Enable Hindi Translation"
        />
      ),
      getSummary: () => `Hindi translation: ${hindiTranslation ? 'Enabled' : 'Disabled'}`
    },
    {
      label: 'Select Cluster Type',
      description: 'Choose the category for this question set',
      completed: !!clusterType,
      content: (
        <FormControl component="fieldset">
          <FormLabel component="legend">Select Cluster Type:</FormLabel>
          <RadioGroup
            value={clusterType || ''}
            onChange={(e) => handleClusterTypeChange(e)}
            sx={{ display: 'flex', flexDirection: 'column', mt: 1 }}
          >
            <FormControlLabel
              value="cognitive"
              control={<Radio />}
              label="Cognitive"
            />
            <FormControlLabel
              value="informative"
              control={<Radio />}
              label="Informative"
            />
            <FormControlLabel
              value="vocab"
              control={<Radio />}
              label="Vocabulary"
            />
          </RadioGroup>
        </FormControl>
      ),
      getSummary: () => `Cluster type: ${clusterType ? clusterType.charAt(0).toUpperCase() + clusterType.slice(1) : 'Not selected'}`
    },
    {
      label: 'Upload Document',
      description: 'Import questions from Word document',
      completed: !!hasFileUploaded,
      content: (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>
          <Button
            variant="contained"
            component="span"
            startIcon={<CloudUploadIcon />}
            onClick={handleFileUploadClick}
            sx={{ mt: 1 }}
          >
            {currentFileName ? "Replace File" : "Upload File"}
          </Button>
          
          {currentFileName && (
            <Typography variant="body2" sx={{ mt: 1, fontStyle: 'italic' }}>
              Current file: {currentFileName}
            </Typography>
          )}
          
          {isUploading && (
            <Box sx={{ width: '100%', mt: 1 }}>
              <LinearProgress 
                variant="determinate" 
                value={uploadProgress} 
                sx={{
                  height: 8,
                  borderRadius: 4,
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  '& .MuiLinearProgress-bar': {
                    borderRadius: 4,
                  },
                }}
              />
              <Typography variant="caption" sx={{ display: 'block', mt: 0.5 }}>
                {uploadProgress === 100 ? "Processing file..." : `Uploading... ${Math.round(uploadProgress)}%`}
              </Typography>
            </Box>
          )}
          
          {hasFileUploaded && !isUploading && (
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <CheckCircleOutlineIcon color="success" sx={{ mr: 1 }} />
              <Typography>File uploaded successfully</Typography>
            </Box>
          )}
        </Box>
      ),
      getSummary: () => `File uploaded: ${currentFileName || 'None'}`
    },
    {
      label: 'Configure Clusters',
      description: 'Organize questions into clusters',
      completed: true, // Always can proceed from this step
      content: (
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: 2 }}>
          <Typography variant="body2" sx={{ mb: 1 }}>
            You can organize your questions into individual clusters or keep them grouped.
          </Typography>
          
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleOpen}
            sx={{
              py: 0.75,
              borderRadius: 1.5,
              mb: 0.5,
              textAlign: "center",
              fontSize: 14,
              fontWeight: 500,
              textTransform: "none",
            }}
          >
            Put Every Question in Separate Cluster
          </Button>
          
          <Typography variant="caption" sx={{ fontStyle: 'italic' }}>
            This will create {countTotalQuestions()} separate clusters with one question each.
          </Typography>
          
          <Dialog open={dialogState.open} onClose={handleClose}>
            <DialogTitle>{dialogState.title}</DialogTitle>
            <DialogContent>
              <DialogContentText>{dialogState.message}</DialogContentText>
            </DialogContent>
            <DialogActions>
              {dialogState.type === "confirm" ? (
                <>
                  <Button
                    onClick={handleClose}
                    color="primary"
                    variant="outlined"
                  >
                    No
                  </Button>
                  <Button
                    onClick={handleConfirm}
                    color="primary"
                    autoFocus
                    variant="contained"
                  >
                    Yes
                  </Button>
                </>
              ) : (
                <Button
                  onClick={handleClose}
                  color="primary"
                  variant="contained"
                >
                  OK
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </Box>
      ),
      getSummary: () => `Question organization: ${isSingleQuestionPerCluster() ? 'Individual clusters' : 'Grouped clusters'} (${countTotalQuestions()} questions total)`
    },
    {
      label: 'Submit Questions',
      description: 'Finalize and save all questions',
      completed: false,
      content: (
        <Box sx={{ mt: 2 }}>
          <Typography>
            You're ready to submit all questions. Please verify that:
          </Typography>
          <ul style={{ paddingLeft: '20px', marginTop: '8px' }}>
            <li>Hindi translation is {hindiTranslation ? 'enabled' : 'disabled'}</li>
            <li>Cluster type is set to: {clusterType || 'Not selected'}</li>
            <li>File uploaded: {currentFileName || 'None'}</li>
            <li>Questions arranged in {isSingleQuestionPerCluster() ? 'individual' : 'grouped'} clusters</li>
          </ul>
          <Button 
            variant="contained" 
            onClick={handleSubmit}
            disabled={isLoading || !clusterType || !hasFileUploaded}
            sx={{ mt: 2 }}
          >
            {isLoading ? "Saving..." : "Save All Questions"}
          </Button>
        </Box>
      ),
      getSummary: () => `Questions ${isLoading ? 'being saved...' : 'ready to be submitted'}`
    },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, width: '100%', maxWidth: 600 }}>
      {/* Main stepper component */}
      <Paper elevation={3} sx={{ p: 3, borderRadius: 2 }}>
        <Typography variant="h6" gutterBottom>
          Question Import Wizard
        </Typography>
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                optional={
                  <Typography variant="caption">{step.description}</Typography>
                }
                error={activeStep > index && !step.completed}
              >
                {step.label}
                
                {/* Show step summary if it's a completed step but not the active step */}
                {completedSteps.includes(index) && activeStep !== index && (
                  <Box 
                    sx={{ 
                      display: 'flex', 
                      alignItems: 'center', 
                      mt: 1, 
                      p: 1, 
                      borderRadius: 1,
                      backgroundColor: '#e3f2fd',
                    }}
                  >
                    <DoneIcon color="success" fontSize="small" sx={{ mr: 1 }} />
                    <Typography variant="body2">{step.getSummary()}</Typography>
                  </Box>
                )}
              </StepLabel>
              
              <StepContent>
                <Box sx={{ mb: 2 }}>
                  {step.content}
                  
                  {/* Don't show navigation buttons on the last step as "Save All Questions" button is the final action */}
                  {index < steps.length - 1 && (
                    <Box sx={{ mb: 2, mt: 2 }}>
                      <div>
                        <Button
                          variant="contained"
                          onClick={handleNext}
                          sx={{ mt: 1, mr: 1 }}
                          disabled={!step.completed && index !== 0}
                        >
                          Continue
                        </Button>
                        <Button
                          disabled={index === 0}
                          onClick={handleBack}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          Back
                        </Button>
                      </div>
                    </Box>
                  )}
                  
                  {/* For the last step, just show Back button, not Continue/Finish */}
                  {index === steps.length - 1 && (
                    <Box sx={{ mb: 2, mt: 2 }}>
                      <div>
                        <Button
                          onClick={handleBack}
                          sx={{ mt: 1, mr: 1 }}
                        >
                          Back
                        </Button>
                      </div>
                    </Box>
                  )}
                </Box>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{ p: 3 }}>
            <Typography>All steps completed</Typography>
            <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
              Reset
            </Button>
          </Paper>
        )}
      </Paper>
    </Box>
  );
};

export default BulkQuestionsFormStepper;