export const proofreading = (props: { height?: string }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props?.height ?? "16"}
    height={props?.height ?? "16"}
    viewBox="0 0 16 16"
    fill="none"
    stroke="currentColor"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round"
  >
    <path d="M2 2h12v12H2z" />
    <path d="M4 4h8M4 7h5M4 10h3" />
    <path d="M10 10l2 2" />
  </svg>
);
