import { FC, useRef } from "react";
import { Stack, Typography } from "@mui/material";
import globalColors from "../../globalColors";
// import math from "../math";

export interface AnswerExplanationProps {
  answerExplanationText: string;
}

const AnswerExplanation: FC<AnswerExplanationProps> = ({
  answerExplanationText,
}) => {
  const answerExplanationRef = useRef(null);

  // useEffect(() => {
  //   math(answerExplanationRef.current);
  // }, [answerExplanationText]);

  return (
    <Stack spacing={"8px"}>
      <Stack px={"8px"}>
        <Typography color={globalColors.primary.black}>Solution</Typography>
      </Stack>
      <Stack
        borderRadius={"5px"}
        bgcolor={globalColors.primary.lightGrey}
        border={"none"}
        p={"8px"}
      >
        <Typography>
          <div
            ref={answerExplanationRef}
            dangerouslySetInnerHTML={{ __html: answerExplanationText }}
          />
        </Typography>
      </Stack>
    </Stack>
  );
};

export default AnswerExplanation;
