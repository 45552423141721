import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  TextField,
  Button,
  Checkbox,
  Typography,
  Breadcrumbs,
  Link,
  Paper,
  Menu,
  MenuItem,
  Divider,
  FormControlLabel,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import MoreVert from "@mui/icons-material/MoreVert";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { SelectChangeEvent } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import {
  createInternalUser,
  updateInternalUser,
  fetchInternalUsers,
  InternalUser,
} from "../../store/slices/InternalUsers/InternalUserSlice";
import { AppDispatch, RootState } from "../../store/store";

const InternalUsers = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = React.useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const { users, status, error } = useSelector(
    (state: RootState) => state.internalUserSlice
  );

  const [showForm, setShowForm] = React.useState(false);
  const [showPermissionForm, setShowPermissionForm] = React.useState(false);
  const [currentView, setCurrentView] = React.useState<'list' | 'add' | 'edit' | 'permissions'>('list');
  
  const [formData, setFormData] = React.useState({
    id: null,
    name: "",
    email: "",
    phone: "",
    is_active: true,
  });
  
  const [permissionData, setPermissionData] = React.useState({
    userId: null,
    userName: "",
    userEmail: "",
    module: "",
    actions: {
      status: false,
      edit: false,
      delete: false,
    },
  });
  
  const [anchorEls, setAnchorEls] = React.useState<{
    [key: string | number]: HTMLElement | null;
  }>({});
  const [editingUserId, setEditingUserId] = React.useState<number | null>(null);
  const [formError, setFormError] = React.useState<string>("");

  React.useEffect(() => {
    dispatch(fetchInternalUsers());
  }, [dispatch]);

  const handleClick = (
    event: React.MouseEvent<HTMLElement>,
    userId: string | number
  ) => {
    setAnchorEls((prev) => ({
      ...prev,
      [userId]: event.currentTarget,
    }));
  };

  const handleAddNewClick = () => {
    setEditingUserId(null);
    setFormData({
      id: null,
      name: "",
      email: "",
      phone: "",
      is_active: true,
    });
    setCurrentView('add');
    setShowForm(true);
  };

  const handleClose = (userId: string | number) => {
    setAnchorEls((prev) => {
      const newAnchorEls = { ...prev };
      delete newAnchorEls[userId];
      return newAnchorEls;
    });
  };

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({ ...formData, is_active: e.target.checked });
  };

  const handlePermissionChange = (e: SelectChangeEvent<string>) => {
    setPermissionData({
      ...permissionData,
      module: e.target.value,
    });
  };

  const handleActionCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    action: string
  ) => {
    setPermissionData({
      ...permissionData,
      actions: {
        ...permissionData.actions,
        [action]: e.target.checked,
      },
    });
  };

  const handleFormSubmit = async () => {
    setFormError("");
    setIsLoading(true);

    if (!formData.name || !formData.email) {
      setFormError("Please fill out the required fields.");
      setIsLoading(false);
      return;
    }

    if (formData.phone && !/^\d{10}$/.test(formData.phone)) {
      setFormError("Phone number must be exactly 10 digits.");
      setIsLoading(false);
      return;
    }

    const payload = {
      id: editingUserId?.toString() || "",
      userData: {
        email: formData.email,
        user_data: { full_name: formData.name },
        phone: formData.phone,
        is_active: formData.is_active,
      },
    };

    try {
      if (editingUserId !== null) {
        await dispatch(updateInternalUser(payload)).unwrap();
      } else {
        await dispatch(createInternalUser(payload.userData)).unwrap();
      }
      resetForm();
      setCurrentView('list');
      dispatch(fetchInternalUsers());
    } catch (err) {
      console.error("Error during submission:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddPermissions = (user: any) => {
    setPermissionData({
      userId: user.id,
      userName: user.user_data?.full_name || "",
      userEmail: user.email || "",
      module: "",
      actions: {
        status: false,
        edit: false,
        delete: false,
      },
    });
    setCurrentView('permissions');
    handleClose(user.id);
  };

  const handlePermissionFormSubmit = () => {
    // Implement your permission submission logic here
    console.log("Permissions submitted:", permissionData);
    setCurrentView('list');
  };

  const handleEditClick = (user: any) => {
    setEditingUserId(user.id);
    setFormData({
      id: user.id,
      name: user.user_data.full_name,
      email: user.email,
      phone: user.phone,
      is_active: user.is_active,
    });
    setCurrentView('edit');
  };

  const handleCancel = () => {
    resetForm();
    setCurrentView('list');
  };

  const resetForm = () => {
    setFormData({ id: null, name: "", email: "", phone: "", is_active: true });
    setPermissionData({
      userId: null,
      userName: "",
      userEmail: "",
      module: "",
      actions: { status: false, edit: false, delete: false },
    });
    setShowForm(false);
    setEditingUserId(null);
    setFormError("");
  };

  const renderBreadcrumbs = () => {
    return (
      <Breadcrumbs aria-label="breadcrumb" style={{ marginBottom: "16px" }}>
        <Link
          underline="hover"
          color="inherit"
          onClick={() => navigate("/usersPermissions")}
          style={{ cursor: "pointer" }}
        >
          Users and Permissions
        </Link>
        <Link
          underline="hover"
          color="inherit"
          onClick={() => setCurrentView('list')}
          style={{ cursor: "pointer" }}
        >
          Internal Users
        </Link>
        {currentView !== 'list' && (
          <Typography color="text.primary">
            {currentView === 'add' ? 'Add New User' : 
             currentView === 'edit' ? 'Edit User' : 
             'Add Permissions'}
          </Typography>
        )}
      </Breadcrumbs>
    );
  };

  const renderPermissionForm = () => {
    return (
      <div>
        <Typography variant="h5" gutterBottom>
          Add Permissions
        </Typography>
        <Divider />
        
<div style={{ marginTop: "20px" }}>
          <FormControl fullWidth style={{ marginBottom: "24px" }}>
            <InputLabel>Module</InputLabel>
            <Select
              value={permissionData.module}
              onChange={handlePermissionChange}
              name="module"
              label="Module"
            >
              <MenuItem value="Home">Home</MenuItem>
              <MenuItem value="Exams & Topical Mastery">Exams & Topical Mastery</MenuItem>
              <MenuItem value="Mock Tests">Mock Tests</MenuItem>
              <MenuItem value="Content Feedback">Content Feedback</MenuItem>
              <MenuItem value="Order Management">Order Management</MenuItem>
              <MenuItem value="Users & Permissions">Users & Permissions</MenuItem>
              <MenuItem value="Content Proof Reading">Content Proof Reading</MenuItem>
            </Select>
          </FormControl>

          <Typography variant="subtitle1" gutterBottom style={{ marginTop: "16px" }}>
            Permissions
          </Typography>
          
          <div style={{ display: "flex", flexDirection: "column", gap: "12px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={permissionData.actions.status}
                  onChange={(e) => handleActionCheckboxChange(e, "status")}
                  name="status"
                />
              }
              label="Change Status"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={permissionData.actions.edit}
                  onChange={(e) => handleActionCheckboxChange(e, "edit")}
                  name="edit"
                />
              }
              label="Edit"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={permissionData.actions.delete}
                  onChange={(e) => handleActionCheckboxChange(e, "delete")}
                  name="delete"
                />
              }
              label="Delete"
            />
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "220px",
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            onClick={handleCancel}
            style={{ flex: "0 0 10%", textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handlePermissionFormSubmit}
            style={{ flex: "0 0 10%", textTransform: "none" }}
          >
            Save Permissions
          </Button>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    switch (currentView) {
      case 'add':
      case 'edit':
        return (
          <div>
            <Typography variant="h5" gutterBottom>
              {currentView === 'add' ? "Add New User" : "Edit User"}
            </Typography>
            <Divider />
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "200px",
                marginTop: "20px",
              }}
            >
              <TextField
                label="Full Name"
                variant="outlined"
                style={{ flex: "0 0 28%" }}
                name="name"
                value={formData.name}
                onChange={handleFormChange}
                required
              />
              <TextField
                label="Email"
                variant="outlined"
                style={{ flex: "0 0 28%" }}
                name="email"
                value={formData.email}
                onChange={handleFormChange}
                required
              />
              <TextField
                label="Phone Number"
                variant="outlined"
                style={{ flex: "0 0 28%" }}
                name="phone"
                value={formData.phone}
                onChange={handleFormChange}
              />
              <div
                style={{
                  flex: "0 0 28%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  checked={formData.is_active}
                  onChange={handleCheckboxChange}
                  inputProps={{ "aria-label": "Active Checkbox" }}
                />
                <Typography>Active</Typography>
              </div>
            </div>

            {formError && (
              <Typography color="error" variant="body2" style={{ marginTop: "8px" }}>
                {formError}
              </Typography>
            )}

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "220px",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={handleCancel}
                style={{ flex: "0 0 10%", textTransform: "none" }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleFormSubmit}
                style={{ flex: "0 0 10%", textTransform: "none" }}
                disabled={!formData.name || !formData.email || isLoading}
              >
                Save User
              </Button>
            </div>
          </div>
        );
      case 'permissions':
        return renderPermissionForm();
      default:
        return (
          <div>
            <TextField
              variant="outlined"
              placeholder="Search Internal Users"
              style={{ width: "30%", marginBottom: "16px" }}
              margin="normal"
            />
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "16px", float: "right" }}
              startIcon={<AddIcon />}
              onClick={handleAddNewClick}
            >
              Add New
            </Button>
            <TableContainer
              component={Paper}
              style={{ maxHeight: "550px", overflowY: "auto" }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Phone</TableCell>
                    <TableCell>is_active</TableCell>
                    <TableCell align="right">Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users && users.length > 0 ? (
                    users.map((user: any) => (
                      <TableRow key={user.id}>
                        <TableCell>
                          {user.user_data?.full_name || "N/A"}
                        </TableCell>
                        <TableCell>{user.email || "N/A"}</TableCell>
                        <TableCell>{user.phone || "N/A"}</TableCell>
                        <TableCell>
                          {user.is_active ? (
                            <Button
                              variant="contained"
                              style={{
                                backgroundColor: "#bcf7bc",
                                color: "#008000",
                                textTransform: "none",
                              }}
                              >
                                Active
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                style={{
                                  backgroundColor: "#fcd4d8",
                                  color: "#e00215",
                                  textTransform: "none",
                                }}
                              >
                                Inactive
                              </Button>
                            )}
                          </TableCell>
                          <TableCell align="right">
                            <IconButton
                              color="primary"
                              id={`fade-button-${user.id ?? -1}`}
                              aria-controls={
                                anchorEls[user.id ?? -1]
                                  ? `fade-menu-${user.id ?? -1}`
                                  : undefined
                              }
                              aria-haspopup="true"
                              aria-expanded={anchorEls[user.id ?? -1] ? "true" : undefined}
                              onClick={(e) => handleClick(e, user.id ?? -1)}
                            >
                              <MoreVert />
                            </IconButton>
                            <Menu
                              id={`fade-menu-${user.id ?? -1}`}
                              anchorEl={anchorEls[user.id ?? -1]}
                              open={Boolean(anchorEls[user.id ?? -1])}
                              onClose={() => handleClose(user.id ?? -1)}
                              style={{ boxShadow: "none" }}
                            >
                              <MenuItem
                                onClick={() => {
                                  handleEditClick(user);
                                  handleClose(user.id ?? -1);
                                }}
                              >
                                Edit
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  handleAddPermissions(user);
                                }}
                              >
                                Add Permissions
                              </MenuItem>
                            </Menu>
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell colSpan={5} align="center">
                          No users found.
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          );
      }
    };
  
    return (
      <Paper style={{ width: "99%", height: "90vh", overflow: "hidden" }}>
        <div style={{ padding: "16px" }}>
          {renderBreadcrumbs()}
          {renderContent()}
        </div>
      </Paper>
    );
  };
  
  export default InternalUsers;