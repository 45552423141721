export function convertToCamelCase(obj: any): any {
  const camelCaseObj: any = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      if (
        value !== null &&
        typeof value === "object" &&
        !Array.isArray(value)
      ) {
        // Recursively convert nested objects
        camelCaseObj[toCamelCase(key)] = convertToCamelCase(value);
      } else if (Array.isArray(value)) {
        // Handle arrays of nested objects
        camelCaseObj[toCamelCase(key)] = value.map(convertToCamelCase);
      } else {
        camelCaseObj[toCamelCase(key)] = value;
      }
    }
  }

  return camelCaseObj;
}

function toCamelCase(str: string): string {
  return str.replace(/_+([a-zA-Z0-9])/g, (_, letterOrDigit) =>
    letterOrDigit.toUpperCase()
  );
}
