import { CKEditor } from "@ckeditor/ckeditor5-react";
import * as Generic from "./packages/res/demos/imports";
import * as ClassicEditor from "./ckeditor";
import { useEffect, useState } from "react";
import DOMPurify from "dompurify";
import katex from "katex";
const toolbar = [
  "bold",
  "italic",
  "MathType",
  "ChemType",
  "alignment:left",
  "alignment:center",
  "alignment:right",
];
const ckConfig = {
  iframe: true,
  charCounterCount: false,
  toolbar,
  htmlAllowedTags: [".*"],
  htmlAllowedAttrs: [".*"],
  htmlAllowedEmptyTags: ["mprescripts"],
  imageResize: false,
  useClasses: false,
};

function updateContent(ckeditor: { getData: () => any }) {
  const ele: any = document.getElementById("btn_update");

  if (ele) {
    ele.addEventListener("click", (e: { preventDefault: () => void }) => {
      e.preventDefault();
      Generic.updateContent(ckeditor.getData(), "transform_content");
    });
  }
}

function CustomCkeditor(props: any) {
  const [editorContent, setEditorContent] = useState();
  const [latexValue, setLatex] = useState();
  const decodeHTMLEntities = (text: string): string => {
    const textArea = document.createElement("textarea");
    textArea.innerHTML = text;
    return textArea.value;
  };

  useEffect(() => {
    if (props.onChange) {
      props.onChange(editorContent);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorContent]);

  const convertLatexToMathML = (text: string): string => {
    return text.replace(/\$\$(.*?)\$\$/gs, (_, expr) => {
      try {
        // Convert LaTeX to MathML using KaTeX
        let mathml = katex.renderToString(expr, {
          throwOnError: false,
          output: "mathml", // Ensure MathML output
        });

        // Extract only <math>...</math> content
        const match = mathml.match(/<math.*?<\/math>/s);
        return match
          ? `<p>&nbsp;</p><p>${match[0]}</p>`
          : `<p>Error rendering math</p>`;
      } catch (error) {
        console.error("KaTeX rendering error:", error);
        return `<p>Error rendering math</p>`;
      }
    });
  };
  useEffect(() => {
    const decodedContent = decodeHTMLEntities(props.value);
    const sanitizedContent = DOMPurify.sanitize(decodedContent);
    const mathRenderedContent: any = convertLatexToMathML(sanitizedContent);
    if (
      !latexValue &&
      (props.value || props.value === "") &&
      props.value !== latexValue
    ) {
      setEditorContent(mathRenderedContent);
      setLatex(props.value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value, latexValue]);
  return (
    <CKEditor
      editor={ClassicEditor}
      config={ckConfig}
      data={editorContent}
      onChange={(event, editor) => {
        const data = editor.getData();
        setEditorContent(data);
      }}
      onReady={(editor: any) => {
        updateContent(editor);
      }}
    />
  );
}

export default CustomCkeditor;