import { Stack, Typography } from "@mui/material";
import React, { useRef } from "react";
import getAlphabetForIndex from "../../utility/getAlphabetForIndex";
// import math from "../math";

interface QuestionOptionProps {
  optionText: string;
  optionIndex: number;
}

const QuestionOption: React.FC<QuestionOptionProps> = ({
  optionText,
  optionIndex,
}) => {
  const questionOptionRef = useRef(null);

  // useEffect(() => {
  //   math(questionOptionRef.current);
  // }, [optionText]);

  return (
    <Stack
      direction={"row"}
      spacing={"10px"}
      component={"button"}
      p={"12px"}
      border="1px solid"
      borderColor={"rgba(200, 200, 200, 1)"}
      borderRadius={"5px"}
      bgcolor={"white"}
    >
      <Typography
        fontStyle={"normal"}
        fontWeight={400}
        fontSize={"14px"}
        lineHeight={"21px"}
        textAlign={"left"}
      >
        <span>{`${getAlphabetForIndex(optionIndex)}. `}</span>
      </Typography>
      <Typography
        fontStyle={"normal"}
        fontWeight={400}
        fontSize={"14px"}
        lineHeight={"21px"}
        textAlign={"left"}
      >
        <span
          ref={questionOptionRef}
          dangerouslySetInnerHTML={{ __html: optionText }}
        />
      </Typography>
    </Stack>
  );
};

export default QuestionOption;
