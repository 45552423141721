import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiRequest from "../../../request/apiRequest/apiRequest";
import { allApis } from "../../../request/config";

export interface InternalUser {
  id?: string;
  email: string;
  user_data: { full_name: string };
  phone: string;
  is_active: boolean;
}

interface InternalUserState {
  users: InternalUser[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | null;
}

const initialState: InternalUserState = {
  users: [],
  status: "idle",
  error: null,
};

// Create Internal User
export const createInternalUser = createAsyncThunk<InternalUser, InternalUser>(
  "internalUsers/create",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await apiRequest({
        api: allApis.userPermissions_create,
        method: "POST",
        payload: userData,
      });
      if ('error' in response && response?.error) {
        return rejectWithValue(response.message);
      }
      else{
        return response;
      }
      
    } catch (error: any) {
      return rejectWithValue(error?.response?.data || error.message);
    }
  }
);

// Update Internal User
export const updateInternalUser = createAsyncThunk<
  InternalUser,
  { id: string; userData: InternalUser }
>("internalUsers/update", async ({ id, userData }, { rejectWithValue }) => {
  try {
    const response = await apiRequest({
      api: allApis.userPermissions_update(id),
      method: "PUT",
      payload: userData,
    });
    return response;
  } catch (error: any) {
    return rejectWithValue(error?.response?.data || error.message);
  }
});

// Retrieve Internal Users
export const fetchInternalUsers = createAsyncThunk<InternalUser[]>(
  "internalUsers/fetchAll",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiRequest({
        api: allApis.userPermissions_retrieve,
        method: "GET",
      });
      return response;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data || error.message);
    }
  }
);

// Slice
const internalUserSlice = createSlice({
  name: "internalUsers",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createInternalUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        createInternalUser.fulfilled,
        (state, action: PayloadAction<InternalUser>) => {
          state.status = "succeeded";
          state.users.push(action.payload);
        }
      )
      .addCase(createInternalUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(updateInternalUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        updateInternalUser.fulfilled,
        (state, action: PayloadAction<InternalUser>) => {
          state.status = "succeeded";
          const index = state.users.findIndex(
            (user) => user.id === action.payload.id
          );
          if (index !== -1) {
            state.users[index] = action.payload;
          }
        }
      )
      .addCase(updateInternalUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      })
      .addCase(fetchInternalUsers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(
        fetchInternalUsers.fulfilled,
        (state, action: PayloadAction<InternalUser[]>) => {
          state.status = "succeeded";
          state.users = action.payload;
        }
      )
      .addCase(fetchInternalUsers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload as string;
      });
  },
});

export const {} = internalUserSlice.actions;

export default internalUserSlice.reducer;
