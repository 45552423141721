import { createAsyncThunk } from "@reduxjs/toolkit";
import { QuestionData } from "./interfaces/frontend";
import { QuestionDataResponse } from "./interfaces/backend";
import { convertToCamelCase } from "../../../../../utility/convertToCamelCase";
//import { SERVER_DOMAIN } from "../../../../../constants";
import { serverDetails } from "../../../constants/staticContents";

// login thunk is called only when on enter mobile number view
export const loadQuestionsDataArray = createAsyncThunk(
  "questionsDataArraySlice/loadQuestionsDataArray",
  async ({ token, currentPageNumber }: LoadQuestionsDataArrayArgs) => {
    try {
      // resend : number, 0 -> ...
      const response = await fetch(
        `${serverDetails}/content/questions-proofread/?page=${currentPageNumber}`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      

      if (!response.ok) {
        const responseData: any = await response.json();
        console.log(responseData);
        throw new Error(`${responseData.detail}`);
      }

      const questionsDataArrayResponse: QuestionDataResponse[] =
        await response.json().then((data) => data.results);
      console.log(questionsDataArrayResponse[0]);
      const loadQuestionsDataArrayReturnValue: LoadQuestionsDataArrayReturnValue =
        {
          questionsDataArray: questionsDataArrayResponse.map((item) =>
            convertToCamelCase(item)
          ),
        };

      return loadQuestionsDataArrayReturnValue;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }
);

export interface LoadQuestionsDataArrayArgs {
  token: string;
  currentPageNumber: number;
}

export interface LoadQuestionsDataArrayReturnValue {
  questionsDataArray: QuestionData[];
}
