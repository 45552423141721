import { configureStore } from "@reduxjs/toolkit";
import constants from "./slices/constants";
import userSlice from "./slices/userManagement"
import exams from "./slices/exams"
import contentFeedbackSlice from "./slices/contentFeedback/contentFeedbackSlice";
import orderAndPaymentSlice from "./slices/ordersAndPayments/ordersAndPaymentSlice";
import internalUserSlice from "./slices/InternalUsers/InternalUserSlice";
import questionsDataArraySliceReducer from "./slices/proofReading/questionDataArraySlice";



export type State = {};

export const store = configureStore({
  reducer: {
    constantSlice: constants,
    userSlice:userSlice,
    exams:exams,
    contentFeedback:contentFeedbackSlice,
    orderAndPaymentSlice: orderAndPaymentSlice,
    internalUserSlice:internalUserSlice,
    questionsDataArraySlice: questionsDataArraySliceReducer,
  },
  middleware: (getDefaultMiddleware) =>
  getDefaultMiddleware({
    // thunk: {
    //   extraArgument: myCustomApiService,
    // },
    serializableCheck: false,
  }),

});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
