import { createAsyncThunk } from "@reduxjs/toolkit";
import apiRequest, {  requestObject } from "../../../../../request/apiRequest/apiRequest";
import { store } from "../../../../store";
import { setLoader, showSnack } from "../../../constants";
export interface userResponse{
  error: any;
  token:string;
  user_id:number;
  user_email:string;
  permissions:string[];
  msg:any
}
export const loginVerify = createAsyncThunk(
  "userSlice/loginVerify",
  async (props: requestObject) => {
    try {
      const response: userResponse = await apiRequest(props ?? {});
      if (response&&!response.error) {
         localStorage.setItem("npc-admin-token", JSON.stringify(response.token));
         localStorage.setItem("npc-admin-userid", JSON.stringify(response.user_id));
        localStorage.setItem("npc-admin-permissions", JSON.stringify(response.permissions));
         if(props.navFunc){
         props.navFunc.function(props.navFunc.route)}
      }else{
        store.dispatch(
          showSnack({
            open: true,
            message: response.msg??"",
            severity: "error",
          })
        );
      }
    
      store.dispatch(setLoader({open:false}));
      return {...response,user_permissions:response.permissions}
    } catch (error: any) {
      return null
    }
  }
);
