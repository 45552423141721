import { ObjectValues } from "./pages/login";
import { allStatus } from "./store/slices/constants/staticContents";

const primary: ObjectValues = {
  black: "#000000",
  appBackground:"#F4F5FA",
  white: "#FAFAFA",
  pureWhite: "#FFFFFF",
  chipGreyColor:"#DFDFDF",
  grey: "#969696",
  darkGrey:"#444444",
  disableBg: "rgba(200, 200, 200, 1)",
  disableText: "rgba(131, 131, 131, 1)",
  lightGrey:"#F6F6F6",
  purple:"#793ED6",
  normalText:"#838383",
  borderColor:"#EBEBEB",
  green:"#02B534",
  harvestGold:"#FAEEDD"
};
const colorCombinations:ObjectValues={
  [allStatus[1].label]:{
    bg:"#E1E6FF",
    color:"#5570F1"
  }, 
  [allStatus[2].label]:{
    bg:"#FAEEDD",
    color:"#CC861D"
  },   approved:{
    bg:"#E3F4E7",
    color:"#519C66"
  }, 
  published:{
    bg:"#F2ECFB",
    color:primary.purple
  },
  archived:{
    bg:"#FFECF8",
    color:"#E642AA"
  },
  rejected:{
    bg:"#FFF0F0",
    color:"#FF1A1A"
  }, 
  unpublished:{
    bg:"#EAFAFC",
    color:"#179EAF"
  },
  whitelist:{
    bg:'#EAFAFC',
    color:"black"
  }, 
  free:{
    bg:primary.lightGrey,
    color:"black"
  }
}
const grey = {
  10: "rgb(150 150 150 / 10%)",
};
const red = {
  700: "#800000",
  600: "#CC0000",
  500: "#FF1A1A",
  400: "#FF6464",
  300: "#FFC1C1",
  200: "#FFE0E0",
  100: "#FFF0F0",
};
const harvestGold = {
  700: "#3B2708",
  600: "#754D11",
  500: "#A16A17",
  400: "#CC861D",
  300: "#EBBC75",
  200: "#F3D5A9",
  100: "#FAEEDD",
};

const lavender = {
  700: "#2B1253",
  600: "#4B1F91",
  500: "#793ED6",
  400: "#A57EE4",
  300: "#BEA2EB",
  200: "#D1BEF1",
  100: "#F2ECFB",
};

const rosePink = {
  700: "#6C0044",
  600: "#D80089",
  500: "#E642AA",
  400: "#FF78CD",
  300: "#FFA0DC",
  200: "#FFBDE7",
  100: "#FFECF8",
};

const electricBlue = {
  700: "#0B4F58",
  600: "#179EAF",
  500: "#1CC5DB",
  400: "#6FDDEC",
  300: "#98E7F2",
  200: "#B7EEF6",
  100: "#EAFAFC",
};


const globalColors = {
  primary,
  red,electricBlue,harvestGold,rosePink,lavender,
  grey,colorCombinations
};

export default globalColors;
