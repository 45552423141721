import { Stack, Typography } from "@mui/material";
import React from "react";

interface ChipProps {
  label: string;
  color: string;
}

const Chip: React.FC<ChipProps> = ({ label, color }) => {
  return (
    <Stack
      bgcolor={"#FFCCBB"}
      py={"4px"}
      px={"8px"}
      borderRadius={"32px"}
      border={`1px solid ${color}`}
      margin={"4px"}
      width={"160px"}
    >
      <Typography
        color={color}
        fontWeight={600}
        fontSize={"10px"}
        lineHeight={"120%"}
        letterSpacing={"-0.2px"}
        dangerouslySetInnerHTML={{ __html: label }}
      />
    </Stack>
  );
};

export default Chip;