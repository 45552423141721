import { useAppDispatch, useAppSelecter } from "../../hooks/storeHooks";
import { Box, Divider, Stack } from "@mui/material";
import CustomTable from "../../Components/CustomTable";
import CustomTab, { TabProp } from "../../Components/CustomTab";
import globalColors from "../../globalColors";
import { headerContent, subHeading } from "../../Components/CustomTexts";
import { Addicon, stepperSvg } from "../../assets/svgs";
import { ObjectValues } from "../login";
import React, { useEffect, useState } from "react";
import FormComponent from "../../Components/FormComponent";
import {
    ModuleDetailsInMockTest,
    allModulesInMockTest,
    allPermissionsMockTest,
    allStatusInMockTestModule,
    allStatusInMockTestModule2,
    checkPermisions,
    contents,
    getNextModuleInMockTest,
    historyArray,
    statusType,
    structureProps,
    structureMockProps,
    allStatusInMock
} from "../../store/slices/constants/staticContents";
import { setExamStructure, setFetchAgain } from "../../store/slices/exams";
import CustomSearch from "../../Components/CustomSearch";
import CustomDialog from "../../Components/CustomDialog";
import CustomTextField from "../../Components/CustomTextfield";
import { fetchTableData } from "../../store/slices/exams/thunks/fetchTableData";
import { allApis } from "../../request/config";
import apiRequest from "../../request/apiRequest/apiRequest";
import { showSnack } from "../../store/slices/constants";
import ConfirmationDialog from "../../Components/ConfirmationDialog";
import PathOrderManagment from "../../Components/PathOrderManagment";
import CopyComponent from "../../Components/CopyComponent";
import { rowCenter, rowHorizontalCenter } from "../../GlobalStyles";
import {
    fetchAllClusters,
    fetchAllExamsTabData,
    fetchAllMockQuestionsTabData,
    fetchAllQuestionsTabData,
    fetchContentData,
    fetchSinlgQustion,
    fetchTagsData,
} from "../../store/slices/exams/thunks/fetchContentData";
import { fetchHistory } from "../../store/slices/exams/thunks/fetchHistory";
import QuestionForm from "../exams/QuestionForm";
import { ExpandButton } from "../../Components/ExpandViewCardMock";
import AllExpandedMockTest from "../AllExpandedMockTest";
import QuestionFormComponentMock from "./QuestionFormComponentMock";
import CustomBreadcrumbsForMock from "../../Components/CustomBreadcrumbsForMock";

export const formatStructureData = (
    old: structureProps,
    newFields: ObjectValues,
    title: (typeof allModulesInMockTest)[number]
) => {
    // Helper function to recursively update the structure
    const updateStructure = (structure: structureProps): structureProps => {
        // If the current structure's title matches the target title, update it
        if (structure.title === title) {
            return {
                ...structure,
                ...newFields,
            };
        }
        // if(newFields.dockedTable){

        // }

        // If there is a child, recursively update the child
        if (structure.child) {
            return {
                ...structure,
                dockedList:
                    newFields.dockedList && structure.title !== title
                        ? false
                        : structure.dockedList,
                child: updateStructure(structure.child),
            };
        }

        // If no update is needed, return the original structure
        return structure;
    };

    // Update the structure and return the updated version
    return updateStructure(old);
};

function MockTest() {
    const dispatch = useAppDispatch();
    const examsData = useAppSelecter((state: ObjectValues) => state.exams);
    const fetchAgain = examsData.fetchAgain
    const tags_list = examsData.tags_list ?? [];
    const all_clusters = examsData.all_clusters ?? [];
    const history_list = examsData.history_list;
    const all_exam_status_data = examsData.all_exam_status_data;
    const all_goal_status_data = examsData.all_goal_status_data;
    const all_mockTestSeries_status_data = examsData.all_mockTestSeries_status_data;
    const all_mockTests_status_data = examsData.all_mockTests_status_data;
    const all_questions_status_data = examsData.all_questions_status_data;
    const userSlice = useAppSelecter(
        (state: ObjectValues) => state.userSlice.loggedUser
    );
    const is_expanded = examsData.is_expanded;
    const dockedCard: structureProps = examsData.docked_exams_table;
    const language_available = dockedCard.parentDetails?.languages_available;
    const url = new URL(window.location.href);
    const selectedQuestionId = url.searchParams.get('questionId');
    const [dialogs, setDialog] = useState<ObjectValues>({});
    const [copyDialog, setCopyDialog] = useState<ObjectValues>({});
    const [mockTestSectionID, setMockTestSectionID] = useState<number>(0);
    const [page, setPage] = useState<number>(1);
    const [size, setSize] = useState<number>(10);
    const exams_structure = examsData.exams_structure;
 
    const formatted_exams_structure: structureProps[] =
        examsData.formatted_exams_structure;
    const getGoalId = () => {
        return formatted_exams_structure.find(
            (item) => item.title === allModulesInMockTest[1]
        )?.selected_row?.id;
    };

    const getMockTestSeriesId = () => {
        return formatted_exams_structure.find(
            (item) => item.title === allModulesInMockTest[2]
        )?.selected_row?.id;
    };
    const getGoalStatus = () => {
        return formatted_exams_structure.find(
            (item) => item.title === allModulesInMockTest[1]
        )?.selected_row?.status;
    };
    const statusUpdateFunction = async (status: string, row: any) => {
        const response: any = await apiRequest({
            api:
                status === "deleted" && allApis[dockedCard.title]?.delete
                    ? allApis[dockedCard.title]?.delete(row?.selected?.id)
                    : allApis[dockedCard.title]?.status_update
                        ? allApis[dockedCard.title]?.status_update(
                            row?.selected?.cluster?.id ?? row?.selected?.id
                        )
                        : allApis[dockedCard.title]?.edit(row?.selected?.id),
            payload:
                status === "deleted" && allApis[dockedCard.title]?.delete
                    ? {}
                    : status === "deleted"
                        ? { is_active: false }
                        : dockedCard.title === "question"
                            ? {
                                status: status,
                                id: row?.selected?.id,
                            }
                            : {
                                status: status,
                                id: row?.selected?.id,
                                // internal_remark: row[contents.feedback] ?? "",
                            },
            method:
                status === "deleted" && allApis[dockedCard.title]?.delete
                    ? "DELETE"
                    : "PATCH",
        });

        const clearAll = () => { };
        setDialog({});
        dispatch(
            setExamStructure(
                formatStructureData(
                    exams_structure,
                    {
                        fetchAgain: true,
                        loading: true,
                    },
                    dockedCard.title
                )
            )
        );
        if (!response || (response && response.error)) {
            clearAll();
        } else {
            if (status === "deleted") {
                dispatch(
                    showSnack({
                        message: contents.successStatusChange(row?.selected?.name, status),
                        severity: "success",
                        open: true,
                    })
                );
                clearAll();
            } else {
                const content = examsData.content_list.find(
                    (item: { model: string }) =>
                        item.model ===
                        (dockedCard.title === allModulesInMockTest[0] 
                            ? "exam"
                           : dockedCard.title === allModulesInMockTest[1] 
                            ? "goal"
                           : dockedCard.title === allModulesInMockTest[2] 
                            ? "mocktestseries" 
                           : dockedCard.title === allModulesInMockTest[3] 
                           ? "mocktest" 
                           : dockedCard.title === allModulesInMockTest[4]
                            ? "mocktestsection"
                            : dockedCard.title === allModulesInMockTest[5]
                                ? "question"
                                : dockedCard.title.replace("s", ""))
                );
                dispatch(
                    showSnack({
                        message: contents.successStatusChange(row?.selected?.name, status),
                        severity: "success",
                        open: true,
                    })
                );
                // if (content && content.id) {
                //     const statusResponse: any = await apiRequest({
                //         api: allApis.status_create,
                //         payload: {
                //             parent_id: row?.selected?.cluster?.id ?? row?.selected?.id,
                //             old_status:
                //                 row?.selected?.cluster?.status ?? row?.selected?.status,
                //             new_status: status,
                //             feedback: row[contents.feedback] ?? "",
                //             parent_model: content.id,
                //         },
                //         method: "POST",
                //     });
                //     if (!statusResponse) {
                //     } else {
                //         await dispatch(
                //             showSnack({
                //                 message: contents.successStatusChange(
                //                     row?.selected?.name,
                //                     status
                //                 ),
                //                 severity: "success",
                //                 open: true,
                //             })
                //         );
                //         await clearAll();
                //     }
                // }
            }
        }
    };
    const getEditButton = (rowData?: ObjectValues, type?: "button") => {
        return (
            checkPermisions(
                userSlice?.user_permissions ?? [],
                "change",
                getPermissionName(),
                (dockedCard.title == "exams" || dockedCard.title == "goals") ? "syllabus" : "content"
                // dockedCard.title == "question" ? "content" : "syllabus"
            ) &&
            (type
                ? {
                    content: "Edit",
                    onClick: (row: ObjectValues) => {
                        dispatch(
                            setExamStructure(
                                formatStructureData(
                                    exams_structure,
                                    {
                                        edit: true,
                                        selected_row: rowData ?? row,
                                        selected_row_field: "name",
                                    },
                                    dockedCard.title
                                )
                            )
                        );
                    },
                }
                : {
                    label: "Edit",
                    // `${dockedCard.title == "question" && dockedCard?.list[0]?.status == "ready" ? "Go to Quality Checks" : "Edit"}`,
                    onclick: (row: ObjectValues) => {
                        dispatch(
                            setExamStructure(
                                formatStructureData(
                                    exams_structure,
                                    {
                                        edit: true,
                                        selected_row: rowData ?? row,
                                        selected_row_field: "name",
                                    },
                                    dockedCard.title
                                )
                            )
                        );
                    },
                })
        );
    };
    const getDeleteButton = () => {
        return checkPermisions(
            userSlice?.user_permissions ?? [],
            "delete",
            getPermissionName(),
            (dockedCard.title == "exams" || dockedCard.title == "goals") ? "syllabus" : "content"
            // dockedCard.title == "question" ? "content" : "syllabus"
        ) &&
            dockedCard.title !== "clusters" &&
            dockedCard.title !== "question"
            ? {
                label: `Delete ${dockedCard.title}`,
                onclick: (row: ObjectValues) => {
                    setDialog((prevDialogs) => {
                        return {
                            ...prevDialogs,
                            open: true,
                            actions: (dialog: any) => {
                                return [
                                    {
                                        sx: { width: "130px", padding: "6px" },
                                        content: "Save",
                                        disabled: !dialog[contents.feedback],
                                        variant: "contained",
                                        onClick: () => {
                                            statusUpdateFunction("deleted", dialog);
                                        },
                                    },
                                ];
                            },
                            title: "Delete",
                            selected: row,
                        };
                    });
                },
            }
            : false;
    };
    const fetchSingleClusterQuestions = async (id: string) => {
        const res = apiRequest({
            api: allApis.cluster_questions(`${id}/`),
            method: "GET",
        });
        return res;
    };
    const allStepForm = ModuleDetailsInMockTest[dockedCard.title]?.inputSteps(
        // {
        //     all_clusters: all_clusters ?? [],
        //     fetchSingleClusterQuestions: fetchSingleClusterQuestions,
        //     ...dockedCard,
        //     selected_row: dockedCard.selected_row ?? copyDialog.row ?? null,
        // },
        // examsData.docked_exams && examsData.docked_exams.selected_row
        //     ? examsData.docked_exams
        //     : null
    );

    const formProps = {
        successMessage: (res: ObjectValues) => {
            return ModuleDetailsInMockTest[dockedCard.title].success(
                dockedCard.edit ? dockedCard.selected_row ?? {} : undefined,
                res
            );
        },
        formatPayload: ModuleDetailsInMockTest[dockedCard.title]?.formatPayload,
        extraFields: dockedCard.edit
            ? []
            : dockedCard.title === allModulesInMockTest[3] ?
            [{ label: "mock_test_series", value: getMockTestSeriesId() }] : dockedCard.title === allModulesInMockTest[2] ||
                dockedCard.title === allModulesInMockTest[4] ||
                dockedCard.title === allModulesInMockTest[5]
                ? [{ label: "goal", value: getGoalId() }] 
                : [
                    { label: "status", value: "draft" },
                    {
                        label: "is_active",
                        value: true,
                    },
                ],
        inputSteps: allStepForm,
        inputFormArray: ModuleDetailsInMockTest[dockedCard.title]?.inputArray(
            {
                tags_list: tags_list ?? [],
                ...dockedCard,

                selected_row: dockedCard.selected_row ?? copyDialog.row ?? null,
                // tagContent:
            },
            examsData.docked_exams && examsData.docked_exams.selected_row
                ? examsData.docked_exams
                : null
        ),
        header: dockedCard.title,
        editData: dockedCard.edit ? dockedCard.selected_row ?? {} : undefined,
        api: {
            edit: dockedCard.edit
                ? allApis[dockedCard.title]?.edit(
                    dockedCard.selected_row?.id ??
                    dockedCard.selected_row?.cluster?.id ??
                    ""
                )
                : null,
            edit_method: dockedCard.title === "clusters" ? "POST" : "PATCH",
            create: allApis[dockedCard.title]?.create,
            create2: allApis[dockedCard.title]?.create2,
        },
        onSubmit: () => {
            // if (dockedCard.title === allModulesInMockTest[6] && fetchAgain.all_cluster !== true) {
            //     dispatch(setFetchAgain({ all_cluster: true }))
            // }
        },
        onClear: () => {
            dispatch(
                setExamStructure(
                    formatStructureData(
                        exams_structure,
                        {
                            create: false,
                            edit: false,
                            selected_row: null,
                            fetchAgain: true,
                        },
                        dockedCard.title
                    )
                )
            );
        }
    };
    const getStatusButtons = (
        list: statusType[],
        row: ObjectValues,
        type?: "popper"
    ): any => {
        /* eslint-disable react/no-array-index-key */
        return list.map((item: statusType) => {
            return type
                ? {
                    label: item.actionLabel,
                    onclick: (rowData: ObjectValues) => {
                        setDialog((prevDialogs) => {
                            return {
                                ...prevDialogs,
                                open: true,
                                actions: (dialog: any) => {
                                    return [
                                        {
                                            sx: { width: "130px", padding: "6px" },
                                            content: "Save",
                                            disabled: !dialog[contents.feedback],
                                            variant: "contained",
                                            onClick: () => {
                                                statusUpdateFunction(item.label, dialog);
                                                // Handle the save action
                                            },
                                        },
                                    ];
                                },
                                title: item.actionLabel,
                                selected: rowData,
                            };
                        });
                    },
                }
                : {
                    content: item.actionLabel,
                    disabled: item?.disabled ?? false,
                    sx: { minWidth: "fit-content" },
                    onClick: () => {
                        setDialog((prevDialogs) => {
                            return {
                                ...prevDialogs,
                                open: true,
                                actions: (dialog: any) => {
                                    return [
                                        {
                                            sx: { width: "130px", padding: "6px" },
                                            content: "Save",
                                            disabled: !dialog[contents.feedback],
                                            variant: "contained",
                                            onClick: () => {
                                                statusUpdateFunction((item.label == "draft" && item.actionLabel == "Ready") ? "ready"
                                                : (item.label == "draft" && item.actionLabel == "Move to Draft") ? "draft" : item.label, dialog);
                                                // Handle the save action
                                            },
                                        },
                                    ];
                                },
                                title: item.actionLabel,
                                selected: row,
                            };
                        });
                    },
                };
        });
    };
    const noEditStatus = ["published", "approved"];
    const actionarr = () => {
        if (ModuleDetailsInMockTest[dockedCard.title]?.isTab && (
            !["mock Tests", "mock Test Series", "question"].includes(dockedCard.title))) {
            switch (dockedCard.selectedTabInMockTest?.label.toLowerCase()) {
                case "approved":
                    return getStatusButtons(
                        allStatusInMockTestModule.filter((status) => status?.label === "rejected"),
                        {},
                        "popper"
                    );
                case "published":
                    return getStatusButtons(
                        allStatusInMockTestModule.filter((status) => status?.label === "unpublished"),
                        {},
                        "popper"
                    );
            }
        } else if (ModuleDetailsInMockTest[dockedCard.title]?.isTab && (
            ["mock Tests", "mock Test Series",].includes(dockedCard.title))) {
            switch (dockedCard.selectedTabInMockTest2?.label.toLowerCase()) {
                case "draft":
                    return [
                        getEditButton(),
                        getDeleteButton(),
                    ].filter((s) => s !== false);
                case "ready":
                    return [
                        getEditButton(),
                        getStatusButtons(
                            allStatusInMockTestModule2.filter((status) => status.label === "draft"),
                            {},
                            "popper"
                        )[0],
                    ];
                case "approved":
                    return getStatusButtons(
                        allStatusInMockTestModule2.filter((status) => status.label === "rejected"),
                        {},
                        "popper"
                    );
                case "published":
                    return getStatusButtons(
                        allStatusInMockTestModule2.filter((status) => status.label === "released"),
                        {},
                        "popper"
                    );
                case "released":
                    return getStatusButtons(
                        allStatusInMockTestModule2.filter((status) => status.label === "archived"),
                        {},
                        "popper"
                    );
                case "archived":
                    return dockedCard.title === allModulesInMockTest[0]
                        ? []
                        : getStatusButtons(
                            allStatusInMockTestModule2.filter((status) => status.label === "unpublished"),
                            {},
                            "popper"
                        );
                case "rejected":
                    return [];
                case "unpublished":
                    return [
                        getStatusButtons(
                            allStatusInMockTestModule2.filter((status) => status.label === "archived"),
                            {},
                            "popper"
                        )[0],
                        getStatusButtons(
                            allStatusInMockTestModule2.filter((status) => status.label === "published"),
                            {},
                            "popper"
                        )[0],
                    ];
            }
        } else if (ModuleDetailsInMockTest[dockedCard.title]?.isTab && (
            ["question"].includes(dockedCard.title))) {
            switch (dockedCard.selectedTabInMockTest3?.label.toLowerCase()) {
                case "draft":
                    return [
                        getEditButton(),
                        getDeleteButton(),
                    ].filter((s) => s !== false);
                case "ready":
                    return [
                        getEditButton(),
                        getStatusButtons(
                            allStatusInMock.filter((status) => status.label === "draft"),
                            {},
                            "popper"
                        )[0],
                    ];
                case "approved":
                    return getStatusButtons(
                        allStatusInMock.filter((status) => status.label === "rejected"),
                        {},
                        "popper"
                    );
                case "published":
                    return getStatusButtons(
                        allStatusInMock.filter((status) => status.label === "archived"),
                        {},
                        "popper"
                    );

                case "archived":
                    return dockedCard.title === allModulesInMockTest[0]
                        ? []
                        : getStatusButtons(
                            allStatusInMock.filter((status) => status.label === "unpublished"),
                            {},
                            "popper"
                        );
                case "rejected":
                    return [];
                case "unpublished":
                    return [
                        getStatusButtons(
                            allStatusInMock.filter((status) => status.label === "archived"),
                            {},
                            "popper"
                        )[0],
                        getStatusButtons(
                            allStatusInMock.filter((status) => status.label === "published"),
                            {},
                            "popper"
                        )[0],
                    ];
            }
        } else {
            return noEditStatus.includes(getGoalStatus())
                ? []
                : getDeleteButton() === false
                    ? []
                    : [getDeleteButton()];
        }
    };
    const fetchSingleQuestionDetails = (id: string) => {
        dispatch(
            fetchSinlgQustion({
                api: `cms/content/question/${id}/`,
                method: "GET",
                payload: {},
                id: id,
            })
        );
    };
    const noAction = () => {
        return ModuleDetailsInMockTest[dockedCard.title]?.isTab
            ? false
            : noEditStatus.includes(getGoalStatus())
                ? true
                : false;
    };
    const buttonarr = (data: ObjectValues) => {
        if (ModuleDetailsInMockTest[dockedCard.title]?.isTab && (
            !["mock Tests", "mock Test Series", "question"].includes(dockedCard.title))) {
            switch (dockedCard.selectedTabInMockTest?.label.toLowerCase()) {
                case "approved":
                    return getStatusButtons(
                        allStatusInMockTestModule.filter(
                            (status) => status?.label === "published"
                            // || status.label === "rejected"
                        ),
                        data
                    );
                case "published":
                    return getStatusButtons(
                        allStatusInMockTestModule.filter((status) => status?.label === "archived"),
                        data
                    );
            }
        } else if (ModuleDetailsInMockTest[dockedCard.title]?.isTab && (
            ["mock Tests", "mock Test Series"].includes(dockedCard.title))) {
            switch (dockedCard.selectedTabInMockTest2?.label.toLowerCase()) {
                case "draft":
                    return getStatusButtons(
                        allStatusInMockTestModule2.filter((status) => status.label === "ready"),
                        data
                    );
                case "ready":
                    return getStatusButtons(
                        allStatusInMockTestModule2.filter(
                            (status) =>
                                status.label === "approved" || status.label === "rejected"
                        ),
                        data
                    );

                case "approved":
                    return getStatusButtons(
                        allStatusInMockTestModule2.filter(
                            (status) => status.label === "published"
                            // || status.label === "rejected"
                        ),
                        data
                    );
                case "published":
                    return getStatusButtons(
                        allStatusInMockTestModule2.filter((status) => status.label === "released"),
                        data
                    );
                case "released":
                    return getStatusButtons(
                        allStatusInMockTestModule2.filter((status) => status.label === "archived"),
                        data
                    );
                case "archived":
                    return dockedCard.title === allModulesInMockTest[0]
                        ? getStatusButtons(
                            allStatusInMockTestModule2.filter((status) => status.label === "unpublished"),
                            data
                        )
                        : [
                            {
                                content: "Create a Copy",
                                onClick: () => {
                                    setCopyDialog({ open: true, row: data });
                                },
                            },
                        ];
                case "rejected":
                    return getStatusButtons(
                        allStatusInMockTestModule2.filter((status) => status.label === "draft"),
                        data
                    );

                case "unpublished":
                    return [getEditButton(data, "button")];
            }
        } else if (ModuleDetailsInMockTest[dockedCard.title]?.isTab && (
            ["question"].includes(dockedCard.title))) {
            switch (dockedCard.selectedTabInMockTest3?.label.toLowerCase()) {
                case "draft":
                    return getStatusButtons(
                        // allStatusInMock
                        // .filter((status) => status.label === "ready"),
                        //.map((f) => ({...f, disabled: data?.})),
                        // data.map((d: any) => (
                        //     {
                        //         disabled: !d?.can_be_ready,
                        //         actionLabel: "Move to Draft",
                        //         label: "draft",
                        //         value: 1
                        //     }
                        // ))
                        [{disabled: !data?.can_be_ready, actionLabel: "Ready", label: "draft", value: 1, badgeCount: 0}]
                        ,
                        data
                    );
                case "ready":
                    return getStatusButtons(
                        // allStatusInMock.filter(
                        //     (status) =>
                        //         status.label === "approved" || status.label === "rejected"
                        // ),
                    [{disabled: !data?.can_be_approved, actionLabel: "Approved", label: "ready", value: 2, badgeCount: 0},
                        {disabled: false, actionLabel: "Rejected", label: "rejected", value: 3, badgeCount: 0}
                    ],
                        data
                    );

                case "approved":
                    return getStatusButtons(
                        allStatusInMock.filter(
                            (status) => status.label === "published"
                            // || status.label === "rejected"
                        ),
                        data
                    );
                case "published":
                    return getStatusButtons(
                        allStatusInMock.filter((status) => status.label === "archived"),
                        data
                    );

                case "archived":
                    return dockedCard.title === allModulesInMockTest[0]
                        ? getStatusButtons(
                            allStatusInMock.filter((status) => status.label === "unpublished"),
                            data
                        )
                        : [
                            {
                                content: "Create a Copy",
                                onClick: () => {
                                    setCopyDialog({ open: true, row: data });
                                },
                            },
                        ];
                case "rejected":
                    return getStatusButtons(
                        allStatusInMock.filter((status) => status.label === "draft"),
                        data
                    );

                case "unpublished":
                    return [getEditButton(data, "button")];
            }
        }
        else {
            return noEditStatus.includes(getGoalStatus())
                ? []
                : [getEditButton(data, "button")];
            //  data.sttaus?==="published"?[]:
        }
    };

    const [tableData, setTableData] = useState<ObjectValues>({
        actual: [],
        copy: [],
        all_status: [],
    });
    useEffect(() => {
        // ModuleDetailsInMockTest[dockedCard.title].arrange_path
        
        const moduleTitle = dockedCard.title;
        let filterLabel: string = "all";
        if(moduleTitle == "goals" || moduleTitle == "exams") {
            filterLabel = dockedCard.selectedTabInMockTest?.label
        } else if (moduleTitle == "mock Test Series" || moduleTitle == "mock Tests") {
            filterLabel = dockedCard.selectedTabInMockTest2?.label
        } else if (moduleTitle == "question") {
            filterLabel = dockedCard.selectedTabInMockTest3?.label
        }  

        const filteredData = ModuleDetailsInMockTest[dockedCard.title]?.formatList(dockedCard.list)?.filter((it) => it.status === filterLabel)
         .filter((it) => it.is_active)
        const sectionsData = ModuleDetailsInMockTest[dockedCard.title]?.formatList(dockedCard.list)?.filter((it) => it.is_active)

        setTableData(
            ModuleDetailsInMockTest[dockedCard.title]?.arrange_path
                ? {
                    all_status: dockedCard.title == 'section' ? sectionsData : filteredData,
                    actual: dockedCard.title == 'section' ? sectionsData : filteredData,
                    copy: dockedCard.title == 'section' ? sectionsData : filteredData,
                }
                : {
                    all_status: dockedCard.title == 'section' ? sectionsData : filteredData,
                    actual: dockedCard.title == 'section' ? sectionsData : filteredData,
                    copy: dockedCard.title == 'section' ? sectionsData : filteredData,
                }
        );
        if (dockedCard.title === allModulesInMockTest[5] && fetchAgain.all_tags !== false) {
            dispatch(fetchTagsData());
            dispatch(setFetchAgain({ all_tags: false }))
        }
        // if (dockedCard.title === allModulesInMockTest[6] && fetchAgain.all_cluster !== false) {
        //     dispatch(fetchAllClusters());
        //     dispatch(setFetchAgain({ all_cluster: false }))
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dockedCard]);

    useEffect(() => {
        if (examsData.content_list?.length === 0) {
            dispatch(fetchContentData());
        }
        if(selectedQuestionId) {
            const initialData = JSON.parse(localStorage.getItem('selectedquestiondata') || '{}');
            dispatch(setExamStructure(initialData))
        } else {
            dispatch(fetchAllExamsTabData())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    // const fetchHistorys = (row: ObjectValues) => {
    //     const content = examsData.content_list.find(
    //         (item: { model: string }) =>
    //             item.model === dockedCard.title.replace("s", "")
    //     );
    //     if (content && content.id) {
    //         dispatch(fetchHistory({ row_id: row.id, content_id: content.id }));
    //     }
    // };

    const removeLastS = (word: string) => {
        if (word.endsWith('s')) {
          return word.slice(0, -1);
        }
        return word;
      }

    const fetchHistorys = (row: ObjectValues) => {
        const content = examsData.content_list.find(
          (item: { model: string }) =>
            item.model === removeLastS(dockedCard.title) ||
            (item.model === "mocktestseries" && dockedCard.title == "mock Test Series") ||
            (item.model === "mocktest" && dockedCard.title == "mock Tests")
         //.replace("s", "")
        );
        if (content && content.id) {
          dispatch(fetchHistory({ row_id: row.id, content_id: content.id }));
        }
      };
    useEffect(() => {
        if (dockedCard.fetchAgain === true && !is_expanded) {
            if(dockedCard.title == "question") {
                dispatch(fetchAllMockQuestionsTabData(examsData.docked_exams.selected_row?.id))

              }
              setMockTestSectionID(examsData.docked_exams.selected_row?.id)
            dispatch(
                fetchTableData({
                    api: `${allApis[dockedCard.title]?.list(dockedCard.title === allModulesInMockTest[5] ?
                        dockedCard.selectedTabInMockTest3?.label.toLowerCase() :
                        dockedCard.selectedTabInMockTest?.label.toLowerCase(),
                        examsData?.docked_exams && examsData.docked_exams.selected_row
                            ? 
                            // examsData.docked_exams.selected_row.cluster?.id ??
                            examsData.docked_exams.selected_row.id
                            : "",
                        dockedCard.title === allModulesInMockTest[2] ||
                            dockedCard.title === allModulesInMockTest[3] ||
                            dockedCard.title === allModulesInMockTest[4] ||
                            dockedCard.title === allModulesInMockTest[5]
                            ? getGoalId()
                            : "",
                        { page: examsData?.paginationModel?.page + 1 || 1, size: examsData?.paginationModel?.pageSize || 10 }
                    )}`,
                    method: "GET",
                    payload: {},
                    token: `mock${dockedCard.title}`,
                })
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dockedCard.fetchAgain]);
    const hasCreateOrEdit: any = (obj: any) => {
        if (!obj) {
            return false;
        } else if (obj.create || obj.edit) {
            return true;
        } else if (obj.child) {
            return hasCreateOrEdit(obj.child);
        } else {
            return false;
        }
    };

    // eslint-disable react/no-array-index-key
    const columns =
        ModuleDetailsInMockTest[dockedCard.title]?.tableHeaders({
            noAction: noAction,
            fetchSingleQuestionDetails: fetchSingleQuestionDetails,
            tags_list: tags_list ?? [],
            tableWidth:
                (document.getElementById("table-width")?.offsetWidth ?? 400) - 5,
            actionWidth: () => {
                if (ModuleDetailsInMockTest[dockedCard.title]?.isTab && (
                    !["mock Tests", "mock Test Series", "question"].includes(dockedCard.title))) {
                    switch (dockedCard.selectedTabInMockTest?.label.toLowerCase()) {
                        case "approved":
                            return 122;
                        case "published":
                            return 124;
                    }
                } else if (ModuleDetailsInMockTest[dockedCard.title]?.isTab && (
                    ["mock Tests", "mock Test Series"].includes(dockedCard.title))) {
                    switch (dockedCard.selectedTabInMockTest2?.label.toLowerCase()) {
                        case "draft":
                            return 120;
                        case "ready":
                            return 130;
                        case "approved":
                            return 122;
                        case "published":
                            return 124;
                        case "archived":
                            return dockedCard.title === allModulesInMockTest[1] ? 180 : 120;
                        case "rejected":
                            return 140;

                        case "unpublished":
                            return 120;
                    }
                } else if (ModuleDetailsInMockTest[dockedCard.title]?.isTab && (
                    ["question"].includes(dockedCard.title))) {
                    switch (dockedCard.selectedTabInMockTest3?.label.toLowerCase()) {
                        case "draft":
                            return 120;
                        case "ready":
                            return 130;
                        case "approved":
                            return 122;
                        case "published":
                            return 124;
                        case "archived":
                            return dockedCard.title === allModulesInMockTest[1] ? 180 : 120;
                        case "rejected":
                            return 140;

                        case "unpublished":
                            return 120;
                    }
                } else {
                    return 120;
                }
            },
            buttonarr,
            actionarr,
            onPreview: (row: any) => {
                dispatch(
                    setExamStructure(
                        formatStructureData(
                            exams_structure,
                            {
                                selected_row: row,
                                selected_row_field: "name",
                                dockedList: true,
                                dockedTable: false,
                                child: {
                                    fetchAgain: true,
                                    title: getNextModuleInMockTest(dockedCard.title),
                                    list: [],
                                    parentDetails: row,
                                    child: null,
                                    loading: true,
                                    create: false,
                                    edit: false,
                                    selected_row: null,
                                    dockedTable: true,
                                    dockedList: false,
                                    selectedTab: allStatusInMock[0],
                                    selectedTabInMockTest:  allStatusInMockTestModule[0],//(dockedCard?.title == "mock Tests" || dockedCard?.title == "mock Test Series") ? allStatusInMockTestModule2[0] : dockedCard?.title == "question" ? allStatusInMock[0] : allStatusInMockTestModule[0],
                                    selectedTabInMockTest2: allStatusInMockTestModule2[0],
                                    selectedTabInMockTest3: allStatusInMock[0]
                                },
                            },
                            dockedCard.title
                        )
                    )
                );
            },
            fetchHistorys,
        }) ?? [];
    const getPermissionName = (title?: string) => {

        const indexOfTitle = allModulesInMockTest.indexOf(title ?? dockedCard.title);
        return allPermissionsMockTest[indexOfTitle]
    }
    const tableContent: any = (it: any) => {
        return (
            checkPermisions(
                userSlice?.user_permissions ?? [],
                "view",
                getPermissionName(),
                (dockedCard.title == "exams" || dockedCard.title == "goals") ? "syllabus" : "content"
                // dockedCard.title == "question" ? "content" : "syllabus"
            ) &&
            (
                <Stack
                    height={"100%"}
                    spacing={"10px"}
                    m="0"
                    id="table-width"
                    width="100%"
                    overflow={"hidden"}
                >
                    <CustomTable
                        renderRowDetails={(row: any) => {
                            const list = history_list[row.id] ?? [];

                            return (
                                <Stack
                                    spacing="8px"
                                    style={{
                                        margin: "10px 0",
                                        padding: "8px 10px",
                                        border: "1px solid #C8C8C8",
                                        borderRadius: "8px",
                                        background: globalColors.primary.appBackground,
                                        minHeight: "70px",
                                    }}
                                >
                                    <Stack
                                        spacing="10px"
                                        sx={{
                                            borderRadius: "8px",
                                            backgroundColor: globalColors.primary.pureWhite,
                                            padding: "8px 8px 8px 50px",
                                        }}
                                        direction="row"
                                    >
                                        {historyArray.map(({ label, sx }) => {
                                            return subHeading({
                                                key: `row-${label}-header`,
                                                text: label,
                                                textAlign: "start",
                                                parentSx: sx ? { ...sx } : {},
                                                sx: { minWidth: "fit-content" },
                                            });
                                        })}
                                    </Stack>
                                    {list.length === 0 ? (
                                        <Stack
                                            width="100%"
                                            sx={{ ...rowHorizontalCenter, padding: "10px 0" }}
                                        >
                                            {subHeading({
                                                text: "No Changes yet!",
                                                sx: { color: globalColors.primary.disableText },
                                                weight: 600,
                                            })}
                                        </Stack>
                                    ) : (
                                        <Stack spacing="10px" direction="row" width="100%">
                                            <Box
                                                sx={{
                                                    minWidth: "30px",
                                                    ...rowHorizontalCenter,
                                                    overflow: "hidden",
                                                    height:
                                                        list.length === 1 || list.length === 2
                                                            ? "100px"
                                                            : list.length === 3
                                                                ? "250px"
                                                                : "370px",
                                                }}
                                            >
                                                 {stepperSvg({
                                                    height:
                                                        list.length === 1
                                                            ? "200px"
                                                            : list.length === 2
                                                                ? "400px"
                                                                : "350px",
                                                    length: list.length,
                                                })} 
                                            </Box>
                                            <Box
                                                sx={{
                                                    gap: "8px",
                                                    flex: "8",
                                                    flexDirection: "column",
                                                    display: "flex",
                                                    // maxHeight: "370px",
                                                    // overflowY: "auto",
                                                }}
                                                className="scrollBluePrimarythin"
                                            >
                                                {list.map((li: ObjectValues) => (
                                                    <Stack
                                                        key={`row-${li.id}`}
                                                        spacing="10px"
                                                        sx={{
                                                            borderRadius: "8px",
                                                            backgroundColor: globalColors.primary.pureWhite,
                                                            padding: "10px",
                                                        }}
                                                        direction="row"
                                                    >
                                                        {historyArray.map(({ label, sx, content }) => {
                                                            return (
                                                                <Box sx={{ ...sx }} key={`row-${label}`}>
                                                                    {content(li)}
                                                                </Box>
                                                            );
                                                        })}
                                                    </Stack>
                                                ))}
                                            </Box>
                                        </Stack>
                                    )}
                                </Stack>
                            );
                        }}
                        loading={dockedCard.loading}
                        apiCall={ModuleDetailsInMockTest[dockedCard.title]?.apiCall}
                        data={tableData.actual}
                        filterValue={[
                            {
                                name: "primary_language",
                                operator: "eq",
                                type: "select",
                                value: null,
                            },
                            {
                                name: "name",
                                operator: "startsWith",
                                type: "string",
                                value: "",
                            },
                            {
                                name: "path_order",
                                operator: "gte",
                                type: "number",
                                value: null,
                            },
                            {
                                name: "registered_users",
                                operator: "gte",
                                type: "number",
                                value: null,
                            },
                            {
                                name: "goal_type",
                                operator: "eq",
                                type: "select",
                                value: null,
                            },
                        ]}
                        // ModuleDetailsInMockTest[dockedCard.title].filterValue ?? []}
                        columnsConfig={columns}
                        isServerSide={ModuleDetailsInMockTest[dockedCard.title]?.isServerSide || false}
                    />
                </Stack>
            )
        );
    };
    const allTab: TabProp[] = (dockedCard?.title == "mock Tests" || dockedCard?.title == "mock Test Series") ? 
        allStatusInMockTestModule2.map((it) => {
        const data: TabProp = {
            content: tableContent(it),
            id: it.value,
            tab: it.label,
        };
        return data;
    }) : (dockedCard?.title == "question") ? allStatusInMock.map((it) => {
        const data: TabProp = {
            content: tableContent(it),
            id: it.value,
            tab: it.label,
        };
        return data;
    }) : allStatusInMockTestModule.map((it) => {
        const data: TabProp = {
            content: tableContent(it),
            id: it.value,
            tab: it.label,
        };
        return data;
    });
    return (
        (selectedQuestionId && dockedCard.title == "question" ) ? 
        <Stack
        height="calc(100% - 15px)"
        width="calc(100% - 16px)"
        direction={"row"}
        m="0 8px 15px"
        spacing={"8px"}
    // sx={{overflowX:"auto"}}
    >
        <Stack
                    height={"100%"}
                    width={
                        dockedCard.parentDetails &&
                            hasCreateOrEdit(exams_structure) !== true
                            ? "calc(100% - 158px)"
                            : "100%"
                    }
                    spacing="15px"
                    position={"relative"}
                >
        <Stack
        flex="8"
        sx={{
            borderRadius: "12px",
            background: globalColors.primary.pureWhite,
            p: "10px 10px",
            width: "calc(100% - 20px)",
            overflow: "auto",
        }}
    >
                                    <Stack flex="8" pb="10px" overflow="hidden">
        <QuestionFormComponentMock 
        {...formProps}
                                        getApi={(id: string) => allApis[dockedCard.title]?.item(id)}
                                        mockTestSectionID={mockTestSectionID}
                                        questionStatus ={tableData.actual[0]?.status}
                                        language_available = {language_available}
                                        selectedQuestionId ={selectedQuestionId}/> 
                                        </Stack>
                                        </Stack>
                                        </Stack>
                                        </Stack>
        :
        <Stack
            height="calc(100% - 15px)"
            width="calc(100% - 16px)"
            direction={"row"}
            m="0 8px 15px"
            spacing={"8px"}
        // sx={{overflowX:"auto"}}
        >
            {copyDialog.open && (
                <CopyComponent
                    open={copyDialog.open}
                    onClose={() => {
                        setCopyDialog({});
                    }}
                    row={copyDialog.row}
                    formProps={formProps}
                />
            )}
            {dialogs.open ? (
                dialogs.title === "Ready" ||
                    dialogs.title === "Approve" ||
                    dialogs.title === "Publish" ||
                    dialogs.title === "Publish" ? (
                    <ConfirmationDialog
                        title="Confirm"
                        content={`Are you sure you want to move ${dialogs.selected.name} to ${dialogs.title} state ?`}
                        open={dialogs.open}
                        onClose={() => {
                            setDialog({});
                        }}
                        onSubmit={() => {
                            dialogs.actions(dialogs)[0].onClick();
                        }}
                    />
                ) : (
                    <CustomDialog
                        title={contents.action(dialogs.title) ?? ""}
                        open={dialogs.open}
                        content={
                            <Stack spacing="10px" width="400px">
                                <Box sx={{ color: "#A09F9F" }}>
                                    {subHeading({
                                        text: contents.actionText(
                                            `exam ${dialogs.selected.name}`,
                                            dialogs.title
                                        ),
                                    })}
                                </Box>
                                <Divider />
                                {subHeading({ text: contents.feedback, weight: 600 })}
                                <CustomTextField
                                    placeholder={contents.feedback}
                                    id={contents.feedback}
                                    variant="outlined"
                                    error={false}
                                    value={dialogs[contents.feedback] ?? ""}
                                    maxRows={6}
                                    minRows={6}
                                    disabled={dialogs.disabled ?? false}
                                    multiline={true}
                                    onChange={(e) => {
                                        setDialog((p) => {
                                            return { ...p, [contents.feedback]: e.target.value };
                                        });
                                    }}
                                    onKeyPress={async (ev: any) => {
                                        if (ev.key === "Enter") {
                                            ev.preventDefault();
                                            if (
                                                dialogs.actions(dialogs)[0] &&
                                                dialogs[contents.feedback]
                                            ) {
                                                dialogs.actions(dialogs)[0].onClick();
                                            }
                                        }
                                    }}
                                    sx={{
                                        width: "100%",
                                        "& .MuiInputBase-root": { padding: "0" },
                                        "& .MuiInputBase-input": { padding: "10px 12px" },
                                    }}
                                    inputProps={{
                                        "data-testid": `${contents.feedback}-input`,
                                    }}
                                />
                            </Stack>
                        }
                        onClose={() => {
                            setDialog({});
                        }}
                        actions={dialogs.actions(dialogs) ?? []}
                    />
                )
            ) : (
                ""
            )}
            {hasCreateOrEdit(exams_structure) === true ? "" : <AllExpandedMockTest />}

            {(!is_expanded || hasCreateOrEdit(exams_structure) === true) && (
                <Stack
                    height={"100%"}
                    width={
                        dockedCard.parentDetails &&
                            hasCreateOrEdit(exams_structure) !== true
                            ? "calc(100% - 158px)"
                            : "100%"
                    }
                    spacing="15px"
                    position={"relative"}
                >
                    {dockedCard.create ||
                        dockedCard.edit ||
                        hasCreateOrEdit(exams_structure) === true
                        ? ""
                        : exams_structure.child && dockedCard.dockedTable && ExpandButton()}
                    <CustomBreadcrumbsForMock />

                    <Stack
                        flex="8"
                        sx={{
                            borderRadius: "12px",
                            background: globalColors.primary.pureWhite,
                            p: "10px 10px 0",
                            width: "calc(100% - 20px)",
                            overflow: "auto",
                        }}
                    >
                        {dockedCard.create ||
                            dockedCard.edit ||
                            hasCreateOrEdit(exams_structure) === true ? (
                            <Stack flex="8" pb="10px" overflow="hidden">
                                {dockedCard.title === allModulesInMockTest[5] ? (

                                    <QuestionFormComponentMock
                                        {...formProps}
                                        getApi={(id: string) => allApis[dockedCard.title]?.item(id)}
                                        mockTestSectionID={mockTestSectionID}
                                        questionStatus ={tableData.actual[0]?.status}
                                        language_available = {language_available}
                                    />
                                ) : (
                                    <FormComponent
                                        {...formProps}
                                        customContent={
                                            allStepForm.length > 0
                                                ? ({ onSubmit, res, submittedData }: any) => {
                                                    return (
                                                        <QuestionForm
                                                            dockedCard={dockedCard}
                                                            res={res}
                                                            editData={
                                                                dockedCard.edit
                                                                    ? dockedCard.selected_row ?? {}
                                                                    : undefined
                                                            }
                                                            formData={submittedData}
                                                            onSubmit={onSubmit}
                                                            onClear={formProps.onClear}
                                                            item={{
                                                                list: tableData.actual,
                                                                selected_row: tableData.actual[0] ?? null,
                                                                title: dockedCard.title,
                                                                primary: "name",
                                                            }}
                                                        />
                                                    );
                                                }
                                                : undefined
                                        }
                                    />
                                )}
                            </Stack>
                        ) : (
                            <Stack
                                flex="8"
                                sx={{
                                    p: "0 10px",
                                    width: "calc(100% - 20px)",
                                    overflow: "auto",
                                }}
                            >
                                {headerContent({
                                    text: dockedCard.title == "section" ? "List of Sections" : `List of ${dockedCard.title.charAt(0).toUpperCase() +
                                        dockedCard.title.slice(1)
                                        }`,
                                    sx: {
                                        p: "10px 0",
                                    },
                                    weight: "500",
                                    icons: [],
                                })}
                                <Stack
                                    direction={"row"}
                                    sx={{
                                        width: "100%",
                                        p: "0 0 15px 0",
                                        borderBottom: `1px solid ${globalColors.primary.borderColor}`,
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <Box sx={{ width: "250px" }}>
                                        {" "}
                                        <CustomSearch
                                            fieldName={
                                                columns.map((col) => col.field) ?? [
                                                    "name",
                                                    "primary_language",
                                                    "description",
                                                ]
                                            }
                                            copyData={tableData.copy ?? []}
                                            filteredData={(array: ObjectValues[]) => {
                                                setTableData((prev) => {
                                                    return { ...prev, actual: array };
                                                });
                                            }}
                                            onClear={() => {
                                                setTableData((prev) => {
                                                    return { ...prev, actual: prev.copy };
                                                });
                                            }}
                                        />
                                        <div style={{ width: "800px", marginTop: "5px", display: dockedCard?.title == "section" ? "block" : "none" }}>Sections configured in this goal will automatically appear in all mock tests</div>
                                    </Box>


                                    <Box sx={{ ...rowCenter, gap: "10px" }}>
                                        {ModuleDetailsInMockTest[dockedCard.title]?.arrange_path && (
                                            <PathOrderManagment
                                                list={tableData.all_status}
                                                title={dockedCard.title}
                                                onClear={() => {
                                                    dispatch(
                                                        setExamStructure(
                                                            formatStructureData(
                                                                exams_structure,
                                                                {
                                                                    create: false,
                                                                    edit: false,
                                                                    selected_row: null,
                                                                    fetchAgain: true,
                                                                },
                                                                dockedCard.title
                                                            )
                                                        )
                                                    );
                                                    dispatch(
                                                        showSnack({
                                                            open: true,
                                                            severity: "success",
                                                            message: `Path order of all the ${dockedCard.title} has been successfully updated.`,
                                                        })
                                                    );
                                                }}
                                            />
                                        )}
                                        {headerContent({
                                            text: "",
                                            icons: (dockedCard.title == "exams" || dockedCard?.title == "goals") ? [] : noAction()
                                                ? []
                                                : checkPermisions(
                                                    userSlice?.user_permissions ?? [],
                                                    "add",
                                                    getPermissionName(),
                                                    (dockedCard.title == "exams" || dockedCard.title == "goals") ? "syllabus" : "content"
                                                    // dockedCard.title == "question" ? "content" : "syllabus"
                                                ) ? [
                                                    {
                                                        content: (dockedCard.title == "exams" || dockedCard?.title == "goals") ? "" : `${(dockedCard.title === "mock Tests" || dockedCard.title === "mock Test Series") ? "Create" : "Add"} ${dockedCard.title === "clusters" ? "" : "New"
                                                            } ${dockedCard.title}`,
                                                        icon: (dockedCard.title == "exams" || dockedCard?.title == "goals") ? "" : Addicon,
                                                        variant: "contained",
                                                        onClick: () => {
                                                            dispatch(
                                                                setExamStructure(
                                                                    formatStructureData(
                                                                        exams_structure,
                                                                        { create: true },
                                                                        dockedCard.title
                                                                    )
                                                                )
                                                            );
                                                        },
                                                    },
                                                ] : [],
                                        })}

                                    </Box>
                                </Stack>

                                <Box
                                    sx={{
                                        maxWidth: "100%",
                                        flex: "8",
                                        overflow: "auto",
                                    }}
                                >
                                    {ModuleDetailsInMockTest[dockedCard.title]?.isTab ? (
                                        <CustomTab
                                            selected={allTab.find(
                                                (it) => 
                                                    it.tab === (
                                                        (dockedCard?.title == "mock Tests" || dockedCard.title === "mock Test Series") ? 
                                                dockedCard.selectedTabInMockTest2?.label : (dockedCard.title === "question") ? 
                                                dockedCard.selectedTabInMockTest3?.label : dockedCard.selectedTabInMockTest?.label
                                              )
                                            )}
                                            onChange={(id: number) => {
                                                dispatch(
                                                    setExamStructure(
                                                        formatStructureData(
                                                            exams_structure,
                                                            {
                                                                selectedTabInMockTest: ["exams", "goals"].includes(dockedCard?.title) ? allStatusInMockTestModule.find(
                                                                    (it) => it.value === id
                                                                ) : allStatusInMockTestModule[0],
                                                                selectedTabInMockTest2: ["mock Tests", "mock Test Series"].includes(dockedCard?.title) ? allStatusInMockTestModule2.find(
                                                                    (it) => it.value === id
                                                                ) : allStatusInMockTestModule2[0],
                                                                selectedTabInMockTest3: dockedCard.title === "question" ? allStatusInMock.find(
                                                                    (it) => it.value === id
                                                                ) : allStatusInMock[0],
                                                                fetchAgain: ModuleDetailsInMockTest[dockedCard.title]
                                                                    .arrange_path
                                                                    ? false
                                                                    : true,
                                                                list: ModuleDetailsInMockTest[dockedCard.title]
                                                                    .arrange_path
                                                                    ? dockedCard.list
                                                                    : [],
                                                                child: null,
                                                                loading: ModuleDetailsInMockTest[dockedCard.title]
                                                                    .arrange_path
                                                                    ? false
                                                                    : true,
                                                            },
                                                            dockedCard.title
                                                        )
                                                    )
                                                );
                                            }}
                                            tabs={allTab}
                                            badgeCount={dockedCard.title == "exams" ? all_exam_status_data 
                                                : dockedCard.title == "goals" ? all_goal_status_data
                                                :dockedCard.title == "mock Test Series" ? all_mockTestSeries_status_data
                                                : dockedCard.title == "mock Tests" ? all_mockTests_status_data 
                                                : dockedCard.title == "question" ? all_questions_status_data
                                                : ""}
                                            dockedCardTitle={dockedCard.title}
                                        />
                                    ) : (
                                        tableContent()
                                    )}
                                </Box>
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
}

export default MockTest;
