import type React from "react"
import { useEffect, useRef } from "react"
import { Box, Typography, RadioGroup, FormControlLabel, Radio, Stack, Paper, TextField, styled } from "@mui/material"
import type { QuestionType } from "./types"
import Chip from "../Chip"

const StyledRichTextDiv = styled("div")<{ 
  empty?: boolean;
  variant?: 'question' | 'option' | 'solution';
}>(({ theme, variant = 'question' }) => ({
  minHeight: "30px",
  width: "90%",
  border: "1px solid #ddd",
  borderRadius: "4px",
  padding: "25px",
  lineHeight: 1.5,
  whiteSpace: "pre-wrap",
  "&:focus": {
    outline: "none",
    borderColor: theme.palette.primary.main,
  },
  "&:empty:before": {
    content: "attr(data-placeholder)",
    color: "#aaa",
    fontStyle: "italic",
  },
  "&.tag-input": {
    minHeight: "20px",
    padding: "8px 12px",
    width: "100%",
    fontSize: "14px",
  },
  "& img": {
    ...(variant === 'question' && {
      maxWidth: "600px",
      height: "auto",
      objectFit: "contain"
    }),
    ...(variant === 'option' && {
      maxWidth: "300px",
      height: "auto",
      objectFit: "contain"
    }),
    ...(variant === 'solution' && {
      maxWidth: "400px",
      height: "auto",
      objectFit: "contain"
    })
  }
}))

interface QuestionEditorProps {
  question: QuestionType
  questionNumber: number
  onQuestionChange: (updatedQuestion: QuestionType) => void
  fileInputRef: React.RefObject<HTMLInputElement>
  isSelected: boolean
}

const RichTextInput = ({
  value,
  onChange,
  placeholder,
  className = "",
  variant = "question",
}: {
  value: string
  onChange: (value: string) => void
  placeholder?: string
  className?: string
  variant?: 'question' | 'option' | 'solution'
}) => {
  const editorRef = useRef<HTMLDivElement>(null)
  const lastHtmlRef = useRef<string>(value)

  useEffect(() => {
    if (editorRef.current && value !== editorRef.current.innerHTML) {
      editorRef.current.innerHTML = value
    }
  }, [value])

  const handleInput = (e: React.FormEvent<HTMLDivElement>) => {
    const currentHtml = e.currentTarget.innerHTML
    if (currentHtml !== lastHtmlRef.current) {
      lastHtmlRef.current = currentHtml
      onChange(currentHtml)
    }
  }

  const handlePaste = (e: React.ClipboardEvent) => {
    e.preventDefault()
    const clipboardData = e.clipboardData

    let content = clipboardData.getData("text/html")

    if (!content) {
      content = clipboardData.getData("text/plain")
      content = content.replace(/\n/g, "<br>")
    }

    const tempDiv = document.createElement("div")
    tempDiv.innerHTML = content

    // Set image dimensions based on variant
    const images = tempDiv.getElementsByTagName("img")
    for (const img of Array.from(images)) {
      switch (variant) {
        case 'question':
          img.style.maxWidth = "600px"
          break
        case 'option':
          img.style.maxWidth = "300px"
          break
        case 'solution':
          img.style.maxWidth = "400px"
          break
      }
      img.style.height = "auto"
    }

    const selection = window.getSelection()
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0)
      range.deleteContents()
      range.insertNode(document.createRange().createContextualFragment(tempDiv.innerHTML))

      range.collapse(false)
      selection.removeAllRanges()
      selection.addRange(range)
    } else {
      editorRef.current?.appendChild(document.createRange().createContextualFragment(tempDiv.innerHTML))
    }

    handleInput(e as any)
  }

  return (
    <StyledRichTextDiv
      ref={editorRef}
      contentEditable
      onInput={handleInput}
      onPaste={handlePaste}
      data-placeholder={placeholder}
      className={className}
      empty={!value}
      variant={variant}
      suppressContentEditableWarning
    />
  )
}

export function QuestionEditor({
  question,
  questionNumber,
  onQuestionChange,
  fileInputRef,
  isSelected,
}: QuestionEditorProps) {
  const questionTextRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (isSelected && questionTextRef.current) {
      questionTextRef.current.focus()
    }
  }, [isSelected])

  const handleChange = (field: keyof QuestionType, value: string | number | string[]) => {
    onQuestionChange({ ...question, [field]: value })
  }

  return (
    <Paper
      sx={{
        p: 3,
        mb: 4,
        border: isSelected ? 2 : 1,
        borderColor: isSelected ? "primary.main" : "divider",
        boxShadow: 0,
        position: "relative",
      }}
      id={`question-editor-${questionNumber}`}
    >
      <Typography
        variant="subtitle1"
        gutterBottom
        sx={{
          position: "absolute",
          top: -8,
          left: 16,
          bgcolor: "background.paper",
          px: 1,
        }}
      >
        Question {questionNumber}
      </Typography>

      <Stack spacing={3} sx={{ border: "none" }}>
        <Box sx={{ border: "none" }}>
          <Typography variant="subtitle2" gutterBottom>
            Question Text
          </Typography>
          <RichTextInput
            value={question.questionHtml}
            onChange={(value) => handleChange("questionHtml", value)}
            placeholder="Paste or type your question here..."
            variant="question"
          />
          
          <Box sx={{ mt: 2 }}>
            <Typography variant="subtitle2" gutterBottom>
              Tag
            </Typography>
            <Box sx={{ maxWidth: '800px' }}>
              <RichTextInput
                value={question.tag || ''}
                onChange={(value) => handleChange("tag", value)}
                placeholder="Enter tag..."
                variant="option"
                className="tag-input"
              />
            </Box>
          </Box>
        </Box>

        <Box>
          <Typography variant="subtitle2" gutterBottom>
            Options
          </Typography>
          {question.options.map((option, optIndex) => (
            <Box key={optIndex} sx={{ mb: 2 }}>
              <RichTextInput
                value={option}
                onChange={(value) => {
                  const newOptions = [...question.options]
                  newOptions[optIndex] = value
                  handleChange("options", newOptions)
                }}
                placeholder={`Option ${String.fromCharCode(65 + optIndex)}`}
                variant="option"
              />
            </Box>
          ))}
        </Box>

        <Box>
          <Typography variant="subtitle2" gutterBottom>
            Correct Answer
          </Typography>
          <RadioGroup
            row
            value={question.correctAnswer}
            onChange={(e) => handleChange("correctAnswer", Number(e.target.value))}
          >
            {question.options.map((_, optIndex) => (
              <FormControlLabel
                key={optIndex}
                value={optIndex}
                control={<Radio />}
                label={String.fromCharCode(65 + optIndex)}
              />
            ))}
          </RadioGroup>
        </Box>

        <Box>
          <Typography variant="subtitle2" gutterBottom>
            Solution Steps
          </Typography>
          <RichTextInput
            value={question.solutionHtml}
            onChange={(value) => handleChange("solutionHtml", value)}
            placeholder="Enter solution steps..."
            className="min-h-[100px]"
            variant="solution"
          />
        </Box>
      </Stack>
    </Paper>
  )
}